import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5483ad6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "application",
  id: "application"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentStructureTree = _resolveComponent("DocumentStructureTree")!
  const _component_pane = _resolveComponent("pane")!
  const _component_Search = _resolveComponent("Search")!
  const _component_splitpanes = _resolveComponent("splitpanes")!
  const _component_Pane = _resolveComponent("Pane")!
  const _component_ApplicationEditor = _resolveComponent("ApplicationEditor")!
  const _component_ReferenceSignList = _resolveComponent("ReferenceSignList")!
  const _component_ApplicationFigureList = _resolveComponent("ApplicationFigureList")!
  const _component_FigureEditor = _resolveComponent("FigureEditor")!
  const _component_DraggableSymbolList = _resolveComponent("DraggableSymbolList")!
  const _component_Splitpanes = _resolveComponent("Splitpanes")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Splitpanes, {
      ref: "topLevelSplitPane",
      "auto-equalize": false,
      "dbl-click-splitter": false,
      "push-other-panes": false,
      onSplitterClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSplitterClick($event))),
      onResize: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onResize($event))),
      onResized: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onResized($event))),
      class: "default-theme application-main-splitpanes"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Pane, {
          size: _ctx.leftSplitPaneSizeComputed,
          "min-size": "0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_splitpanes, {
              horizontal: "",
              class: "default-theme application-left-splitpanes",
              "push-other-panes": false,
              onResize: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onResizeLeft($event))),
              onSplitterClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSplitterHorizontalClick($event)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_pane, { class: "document-structure-tree-pane" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DocumentStructureTree)
                  ]),
                  _: 1
                }),
                _createVNode(_component_pane, {
                  ref: "search",
                  class: _normalizeClass(["search-pane", {scrollx: _ctx.scrollSearch()}])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Search, {
                      mainTab: _ctx.mainTab,
                      searchSpaceTab: _ctx.searchSpaceTab
                    }, null, 8, ["mainTab", "searchSpaceTab"])
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["size"]),
        _createVNode(_component_Pane, {
          size: _ctx.documentEditorPaneSizeComputed,
          "min-size": "10"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ApplicationEditor, {
              onMainEditorScrolled: _ctx.onEditorScroll,
              ref: "applicationEditorInstance"
            }, null, 8, ["onMainEditorScrolled"])
          ]),
          _: 1
        }, 8, ["size"]),
        (_ctx.showDocumentOnly)
          ? (_openBlock(), _createBlock(_component_Pane, {
              key: 0,
              size: this.rightSplitPaneSizeComputed,
              "min-size": "0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_splitpanes, {
                  horizontal: "",
                  class: "default-theme application-right-splitpanes",
                  onSplitterClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSplitterHorizontalClick($event))),
                  "push-other-panes": false
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_pane, { "min-size": "0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ReferenceSignList, {
                          "application-guid": _ctx.applicationGUID,
                          "application-editor-instance": _ctx.applicationEditorInstance,
                          "edit-reference-signs-mode": _ctx.EditReferenceSignMode.EDIT_ENABLED,
                          "drag-mode": _ctx.DragMode.DRAG_DROP_DISABLED,
                          "show-apply-reference-sign-button": true,
                          "show-hightlight-reference-sign-button": true
                        }, null, 8, ["application-guid", "application-editor-instance", "edit-reference-signs-mode", "drag-mode"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_pane, { "min-size": "0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ApplicationFigureList, {
                          "application-guid": _ctx.applicationGUID,
                          figures: this.figures
                        }, null, 8, ["application-guid", "figures"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["size"]))
          : _createCommentVNode("", true),
        (!_ctx.showDocumentOnly)
          ? (_openBlock(), _createBlock(_component_Pane, {
              key: 1,
              "min-size": "10",
              size: _ctx.figureEditorPaneSizeComputed,
              class: "figure-editor-pane",
              ref: "figureEditorPane"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_component_FigureEditor, {
                  key: _ctx.applicationGUID,
                  "application-guid": _ctx.applicationGUID,
                  "figure-guid": _ctx.figureGUID,
                  figures: _ctx.figures,
                  "on-figure-guid-changed": this.onFigureGuidChange
                }, null, 8, ["application-guid", "figure-guid", "figures", "on-figure-guid-changed"]))
              ]),
              _: 1
            }, 8, ["size"]))
          : _createCommentVNode("", true),
        (!_ctx.showDocumentOnly)
          ? (_openBlock(), _createBlock(_component_Pane, {
              key: 2,
              "max-size": 30,
              size: this.rightSplitPaneSizeComputed
            }, {
              default: _withCtx(() => [
                _createVNode(_component_splitpanes, {
                  horizontal: "",
                  class: "default-theme figure-main-splitpanes",
                  "push-other-panes": false
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_pane, {
                      "min-size": "0",
                      class: "reference-sign-list-pane"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ReferenceSignList, {
                          "application-guid": _ctx.applicationGUID,
                          "application-editor-instance": null,
                          "edit-reference-signs-mode": _ctx.EditReferenceSignMode.EDIT_ENABLED,
                          "drag-mode": _ctx.DragMode.DRAG_DROP_ENABLED,
                          "show-apply-reference-sign-button": true,
                          "show-hightlight-reference-sign-button": true
                        }, null, 8, ["application-guid", "edit-reference-signs-mode", "drag-mode"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_pane, {
                      "min-size": "0",
                      class: "reference-sign-list-pane"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DraggableSymbolList, {
                          symbolHeader: _ctx.$t('symbols'),
                          symbolsList: _ctx.symbolsList,
                          disabled: this.figureGUID === null
                        }, null, 8, ["symbolHeader", "symbolsList", "disabled"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_pane, {
                      "min-size": "0",
                      class: "reference-sign-list-pane"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DraggableSymbolList, {
                          symbolHeader: _ctx.$t('helpLines'),
                          symbolsList: _ctx.helpLinesList,
                          disabled: this.figureGUID === null
                        }, null, 8, ["symbolHeader", "symbolsList", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["size"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512)
  ]))
}