import {EditorState, Plugin, PluginKey, PluginSpec, Transaction} from '@tiptap/pm/state';
import {Decoration, DecorationSet} from '@tiptap/pm/view';
import {
  calcLogicalBlock,
  determineDocStartPos,
  findAllLogicalBlocksLeaves,
  isNodeExcludedFromSpellcheck,
  shouldSpellcheckLogicalBlock
} from '@/components/applicationEditor/utils/prosemirror.util';
import {Node as PmNode, Schema} from '@tiptap/pm/model';
import SpellcheckModule from '@/store/modules/SpellcheckModule';
import {SpellcheckOverlay as SpellcheckOverlayClass} from '@/components/applicationEditor/menubar/SpellcheckOverlay.vue';
import {removeMarks} from '@/components/applicationEditor/utils/mark.util';
import {PatentengineSpellcheckState, Range, SpellingMistake} from '@/store/models/spellcheck.model';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';

export const PatentengineSpellcheckPluginKey = new PluginKey<PatentengineSpellcheckState>('patentEngineSpellcheckPlugin');

/**
 * This Plugin adds spell check decorators to the document.
 */
export class PatentengineSpellcheckPlugin extends Plugin {

  private editorId: string;
  private spellcheckDelay = 800;
  private debug = false;
  private spellcheckOverlay: SpellcheckOverlayClass;

  constructor(editorId: string, spellcheckOverlay: SpellcheckOverlayClass) {
    super({
            key: PatentengineSpellcheckPluginKey,
            props: {
              decorations: (state: EditorState): DecorationSet | undefined => {
                const pluginState = this.getPluginState(state);
                if (pluginState && pluginState.editorId === editorId) {
                  return this.getPluginState(state).decorationSet;
                }
                return undefined;
              }
            },
            view: (/*editorView: EditorView*/) => {
              return this.spellcheckOverlay;
            },
            state: {
              /**
               * Inits the state of the plugin
               * See https://prosemirror.net/docs/ref/#state.StateField
               */
              init: (): PatentengineSpellcheckState => {
                return new PatentengineSpellcheckState(editorId);
              },
              /**
               * Hook to intercept transactions.
               */
              apply: (tr: Transaction, pluginState: PatentengineSpellcheckState, oldState: EditorState, newState: EditorState) =>
                this.applyState(tr, pluginState, oldState, newState)
            }
          } as PluginSpec<PatentengineSpellcheckState>);
    this.editorId = editorId;
    this.spellcheckOverlay = spellcheckOverlay;
  }

  private get spellingMistakes(): SpellingMistake[] {
    return SpellcheckModule.spellingMistakes;
  }

  private getPluginState(state: EditorState): PatentengineSpellcheckState {
    return this.getState(state) as PatentengineSpellcheckState;
  }

  /**
   * The plugin-state is updated here and spellchecks are triggered, immediately or delayed.
   * @private
   */
  private applyState(tr: Transaction, oldPluginState: PatentengineSpellcheckState,
                     oldState: EditorState, newState: EditorState): PatentengineSpellcheckState {

    const newActiveEditor = tr.getMeta(ProsemirrorTransactionMeta.UPDATE_ACTIVE_EDITOR);
    let updatedPluginState = oldPluginState;
    if (newActiveEditor) {
      updatedPluginState = updatedPluginState.changeEditor(newActiveEditor);
    }

    // If spellcheck is switched off, we clear the plugin-state which removes the decorations.
    if (!SpellcheckModule.isActive) {
      return updatedPluginState.clear();
    }

    // We always apply the transaction's mappings to the state, which adjusts the positions of the decorations.
    const mappedPluginState = updatedPluginState.mapDecorations(tr, oldState, newState);

    const updateFromBackend: number[] | undefined = tr.getMeta(ProsemirrorTransactionMeta.UPDATE_FROM_BACKEND) as number[];

    if (updateFromBackend != undefined) {
      // On backend updates and after initial loading: trigger spellcheck for relevant blocks or the whole document
      this.triggerSpellcheckForUpdateFromBackend(updateFromBackend, newState);
    } else if (tr.getMeta(ProsemirrorTransactionMeta.SPELLCHECK_RESULT)) {
      // Apply spellcheck results from SpellcheckModule to the UI as decorations.
      return this.applySpellcheckResults(mappedPluginState, newState);
    } else {
      // Handle normal changes in the text (typing): trigger delayed spellcheck
      this.triggerSpellcheckForLocalChanges(tr, newState);
    }

    return mappedPluginState;
  }

  private triggerSpellcheckForUpdateFromBackend(updateFromBackend: number[], newState: EditorState): void {
    if (updateFromBackend[0] === -1) {
      // Special case: update the whole document after the first loading (or a complete reload)
      const logicalBlocks: PmNode[] = findAllLogicalBlocksLeaves(newState.doc);
      const logicalBlockGuids = logicalBlocks
        .map(node => node?.attrs?.guid as string)
        .filter(guid => !!guid);
      SpellcheckModule.setSpellcheckBlockRequests(logicalBlockGuids);

    } else {
      // Trigger first spellcheck after update but only check the updated parts and neighbours
      const blocksChecked: string[] = [];

      // Determine the position in the document where we can start typing
      const docStartPos = determineDocStartPos(newState.doc);

      // The document should not be empty
      if (docStartPos == -1) {
        return;
      }

      // Don't check anything before the first position in the document, where we really can type.
      // (eg. genericTerm in title may got changed)
      updateFromBackend
        .filter((updatePos: number) => updatePos >= docStartPos)
        .forEach((updatePos: number) => {
          const logicalBlock: PmNode | null = calcLogicalBlock(newState.doc, updatePos);
          const logicalBlockGuid = logicalBlock?.attrs?.guid;
          // If the same block was already checked, skip it.
          if (logicalBlockGuid && !blocksChecked.includes(logicalBlockGuid)
            && shouldSpellcheckLogicalBlock(logicalBlock as PmNode)) {
            SpellcheckModule.addSpellcheckBlockRequest(logicalBlockGuid);
            blocksChecked.push(logicalBlockGuid);
          }
        });
    }
  }

  private triggerSpellcheckForLocalChanges(tr: Transaction, newState: EditorState): void {
    if (tr.docChanged) {
      const pos = tr.selection.$head.pos;
      const logicalBlock = calcLogicalBlock(newState.doc, pos);
      const logicalBlockGuid = logicalBlock?.attrs?.guid;
      // Trigger next spellcheck
      if (logicalBlockGuid && logicalBlock && !isNodeExcludedFromSpellcheck(logicalBlock)) {
        SpellcheckModule.addDelayedSpellcheckBlockRequest({logicalBlockGuid, delayMs: this.spellcheckDelay});
      }
    }
  }

  /**
   * The plugin-state is updated using the spellcheck matches in the SpellcheckModule.
   * pluginState.replaceAll() updates its decorations according to the current mistakes.
   * This might remove some decorations, add some new ones and, for performance reasons, reuse all decorations that refer to the same
   * SpellcheckMatches as the new mistakes. New decorations are created at a position calculated according to the logical block.
   */
  private applySpellcheckResults(pluginState: PatentengineSpellcheckState, newState: EditorState): PatentengineSpellcheckState {
    return pluginState.replaceAll(newState, this.spellingMistakes);
  }

  public replaceWithSuggestion(editorState: EditorState, schema: Schema,
                               mistakeDecoration: Decoration, replacement: string): Transaction {

    // We will do all changes on this transaction and dispatch them at once
    const tr: Transaction = editorState.tr;

    // Check if the range to replace consists of multiple textblocks
    const ranges: Range[] = this.getNodesBetween(editorState.doc, mistakeDecoration.from, mistakeDecoration.to);

    // Removes styling marks
    removeMarks(tr, mistakeDecoration.from, mistakeDecoration.to, [
      schema.marks.bold,
      schema.marks.italic,
      schema.marks.underline
    ]);

    // Then we must devide the result into these blocks
    if (ranges.length > 1) {
      // Replace line breaks and split all words by spaces
      this.logDebug('replaceWithSuggestion: ranges number:', ranges.length);
      const replacements = replacement.replaceAll(/(?:\r\n|\r|\n)/g, '').split(' ');
      this.logDebug('replaceWithSuggestion: replacements:', replacements);

      // Calculate how many of the splitted suggestion words fit in which text block
      const wordsPerRange = new Array(ranges.length).fill(0);
      replacements.forEach((_replacement: string, index: number) => wordsPerRange[index % ranges.length]++);
      this.logDebug('replaceWithSuggestion: wordsPerRange:', wordsPerRange);

      // Iterate through all ranges from the back (so the front positions don't need to be adapted)
      let replacementIndex = replacements.length - 1;
      for (let wordIndex = wordsPerRange.length - 1; wordIndex >= 0; wordIndex--) {
        let wordsNumber = wordsPerRange[wordIndex];
        this.logDebug('replaceWithSuggestion: Iterate through all ranges - wordsNumber:', wordsNumber);

        // If text is only removed we must use "deleteRange" instead of replacing with an empty string
        if (wordsNumber === 0) {
          tr.deleteRange(ranges[wordIndex].end, ranges[wordIndex].end);
          continue;
        }

        let replacementForRange = replacements[replacementIndex--];
        // Now we add the determined amount of words to the current text block
        while (wordsNumber > 1) {
          replacementForRange = replacements[replacementIndex--] + ' ' + replacementForRange;
          wordsNumber--;
        }

        this.logDebug('replaceWithSuggestion: For replacement for index ' + replacementIndex + ':', '"' + replacementForRange + '"');

        const newNodeText = (replacementIndex >= 0 ? ' ' : '') + replacementForRange;
        if (newNodeText.length > 0) {
          const newNode = editorState.schema.text(newNodeText);
          this.logDebug('replaceWithSuggestion: newNode:', newNode);
          tr.replaceWith(ranges[wordIndex].start, ranges[wordIndex].end, newNode);
        } else {
          // We can not replace with empty nodes
          this.logDebug('replaceWithSuggestion: delete range (no new node).');
          tr.deleteRange(ranges[wordIndex].start, ranges[wordIndex].end);
        }
      }
    } else {
      // Simple case: Create a new text node and replace the old one
      const newNode = editorState.schema.text(replacement);
      tr.replaceWith(mistakeDecoration.from, mistakeDecoration.to, newNode);
    }

    return tr;
  }

  private getResolvedPos(doc: PmNode, position: number) {
    return doc.resolve(position);
  }

  private getNodesBetween(doc: PmNode, posStart: number, posEnd: number): Range[] {
    const resolvedPosStart = this.getResolvedPos(doc, posStart);
    const resolvedPosEnd = this.getResolvedPos(doc, posEnd);

    const startNode = resolvedPosStart.node();

    const sharedDepth = resolvedPosStart.sharedDepth(resolvedPosEnd.pos);
    const nodeWithStartAndEndNode = resolvedPosStart.node(sharedDepth);

    // This should not happen
    if (startNode === null) {
      return [];
    }

    const ranges: Range[] = [];
    nodeWithStartAndEndNode.descendants((node, pos, parent) => {
      if (node.type.name === 'textBlockNode') {

        const absoluteStart = resolvedPosStart.start(sharedDepth) + pos + 1;
        const absoluteEnd = absoluteStart + node.nodeSize - 2;

        // If we already reached the end, we can stop
        if (absoluteStart >= posEnd) {
          return false;
        }

        const startMax = Math.max(posStart, absoluteStart);
        const endMin = Math.min(posEnd, absoluteEnd);
        // Only push ranges with a start before the end
        if (startMax < endMin) {
          ranges.push({start: startMax, end: endMin});
        }
      }
    })

    return ranges;
  }

  /**
   * Find decoration at given position if there is one.
   */
  public findDecoration(state: EditorState, pos: number): Decoration | undefined {
    const pluginState = this.getPluginState(state);
    return pluginState?.findDecoration(pos);
  }

  private logDebug(text: string, object?: any) {
    if (this.debug) {
      console.log(text, object);
    }
  }
}
