import {Extension} from '@tiptap/vue-3';
import {EditorState, TextSelection} from '@tiptap/pm/state';
import {EditorView} from '@tiptap/pm/view';
import {findNextTextblock, SearchDirection} from '@/components/applicationEditor/utils/prosemirror.util';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';

/**
 * Command for placing the cursor at the end of the next text node, considering the current position of the cursor
 * @param state the EditorState for the action.
 * @param view The view on which the command was executed.
 * @return true if the command could execute, else false.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const placeCursorInNextTextNode = ({state, view}: { state: EditorState, view: EditorView }) => {
  const pos = findNextTextblock(state.doc, state.selection.$anchor, SearchDirection.RIGHT, true)
  if (pos !== -1) {
    const newSelection = TextSelection.create(state.doc, pos, pos);
    const tr = state.tr.setSelection(newSelection);
    if (view.dispatch) {
      tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'QuickTextblockNavigationExtension - placeCursorInNextTextNode');
      view.dispatch(tr);
      return true;
    }
  }
  return false;
};

/**
 * Command for placing the cursor at the end of the previous text node, considering the current position of the cursor
 * @param state the EditorState for the action.
 * @param view The view on which the command was executed.
 * @return true if the command could execute, else false.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const placeCursorInPreviousTextNode = ({state, view}: { state: EditorState, view: EditorView }) => {
  const pos = findNextTextblock(state.doc, state.selection.$anchor, SearchDirection.LEFT);
  if (pos !== -1) {
    const newSelection = TextSelection.create(state.doc, pos, pos);
    const tr = state.tr.setSelection(newSelection);
    if (view.dispatch) {
      tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'QuickTextblockNavigationExtension - placeCursorInNextTextNode');
      view.dispatch(tr);
      return true;
    }
  }
  return false;
};

/**
 * Shortcuts for fast navigation between textblocks
 */
export const QuickTextblockNavigationExtension = Extension.create(
  {
    name: 'quickTextblockNavigationExtension',

    addKeyboardShortcuts() {
      return {
        'Alt-ArrowRight': () => placeCursorInNextTextNode(this.editor),
        'Alt-ArrowLeft': () => placeCursorInPreviousTextNode(this.editor)
      }
    }
  });
