<template>
  <PengineEditorMenuBubble v-if="editor !== null" :editor="editor" :isSelectionNeeded="false">
    <div ref="spellcheckOverlay"
         :class="'mistake-info-wrapper' + (currentMistakeDecoration ? ' is-active' : '')"
         :style="position">
      <div :class="'mistake-info ' + mistakeClass" v-if="currentMistakeDecoration">

        <div class="headline">{{ currentMistake.shortMessage }}</div>

        <div class="description">
          {{ currentMistake.message }}
          <button v-if="currentMistake.url"
                  class="info-button icon-button"
                  :title="$t('spellcheck.info.title') + ' '"
                  v-on:click="clickInfo(currentMistakeDecoration)">
            <i class="exi exi-report-problem"></i>
          </button>
        </div>

        <div class="replacement-list" v-if="!delayForReplacement">
          <div class="replacement" v-for="replacement in currentMistake.replacements" :key="replacement">
            <button class="replacement"
                    :title="$t('spellcheck.replacement.title') + ' ' + replacement"
                    v-on:click="clickReplacement(currentMistakeDecoration, replacement)">
              {{ shorten(replacement, replacementMaxLength) }}
            </button>
          </div>
        </div>
        <div class="replacement-list" v-else>
          <i class="exi exi-small-spinner-unmasked rotating"/>
        </div>

        <div class="ignore" v-if="ignoreText != null">
          <button class="info-button icon-button"
                  :title="ignoreText"
                  v-on:click="clickIgnore()">
            <span>
              <i class="exi exi-ignore"></i> {{ ignoreText }}
            </span>
          </button>
        </div>

        <div class="add-to-dictionary" v-if="dictText != null">
          <button class="info-button icon-button"
                  :title="dictText"
                  v-on:click="clickAddToDict()">
            <span>
              <i class="exi exi-dictionary"></i> {{ dictText }}
            </span>
          </button>
        </div>

      </div>
    </div>
  </PengineEditorMenuBubble>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue} from 'vue-facing-decorator';
import {Editor} from '@tiptap/vue-3';
import {EditorState} from "@tiptap/pm/state";
import {
  PatentengineSpellcheckPlugin,
  PatentengineSpellcheckPluginKey
} from '@/components/applicationEditor/plugins/PatentengineSpellcheckPlugin';
import {SpellcheckCategoryID} from '@/api/models/spellcheck.model';
import PengineEditorMenuBubble from '@/components/applicationEditor/plugins/MenuBubble/PengineEditorMenuBubble';
import {Decoration, EditorView} from 'prosemirror-view';
import SpellcheckModule from '@/store/modules/SpellcheckModule';
import {SpellingMistake} from '@/store/models/spellcheck.model';
import {calcBubblePositioningStyleBelow} from '@/components/applicationEditor/plugins/MenuBubble/PengineEditorMenuBubble.util';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';
import {useDefaultErrorHandling} from '@/errorHandling';

@Component(
  {
    components: {
      PengineEditorMenuBubble
    }
  })
class SpellcheckOverlay extends Vue {

  @Prop({required: true})
  private editor!: Editor;
  @Prop()
  private beforeCommandExecution!: () => void;
  @Prop()
  private locale!: string;
  @Prop()
  private appDocGuid!: string;

  @Ref('spellcheckOverlay') private spellcheckOverlay!: HTMLElement;

  private originalWordMaxLength = 24;
  private replacementMaxLength = 54;

  private spellcheckPlugin: PatentengineSpellcheckPlugin | null = null;

  private currentMistakeDecoration: Decoration | null = null;
  private currentMistake: SpellingMistake | null = null;
  private position = '';

  private mistakeClass = '';
  private original = '';
  private errorType = '';
  private dictText: string | null = null;
  private ignoreText: string | null = null;

  // After replacing words we want to keep the overlay open for a while to prevent other actions
  private delayForReplacement = false;
  private delayForReplacementTime = 1500;

  private shorten(word: string, maxLength: number): string {
    if (word.length > maxLength) {
      return word.substr(0, maxLength - 1) + '...';
    }
    return word;
  }

  public update(editorView: EditorView /*, prevState: PmState*/): void {
    const state = editorView.state;
    // Save it to global variable
    this.spellcheckPlugin = PatentengineSpellcheckPluginKey.get(state) as PatentengineSpellcheckPlugin;
  }

  private updateCurrentMistakeDecoration(mistakeDecoration: Decoration | null) {
    this.currentMistakeDecoration = mistakeDecoration;
    if (mistakeDecoration) {
      this.currentMistake = mistakeDecoration.spec as SpellingMistake;
      this.mistakeClass = this.getMistakeClass(this.currentMistake);
      this.original = this.editor.state.doc.textBetween(mistakeDecoration.from, mistakeDecoration.to).trim();
      const originalShort = this.shorten(this.original, this.originalWordMaxLength);
      this.errorType = this.currentMistake.type;
      const canAddToDict = this.mistakeClass === SpellcheckCategoryID.TYPOS;
      this.dictText = canAddToDict ? `"${originalShort}" ${this.$t('spellcheck.addToDictionary.title')}` : null;
      const canIgnore = this.original != '';
      this.ignoreText = canIgnore ? `"${originalShort}" ${this.$t('spellcheck.ignore.title')}` : null;
      this.position = this.calcPosition(mistakeDecoration);
    }
  }

  private calcPosition(decoration: Decoration): string {
    return calcBubblePositioningStyleBelow(this.editor.view, this.spellcheckOverlay, decoration.from, decoration.to);
  }

  public toggleOverlayOnClick(editorState: EditorState, pos: number): void {

    // If we just replaced something, wait a little longer
    if (this.delayForReplacement) {
      return;
    }

    // Find decoration at clicked position.
    // (!) We now use clicked position, not selection start, because this is more reliable.
    // The selection position may take some time to stabilize, and it doesn't work well with placeholders.
    const decoration = this.spellcheckPlugin?.findDecoration(editorState, pos);

    // Hide overlay if clicking on the same decoration again or not clicking on a decoration at all.
    if (!decoration || this.currentMistakeDecoration?.spec === decoration?.spec) {
      this.hide();
    } else {
      this.updateCurrentMistakeDecoration(decoration as Decoration);
    }
  }

  public hideWithDelay(): void {
    // The overlay shouldn't disappear immediately otherwise clicking in the overlay (e.g. for replace) doesn't work.
    setTimeout(() => {
      // If we successfully replaced words, we want to let the overlay open for longer
      if (this.delayForReplacement) {
        setTimeout(() => {
          this.delayForReplacement = false;
          this.hide();
        }, this.delayForReplacementTime);
      } else {
        this.hide();
      }
    }, 300);
  }

  public hide(): void {
    this.updateCurrentMistakeDecoration(null);
  }

  private clickInfo(mistakeDecoration: Decoration): void {
    this.beforeExecution();
    window.open(mistakeDecoration.spec.url, '_blank');
  }

  private clickAddToDict(): void {
    SpellcheckModule.addWordToDictionary({locale: this.locale, word: this.original}).catch(useDefaultErrorHandling);
  }

  private clickIgnore(): void {
    SpellcheckModule.addWordToIgnoreList({appDocGuid: this.appDocGuid, errorType: this.errorType, word: this.original})
      .catch(useDefaultErrorHandling);
  }

  private clickReplacement(mistakeDecoration: Decoration, replacement: string): void {
    this.beforeExecution();
    if (!this.spellcheckPlugin) {
      console.error("spellcheckPlugin missing");
      return;
    }
    const tr = this.spellcheckPlugin.replaceWithSuggestion(this.editor.state, this.editor.schema, mistakeDecoration, replacement);
    this.editor.view.dispatch(tr);

    // Delete this spellcheck to let the widget and decorator get removed.
    SpellcheckModule.removeMistake(mistakeDecoration.spec);
    const spellCheck = this.editor.state.tr;
    spellCheck.setMeta(ProsemirrorTransactionMeta.SPELLCHECK_RESULT, true);
    spellCheck.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'SpellcheckOverlay - remove spellcheck mistake');
    this.editor.view.dispatch(spellCheck);

    // // TODO - PENGINESUP-560 - move to after applying the transaction. Maybe generalize side effect handling in plugin or (on transaction)
    // // Set back the focus
    // setTimeout(() => {
    //   // this.editor.focus(posAfterReplacement);
    //   // We also tell the ApplicationEditor to use position for focusing after the next server update
    //   EditorModule.setDocumentUpdateFocus(posAfterReplacement);
    // }, 1);

    // If we replace something, we want the overlay to stay open longer
    this.delayForReplacement = true;
  }

  private getMistakeClass(mistake: SpellingMistake): string {
    return mistake.type.toLowerCase().replace('_', '-');
  }

  private beforeExecution() {
    if (this.beforeCommandExecution) {
      this.beforeCommandExecution();
    }
  }
}

export default toNative(SpellcheckOverlay);
export {SpellcheckOverlay};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

$mistake-info-width: 550px;

.mistake-info-wrapper {
  position: absolute;
  width: $mistake-info-width;
  margin-left: #{-$mistake-info-width / 2};
  overflow: visible;
  opacity: 0;
  visibility: hidden;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  .mistake-info {
    position: absolute;
    margin-top: 5px;
    padding: 12px 0px 12px 0px;
    text-align: left;
    background-color: white;
    border-radius: 12px;
    z-index: 999;
    -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);

    .headline {
      padding-left: 28px;
      font-size: $font-size-normal;
      font-weight: bold;

      &:before {
        background-color: $pengine-spellcheck-unknown;
        content: '';
        height: 8px;
        width: 8px;
        border-radius: 50%;
        position: absolute;
        left: 12px;
        top: 18px;
      }
    }

    .description {
      padding: 8px 12px 8px 12px;
      font-size: $font-size-input;

      i {
        height: 13px;
        width: 13px;
        margin-bottom: -1px;
      }
    }

    .replacement-list {
      padding-left: 8px;
      padding-right: 8px;
      width: $mistake-info-width;
      float: left;

      .replacement {
        float: left;
        margin: 2px;
        max-width: 100%;

        button {
          min-height: 26.6px;
          padding: 4px 6px;
          text-align: left;
        }
      }
    }

    .add-to-dictionary, .ignore {
      float: left;
      position: relative;
      margin-top: 4px;
      padding-top: 8px;
      padding-left: 8px;
      padding-right: 8px;
      width: 100%;
      border-top: 1px solid $pengine-grey-light;

      button {
        span {
          display: flex;

          i {
            margin-right: 8px;
          }
        }

        &:hover:enabled {
          color: $pengine-blue-dark;
        }
      }
    }

    &.confused-words .headline:before {
      background-color: $pengine-spellcheck-confused-words;
    }

    &.punctuation .headline:before {
      background-color: $pengine-spellcheck-punctuation;
    }

    &.hilfestellung-kommasetzung .headline:before {
      background-color: $pengine-spellcheck-hilfestellung-kommasetzung;
    }

    &.grammar .headline:before {
      background-color: $pengine-spellcheck-grammar;
    }

    &.misc .headline:before {
      background-color: $pengine-spellcheck-misc;
    }

    &.semantics .headline:before {
      background-color: $pengine-spellcheck-semantics;
    }

    &.casing .headline:before {
      background-color: $pengine-spellcheck-casing;
    }

    &.compounding .headline:before {
      background-color: $pengine-spellcheck-compounding;
    }

    &.typography .headline:before {
      background-color: $pengine-spellcheck-typography;
    }

    &.typos .headline:before {
      background-color: $pengine-spellcheck-typos;
    }

    &.redundancy .headline:before {
      background-color: $pengine-spellcheck-redundancy;
    }

    &.correspondence .headline:before {
      background-color: $pengine-spellcheck-correspondence;
    }

    &.style .headline:before {
      background-color: $pengine-spellcheck-style;
    }

    &.colloquialisms .headline:before {
      background-color: $pengine-spellcheck-colloquialisms;
    }

    &.empfohlene-rechtschreibung .headline:before {
      background-color: $pengine-spellcheck-empfohlene-rechtschreibung;
    }
  }
}
</style>
