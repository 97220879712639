import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';

import store from '../index';
import {ApplicationFigureState} from '@/store/models/applicationFigure.model';
import {
  ApplicationFigurePreview,
  ApplicationFigureThumbnail,
  UpdateApplicationFigureEvent
} from '@/api/models/applicationFigure.model';
import {
  CreateApplicationFigure,
  DeleteApplicationFigure,
  GetApplicationFigurePreview,
  UpdateApplicationFigure, UpdateApplicationFigureMetadata
} from '@/api/services/applicationFigure.api';
import {GetApplicationFiguresForApplicationDocument} from '@/api/services/application.api';

@Module({dynamic: true, namespaced: true, store, name: 'applicationFigure'})
class ApplicationFigureModule extends VuexModule implements ApplicationFigureState {

  // initial state
  private _isApplicationFiguresLoading = false;
  private _applicationFigures: ApplicationFigureThumbnail[] = [];
  private _isPreviewLoading = false;
  private _applicationFigurePreview: ApplicationFigurePreview | null = null;

  get isApplicationFiguresLoading(): boolean {
    return this._isApplicationFiguresLoading;
  }

  get isPreviewLoading(): boolean {
    return this._isPreviewLoading;
  }

  get applicationFigures(): ApplicationFigureThumbnail[] {
    return this._applicationFigures;
  }

  get applicationFigurePreview(): ApplicationFigurePreview | null {
    return this._applicationFigurePreview;
  }

  @Mutation
  private setIsApplicationFiguresLoading(isApplicationFiguresLoading: boolean): void {
    this._isApplicationFiguresLoading = isApplicationFiguresLoading;
  }

  @Mutation
  private setApplicationFigures(applicationFigures: ApplicationFigureThumbnail[]): void {
    this._applicationFigures = applicationFigures;
  }

  @Mutation
  private setIsPreviewLoading(isPreviewLoading: boolean): void {
    this._isPreviewLoading = isPreviewLoading;
  }

  @Mutation
  private setApplicationFigurePreview(applicationFigurePreview: ApplicationFigurePreview | null): void {
    this._applicationFigurePreview = applicationFigurePreview;
  }

  @Action
  async getApplicationFigurePreview(guid: string): Promise<void> {
    this.setIsPreviewLoading(true);
    this.setApplicationFigurePreview(null);
    return GetApplicationFigurePreview(guid).then((applicationFigurePreview: ApplicationFigurePreview) => {
      this.setIsPreviewLoading(false);
      this.setApplicationFigurePreview({
                                         ...applicationFigurePreview,
                                         fullResolution: `data:image/png;base64,${applicationFigurePreview.fullResolution}`
                                       });
    }).catch((error) => {
      this.setIsPreviewLoading(false);
      throw error;
    });
  }

  @Action
  private setApplicationFiguresLoadingStart(): void {
    this.setIsApplicationFiguresLoading(true);
  }

  @Action
  private setApplicationFiguresLoadingFinished(): void {
    this.setIsApplicationFiguresLoading(false);
  }

  @Action
  async fetchApplicationFiguresForApplicationDocument(guid: string): Promise<ApplicationFigureThumbnail[]> {
    this.setApplicationFigures([]);
    this.setApplicationFiguresLoadingStart();
    return GetApplicationFiguresForApplicationDocument(guid)
      .then((applicationFigures: ApplicationFigureThumbnail[]) => {
        this.setApplicationFiguresLoadingFinished();
        this.setApplicationFigures(applicationFigures);
        return applicationFigures;
      })
      .catch((error) => {
        this.setApplicationFiguresLoadingFinished();
        throw error;
      });
  }

  @Action
  async setMainFigure(applicationFigure: ApplicationFigureThumbnail): Promise<void> {
    // Removes the old main figure
    const oldMainFigure = this._applicationFigures
      .find((figure: ApplicationFigureThumbnail) => figure.isMainFigure);

    if (oldMainFigure) {
      oldMainFigure.isMainFigure = false;
    }
    // Sets the new main figure
    const newMainFigure = this._applicationFigures
      .find((figure: ApplicationFigureThumbnail) => figure.guid === applicationFigure.guid);

    if (newMainFigure) {
      newMainFigure.isMainFigure = true;
    }
    const formData = new FormData();
    formData.append('guid', applicationFigure.guid as string);
    formData.append('position', applicationFigure.position.toString());
    formData.append('isMainFigure', 'true');

    return this.updateApplicationFigure(formData);
  }

  @Action
  async createApplicationFigure(formData: FormData): Promise<void> {
    this.setApplicationFiguresLoadingStart();
    return CreateApplicationFigure(formData)
      .then((applicationFigure: ApplicationFigureThumbnail) => {
        this.setApplicationFiguresLoadingFinished();
        return this.replaceOrPushApplicationFigure(applicationFigure);
      })
      .catch((error) => {
        this.setApplicationFiguresLoadingFinished();
        throw error;
      });
  }

  @Action
  async updateApplicationFigure(formData: FormData): Promise<void> {
    this.setApplicationFiguresLoadingStart();
    return UpdateApplicationFigure(formData)
      .then((applicationFigure: ApplicationFigureThumbnail) => {
        this.setApplicationFiguresLoadingFinished();
        return this.replaceOrPushApplicationFigure(applicationFigure);
      })
      .catch((error) => {
        this.setApplicationFiguresLoadingFinished();
        throw error;
      });
  }

  @Action
  async deleteApplicationFigure(guid: string): Promise<void> {
    this.setApplicationFiguresLoadingStart();
    return DeleteApplicationFigure(guid)
      .then((guid: string) => {
        // Removes the element from the list
        this.setApplicationFigures(
          this._applicationFigures.filter((applicationFigure: ApplicationFigureThumbnail) => applicationFigure.guid !== guid));
        this.setApplicationFiguresLoadingFinished();
      })
      .catch((error) => {
        this.setApplicationFiguresLoadingFinished();
        throw error;
      });
  }

  @Action
  private replaceOrPushApplicationFigure(applicationFigure: ApplicationFigureThumbnail) {
    const existingFigure = this._applicationFigures.find(figure => figure.guid === applicationFigure.guid);
    let newList = this._applicationFigures;
    if (existingFigure) {
      newList = newList.filter((listEntry) => (listEntry.guid !== applicationFigure.guid));
      newList.splice(applicationFigure.position, 0, applicationFigure);
    } else {
      newList.push(applicationFigure);
    }
    this.setApplicationFigures(newList);
  }

  @Action
  async updateApplicationFigureDescription(event: UpdateApplicationFigureEvent): Promise<ApplicationFigureThumbnail> {
    return UpdateApplicationFigureMetadata(event);
  }

  /**
   * Update the _applicationFigures once the description of a figure is changed.
   *
   * @param updatedApplicationFigure The application figure whose description has been updated
   */
  @Action
  updateFigureListOnDescriptionUpdate(updatedApplicationFigure: ApplicationFigureThumbnail): void {
    const localFigure = this._applicationFigures
      .find(((value, index) => value.guid === updatedApplicationFigure.guid));

    if (localFigure) {
      localFigure.description = updatedApplicationFigure.description;
    }
  }
}

export default getModule(ApplicationFigureModule);
