import {EditorState, Plugin, PluginKey, Transaction} from '@tiptap/pm/state'
import {
  applyTransaction as applyToLocalHistory,
  Branch,
  HistoryOptions as LocalHistoryOptions,
  HistoryState as LocalHistoryState,
  histTransaction
} from './prosemirror-history/prosemirror-history-clone';
import {Step as PmStep} from '@tiptap/pm/transform';
import EditorModule from '@/store/modules/EditorModule';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';

export const PatentengineHistoryPluginKey = new PluginKey<PatentEngineHistory>('patentEngineHistory');

/**
 * Class containing the state (i.e. the history) of the PatentEngineHistoryPlugin.
 * History is immutable by design (in prosemirror)
 */
export class PatentEngineHistory {

  debug = false;

  private readonly localPorsemirrorState: LocalHistoryState;
  private readonly localHistoryOptions: LocalHistoryOptions;
  private readonly _applicationDocumentGuid: string;
  private readonly _changedBlockGuid?: string;

  constructor(localState: LocalHistoryState, localHistoryOptions: LocalHistoryOptions,
              applicationDocumentGuid: string, changedBlockGuid?: string) {
    this.localPorsemirrorState = localState;
    this.localHistoryOptions = localHistoryOptions;
    this._applicationDocumentGuid = applicationDocumentGuid;
    this._changedBlockGuid = changedBlockGuid;
  }

  logDebug(text: string, object?: any) {
    if (this.debug) {
      console.log(text, object);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public relayTransactionToHistoryPlugins(tr: Transaction, oldState: EditorState, newState: EditorState): PatentEngineHistory {
    const isLogicalDepthUpdate = tr.getMeta(ProsemirrorTransactionMeta.UPDATE_LOGICAL_DEPTH_ATTRIBUTE);
    // ignore logical depth update
    if (isLogicalDepthUpdate) {
      return new PatentEngineHistory(this.localPorsemirrorState, this.localHistoryOptions,
                                     this._applicationDocumentGuid, this._changedBlockGuid);
    }
    const updateFromBackend = tr.getMeta(ProsemirrorTransactionMeta.UPDATE_FROM_BACKEND);
    if (updateFromBackend) {

      const initialState = tr.getMeta(ProsemirrorTransactionMeta.INITIAL_STATE);
      if (initialState) {
        this.logDebug('1 UpdateFromBackend - new initial state');
        return new PatentEngineHistory(PatentEngineHistory.emptyLocalState(), this.localHistoryOptions, this._applicationDocumentGuid);
      }
      // updates from the backend trigger reset of the local history unless the backend forbids it explicitly
      const keepLocalHistory = tr.getMeta(ProsemirrorTransactionMeta.KEEP_LOCAL_HISTORY);
      if (keepLocalHistory) {
        this.logDebug('2 UpdateFromBackend - keeping local history');
        return new PatentEngineHistory(this.localPorsemirrorState, this.localHistoryOptions,
                                       this._applicationDocumentGuid, this._changedBlockGuid);
      } else {
        this.logDebug('3 UpdateFromBackend - empty local history');
        return new PatentEngineHistory(PatentEngineHistory.emptyLocalState(), this.localHistoryOptions, this._applicationDocumentGuid);
      }
    } else {

      // get changed block
      const changedNode = newState.doc.resolve(newState.selection.to).node();
      const changedBlockGuid = changedNode?.attrs.guid;

      // Special case for read only blocks that can be formatted but should not add undo/redo histories for changes that never happened
      const changedNodeOld = oldState.doc.resolve(oldState.selection.to).node();
      if (changedNode.attrs.isReadOnly && changedNode.text === changedNodeOld.text) {
        const wasFormatted = tr.steps.some((step) => {
          return this.isAddMarkStep(step) || this.isRemoveMarkStep(step)
        });
        // No changes
        if (!wasFormatted) {
          this.logDebug('4 Local Change - record no change');
          return new PatentEngineHistory(this.localPorsemirrorState, this.localHistoryOptions,
                                         this._applicationDocumentGuid, this._changedBlockGuid);
        }
      }

      // updates done in the frontend append to local history
      this.logDebug('5 Local Change - record change');
      const newLocalState = applyToLocalHistory(this.localPorsemirrorState, oldState, tr, this.localHistoryOptions);
      return new PatentEngineHistory(newLocalState, this.localHistoryOptions, this._applicationDocumentGuid, changedBlockGuid);
    }
  }

  private isAddMarkStep(step: PmStep): boolean {
    return step.toJSON().stepType === "addMark";
  }

  private isRemoveMarkStep(step: PmStep): boolean {
    return step.toJSON().stepType === "removeMark";
  }

  /**
   * Undos a local change using prosemirror-history plugin logic
   * @param state EditorState used to modify the document
   * @param dispatch dispatch routine to commit undo transaction to state
   */
  public undo(state: EditorState, dispatch?: (tr: Transaction) => void): boolean {
    const result = histTransaction(this.localPorsemirrorState, state, false, this.localHistoryOptions);
    if (result && dispatch) {
      const tr = result.transaction.setMeta(ProsemirrorTransactionMeta.PATENTENGINE_HISTORY_PLUGIN,
                                                            new PatentEngineHistory(result.history, this.localHistoryOptions,
                                                                                    this._applicationDocumentGuid));
      tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'PatentEngineHistory - undo');
      dispatch(tr);
      return true;
    }
    return false;
  }

  /**
   * Redos a local change using prosemirror-history plugin logic
   * @param state EditorState used to modify the document
   * @param dispatch dispatch routine to commit redo transaction to state
   */
  public redo(state: EditorState, dispatch?: (tr: Transaction) => void): boolean {
    const result = histTransaction(this.localPorsemirrorState, state, true, this.localHistoryOptions);
    if (result && dispatch) {
      const tr = result.transaction.setMeta(ProsemirrorTransactionMeta.PATENTENGINE_HISTORY_PLUGIN,
                                                            new PatentEngineHistory(result.history, this.localHistoryOptions,
                                                                                    this._applicationDocumentGuid));
      tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'PatentEngineHistory - redo');
      dispatch(tr);
      return true;
    }
    return false;
  }

  /**
   * Creates an empty local state for reseting the prosemirror history plugin
   */
  public static emptyLocalState(): LocalHistoryState {
    return new LocalHistoryState(Branch.empty, Branch.empty, null, 0);
  }

  /**
   * Checks if the local history is empty
   */
  get isLocalHistoryEmpty(): boolean {
    return this.localPorsemirrorState.done.eventCount == 0;
  }

  get undoCount(): number {
    return this.localPorsemirrorState.done.eventCount;
  }

  get redoCount(): number {
    return this.localPorsemirrorState.undone.eventCount;
  }

  get applicationDocumentGuid(): string {
    return this._applicationDocumentGuid;
  }

  get changedBlockGuid(): string | undefined {
    return this._changedBlockGuid;
  }
}

/**
 * The actual history plugin for Patentengine.
 */
export class PatentengineHistoryPlugin extends Plugin {

  constructor(localHistoryOptions: LocalHistoryOptions, applicationDocumentGuid: string) {
    super({
            key: PatentengineHistoryPluginKey,
            props: {},
            state: {
              /**
               * Inits the state of the plugin
               * See https://prosemirror.net/docs/ref/#state.StateField
               */
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              init(config: object, instance: EditorState): PatentEngineHistory {
                const empty = PatentEngineHistory.emptyLocalState();
                return new PatentEngineHistory(empty, localHistoryOptions, applicationDocumentGuid);
              },
              /**
               * Hook to intercept transactions.
               * Used to store undo/redo stepts in the history
               */
              apply(tr: Transaction, pluginState: PatentEngineHistory, oldState: EditorState, newState: EditorState): PatentEngineHistory {
                const newHistory = pluginState.relayTransactionToHistoryPlugins(tr, oldState, newState);
                EditorModule.setLocalUndoCount(newHistory.undoCount);
                EditorModule.setLocalRedoCount(newHistory.redoCount);
                return newHistory;
              }
            }
          });
  }

  /**
   * Retrieves the number of available undo steps
   */
  public undoStepsAvailable(state: EditorState, dispatch?: (tr: Transaction) => void): number {
    const peState = PatentengineHistoryPluginKey.getState(state);
    if (peState) {
      return peState.undoCount;
    }
    return 0;
  }

  /**
   * Retrieves the number of available redo steps
   */
  public redoStepsAvailable(state: EditorState, dispatch?: (tr: Transaction) => void): number {
    const peState = PatentengineHistoryPluginKey.getState(state);
    if (peState) {
      return peState.redoCount;
    }
    return 0;
  }

  /**
   * Undo command executing an undo step
   */
  public undo(state: EditorState, dispatch?: (tr: Transaction) => void): boolean {
    const peState = PatentengineHistoryPluginKey.getState(state);
    if (peState) {
      return peState.undo(state, dispatch);
    }
    return false;
  }

  /**
   * Redo command executing a redo step
   */
  public redo(state: EditorState, dispatch?: (tr: Transaction) => void): boolean {
    const peState = PatentengineHistoryPluginKey.getState(state);
    if (peState) {
      return peState.redo(state, dispatch);
    }
    return false;
  }
}
