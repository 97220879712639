import {axiosInstance, axiosLlmRequestInstance} from '@/api';
import {Llm} from '@/api/models/Llm.model';
import {LlmRequest} from '@/api/models/LlmRequest.model';
import {CompletionResult} from '@/api/models/CompletionResult.model';
import {AiTemplate} from '@/api/models/aiTemplate.model';
import CompletionResultModule from '@/store/modules/CompletionResultModule';
import {DynamicByteBuffer} from '@/components/applicationEditor/utils/DynamicByteBuffer.util';
import {InterpretAiTemplateRequestDto, InterpretAiTemplateResponseDto} from '@/api/models/aiAssisstant.model';
import {PatentEngineException} from '@/api/models/exception.model';


/**
 * The connection to the backend endoints for the aiFeature are defined here
 * */
const AI_ASSISTANT_PATH = '/aiAssistant';

export const GetAllLlms = async (): Promise<Array<Llm>> => {
  const res = await axiosInstance.get(`${AI_ASSISTANT_PATH}/llms`);
  return res?.data as Array<Llm>;
}
export const SendLlmRequest = async (llmRequest: LlmRequest): Promise<CompletionResult> => {
  const res = await axiosLlmRequestInstance.post(`${AI_ASSISTANT_PATH}/chat/completion`, llmRequest);
  return res?.data;
}
export const UpdateRating = async (resultId: string, rating: number): Promise<CompletionResult> => {
  const res = await axiosInstance.put(`${AI_ASSISTANT_PATH}/completionResults/${resultId}/${rating}`);
  return res?.data;
}
export const GetAiTemplates = async (applicationDocumentGuid: string): Promise<Array<AiTemplate>> => {
  const res = await axiosInstance.get(`${AI_ASSISTANT_PATH}/templates?applicationDocument=${applicationDocumentGuid}`);
  return res?.data;
}

export const InterpretAiTemplate = async (interpretAiTemplateRequestDto: InterpretAiTemplateRequestDto): Promise<InterpretAiTemplateResponseDto> => {
  const res = await axiosLlmRequestInstance.post(`${AI_ASSISTANT_PATH}/interpretAiTemplate`, interpretAiTemplateRequestDto);
  return res?.data;
}

// TODO: Tests schreiben
export const SendLlmRequestStream = async (llmRequest: LlmRequest): Promise<void> => {
  const decoder = new TextDecoder('utf-8');
  const baseUrl = process.env.VUE_APP_API_URL;
  const responsePromise = fetch(`${baseUrl}${AI_ASSISTANT_PATH}/chat/streamingCompletion`,
                               {
                                 method: 'POST',
                                 headers: {
                                   'Accept': 'application/json',
                                   'Content-Type': 'application/json'
                                 },
                                 body: JSON.stringify(llmRequest)
                               });
  return responsePromise.then(async response => {
    if(!response.ok){
      const errorResponse = await response.json().catch(() => null);
      if(errorResponse == null){
        return Promise.reject(null);
      }
      const exception = errorResponse as PatentEngineException;
      return Promise.reject(exception.localizedMessage);
    }
    const reader = response.body!.getReader();
    return readCompletionResultStream(reader);
  })
}

async function readCompletionResultStream(reader: ReadableStreamDefaultReader): Promise<void> {
  const decoder = new TextDecoder('utf-8');
  const streamBuffer = new DynamicByteBuffer();
  let done2 = false;
  let llmResult: CompletionResult | null = null;
  while (!done2) {
    const {done, value} = await reader.read();
    done2 = done;
    if (done2) {
      return Promise.resolve();
    }
    streamBuffer.append(value);
    let objectLength = streamBuffer.readInt32BigEndian()
    while (objectLength !== null && !isNaN(objectLength) && streamBuffer.getLength() >= objectLength + 4) {
      streamBuffer.cutBytes(4);
      const chunk = streamBuffer.cutBytes(objectLength);
      llmResult = JSON.parse(decoder.decode(chunk)) as CompletionResult;
      objectLength = streamBuffer.readInt32BigEndian();
    }
    if (llmResult !== null) {
      updateStreamingResult(llmResult);
    }
  }
  return Promise.resolve();
}

function updateStreamingResult(completionResult: CompletionResult) {
  CompletionResultModule.setChunkedResult(completionResult);
}