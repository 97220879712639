/**
 * Semantic Type of a Block.
 */
import {LocalizedMessage} from '@/api/models/exception.model';
import {ReferenceSign} from '@/api/models/referenceSign.model';
import {isEqual, pick} from 'lodash';

export enum SemanticType {

  /**
   * Identifies the root of an application document.
   */
  APPLICATION_DOCUMENT = 'APPLICATION_DOCUMENT',

  // Chapters

  /**
   * Identifies the cover sheet part of an application document.
   */
    // Change the value to make the coverSheet entry in the tree not empty
  COVER_SHEET = 'COVER_SHEET',

  /**
   * Identifies the description chapter of an application document.
   */
  DESCRIPTION = 'DESCRIPTION',

  /**
   * Identifies the reference sign list chapter of an application document.
   */
  REFERENCE_SIGN_LIST = 'REFERENCE_SIGN_LIST',

  /**
   * Identifies the reference sign list row.
   */
  REFERENCE_SIGN_LIST_ROW = 'REFERENCE_SIGN_LIST_ROW',

  /**
   * Identifies the reference sign list row name.
   */
  REFERENCE_SIGN_LIST_ROW_NAME = 'REFERENCE_SIGN_LIST_ROW_NAME',

  /**
   * Identifies the claim list chapter of an application document.
   */
  CLAIM_LIST = 'CLAIM_LIST',

  /**
   * Identifies the abstract chapter of an application document.
   */
  APPLICATION_ABSTRACT = 'applicationAbstract',

  /**
   * Identifies the (text) content of the abstract chapter.
   */
  APPLICATION_ABSTRACT_CONTENT = 'APPLICATION_ABSTRACT_CONTENT',

  /**
   * Identifies the preamble of the abstract content.
   */
  APPLICATION_ABSTRACT_PREAMBLE = 'APPLICATION_ABSTRACT_PREAMBLE',

  /**
   * Identifies the charcterizing part of the abstract content.
   */
  APPLICATION_ABSTRACT_CHARACTERIZING_PART = 'APPLICATION_ABSTRACT_CHARACTERIZING_PART',

  /**
   * Identifies the beginning of an abstract.
   */
  APPLICATION_ABSTRACT_PREAMBLE_PREFACE = 'applicationAbstractPreamblePreface',

  /**
   * Identifies the generic term mentioned within the abstract.
   */
  APPLICATION_ABSTRACT_PREAMBLE_GENERIC_TERM = 'APPLICATION_ABSTRACT_PREAMBLE_GENERIC_TERM',

  /**
   * Identifies the complement text to the generic term within the abstract.
   */
  APPLICATION_ABSTRACT_PREAMBLE_COMPLEMENT_TEXT = 'APPLICATION_ABSTRACT_PREAMBLE_COMPLEMENT_TEXT',

  /**
   * Identifies the preamble extracted from the main claim inside the abstract.
   */
  APPLICATION_ABSTRACT_PREAMBLE_TEXT = 'APPLICATION_ABSTRACT_PREAMBLE_TEXT',

  /**
   * Identifies the beginning of the first claim within the abstract
   */
  APPLICATION_ABSTRACT_CHARACTERIZING_PART_PREFACE = 'APPLICATION_ABSTRACT_CHARACTERIZING_PART_PREFACE',

  /**
   * Identifies the characterizing part extracted from the main claim inside the abstract.
   */
  APPLICATION_ABSTRACT_CHARACTERIZING_PART_TEXT = 'APPLICATION_ABSTRACT_CHARACTERIZING_PART_TEXT',

  /**
   * Identifies the figure part of the abstract.
   */
  APPLICATION_ABSTRACT_FIGURE = 'APPLICATION_ABSTRACT_FIGURE',

  /**
   * Identifies the figure text inside the figure part of the abstract
   */
  APPLICATION_ABSTRACT_FIGURE_TEXT = 'APPLICATION_ABSTRACT_FIGURE_TEXT',

  // Description -> GeneralDescription

  /**
   * Identifies the general description subchapter in the description chapter.
   */
  GENERAL_DESCRIPTION = 'GENERAL_DESCRIPTION',

  /**
   * Identifies the generic term list subchapter in the general description subchapter.
   */
  GENERIC_TERM_LIST = 'GENERIC_TERM_LIST',

  /**
   * Identifies the context of a main claim in the generic term list subchapter with a term definition.
   */
  GENERIC_TERM_MAIN_CLAIM_CONTEXT_WITH_TERM_DEFINITION = 'GENERIC_TERM_MAIN_CLAIM_CONTEXT_WITH_TERM_DEFINITION',

  /**
   * Identifies the context of a main claim in the generic term list subchapter.
   */
  GENERIC_TERM_MAIN_CLAIM_CONTEXT = 'GENERIC_TERM_MAIN_CLAIM_CONTEXT',

  /**
   * Identifies the preface of a main claim in the generic term list subchapter.
   */
  GENERIC_TERM_MAIN_CLAIM_PREFACE = 'GENERIC_TERM_MAIN_CLAIM_PREFACE',

  /**
   * Identifies the preface of a main claim in the generic term list subchapter.
   */
  GENERIC_TERM_COPY = 'GENERIC_TERM_COPY',

  /**
   * Identifies the preface of a main claim in the generic term list subchapter.
   */
  GENERIC_TERM_COMPLEMENT_TEXT_COPY = 'GENERIC_TERM_COMPLEMENT_TEXT_COPY',

  /**
   * Identifies the claim reference of a claim in the generic term list subchapter.
   */
  CLAIM_REFERENCE = 'CLAIM_REFERENCE',

  /**
   * Identifies the context of a parallel claim in the generic term list subchapter with a term definition.
   */
  GENERIC_TERM_PARALLEL_CLAIM_CONTEXT_WITH_TERM_DEFINITION = 'GENERIC_TERM_PARALLEL_CLAIM_CONTEXT_WITH_TERM_DEFINITION',

  /**
   * Identifies the context of a parallel claim in the generic term list subchapter.
   */
  GENERIC_TERM_PARALLEL_CLAIM_CONTEXT = 'GENERIC_TERM_PARALLEL_CLAIM_CONTEXT',

  /**
   * Identifies the connecting element of a parallel claim in the generic term list subchapter.
   */
  GENERIC_TERM_CONNECTING_ELEMENT = 'GENERIC_TERM_CONNECTING_ELEMENT',

  /**
   * Identifies the closest prior art subchapter in the general description subchapter.
   */
  CLOSEST_PRIOR_ART = 'CLOSEST_PRIOR_ART',

  /**
   * Identifies the preface in the closest prior art subchapter.
   */
  CLOSEST_PRIOR_ART_PREFACE = 'CLOSEST_PRIOR_ART_PREFACE',

  /**
   * Identifies the reference in the closest prior art subchapter.
   */
  CLOSEST_PRIOR_ART_REFERENCE = 'CLOSEST_PRIOR_ART_REFERENCE',

  /**
   * Identifies the connecting element in the closest prior art subchapter.
   */
  CLOSEST_PRIOR_ART_CONNECTING_ELEMENT = 'CLOSEST_PRIOR_ART_CONNECTING_ELEMENT',

  /**
   * Identifies the technical field explanation subchapter in the general description subchapter.
   */
  EXPLANATION_TECHNICAL_FIELD = 'EXPLANATION_TECHNICAL_FIELD',

  /**
   * Identifies the text in the technical field explanation subchapter.
   */
  EXPLANATION_TECHNICAL_FIELD_TEXT = 'EXPLANATION_TECHNICAL_FIELD_TEXT',

  /**
   * Identifies the challenge subchapter in the general description subchapter.
   */
  CHALLENGE = 'CHALLENGE',

  /**
   * Identifies the text in the challenge subchapter.
   */
  CHALLENGE_TEXT = 'CHALLENGE_TEXT',

  // Description -> FigureDescription

  /**
   * Identifies the figure description subchapter in the description chapter.
   */
  FIGURE_DESCRIPTION = 'FIGURE_DESCRIPTION',

  /**
   * Identifies the preface in the figure description subchapter.
   */
  FIGURE_PREFACE = 'FIGURE_PREFACE',

  /**
   * Identifies the text of the figure description preface.
   */
  FIGURE_PREFACE_TEXT = 'FIGURE_PREFACE_TEXT',

  /**
   * Identifies the short description figure list in the figure description subchapter.
   */
  SHORT_DESCRIPTION_FIGURE_LIST = 'SHORT_DESCRIPTION_FIGURE_LIST',

  /**
   * Identifies a short description figure in the short description figure list.
   */
  SHORT_DESCRIPTION_FIGURE = 'SHORT_DESCRIPTION_FIGURE',

  /**
   * Identifies the text of a short description figure.
   */
  SHORT_DESCRIPTION_FIGURE_TEXT = 'SHORT_DESCRIPTION_FIGURE_TEXT',

  /**
   * Identifies the connecting element of a short description figure.
   */
  SHORT_DESCRIPTION_FIGURE_CONNECTING_ELEMENT = 'SHORT_DESCRIPTION_FIGURE_CONNECTING_ELEMENT',

  /**
   * Identifies the explanation preamble in the figure description subchapter.
   */
  EXPLANATION_PREAMBLE = 'EXPLANATION_PREAMBLE',

  /**
   * Identifies the text of an explanation preamble.
   */
  EXPLANATION_PREAMBLE_TEXT = 'EXPLANATION_PREAMBLE_TEXT',

  /**
   * Identifies the support list in the figure description subchapter.
   */
  SUPPORT_LIST = 'SUPPORT_LIST',

  /**
   * Identifies the support main claim context in the support list.
   */
  SUPPORT_MAIN_CLAIM_CONTEXT = 'SUPPORT_MAIN_CLAIM_CONTEXT',

  /**
   * Identifies the support main claim in the support main claim context.
   */
  SUPPORT_MAIN_CLAIM = 'SUPPORT_MAIN_CLAIM',

  /**
   * Identifies the support preamble context in the support main/parallel claim.
   */
  SUPPORT_PREAMBLE_CONTEXT = 'SUPPORT_PREAMBLE_CONTEXT',

  /**
   * Identifies the support preamble in the support preamble context.
   */
  SUPPORT_PREAMBLE = 'SUPPORT_PREAMBLE',

  /**
   * Identifies the support preamble preface in the support preamble.
   */
  SUPPORT_PREAMBLE_PREFACE = 'SUPPORT_PREAMBLE_PREFACE',

  /**
   * Identifies the term definition.
   */
  TERM_DEFINITION = 'TERM_DEFINITION',

  /**
   * Identifies the term definition text in the term definition.
   */
  TERM_DEFINITION_TEXT = 'TERM_DEFINITION_TEXT',

  /**
   * Identifies the support characterizing part context in the support main/parallel/sub claim.
   */
  SUPPORT_CHARACTERIZING_PART_CONTEXT = 'SUPPORT_CHARACTERIZING_PART_CONTEXT',

  /**
   * Identifies the support characterizing part in the support characterizing part context.
   */
  SUPPORT_CHARACTERIZING_PART = 'SUPPORT_CHARACTERIZING_PART',

  /**
   * Identifies the support characterizing part preface in the support characterizing part.
   */
  SUPPORT_CHARACTERIZING_PART_PREFACE = 'SUPPORT_CHARACTERIZING_PART_PREFACE',

  /**
   * Identifies the support sub claim the support main/parallel claim context.
   */
  SUPPORT_SUB_CLAIM = 'SUPPORT_SUB_CLAIM',

  /**
   * Identifies the support parallel claim context in the support list.
   */
  SUPPORT_PARALLEL_CLAIM_CONTEXT = 'SUPPORT_PARALLEL_CLAIM_CONTEXT',

  /**
   * Identifies the support parallel claim in the support parallel claim context.
   */
  SUPPORT_PARALLEL_CLAIM = 'SUPPORT_PARALLEL_CLAIM',

  /**
   * Identifies the support explanation preamble in the support main/parallel claim.
   */
  SUPPORT_EXPLANATION_PREAMBLE = 'SUPPORT_EXPLANATION_PREAMBLE',

  /**
   * Identifies the support explanation preamble text in the support explanation preamble.
   */
  SUPPORT_EXPLANATION_PREAMBLE_TEXT = 'SUPPORT_EXPLANATION_PREAMBLE_TEXT',

  /**
   * Identifies the support explanation characterizing part in the support main/parallel/sub claim.
   */
  SUPPORT_EXPLANATION_CHARACTERIZING_PART = 'SUPPORT_EXPLANATION_CHARACTERIZING_PART',

  /**
   * Identifies support explanation characterizing part text in the support explanation characterizing part.
   */
  SUPPORT_EXPLANATION_CHARACTERIZING_PART_TEXT = 'SUPPORT_EXPLANATION_CHARACTERIZING_PART_TEXT',

  /**
   * Identifies the solution of the problem subchapter in the general description subchapter.
   */
  SOLUTION_OF_THE_PROBLEM = 'SOLUTION_OF_THE_PROBLEM',

  /**
   * Identifies the problem solution part in the solution of the problem subchapter.
   */
  PROBLEM_SUGGESTION = 'PROBLEM_SUGGESTION',

  /**
   * Identifies the preface of the problem solution part.
   */
  PROBLEM_SUGGESTION_PREFACE = 'PROBLEM_SUGGESTION_PREFACE',

  /**
   * Identifies the postface of the problem solution part.
   */
  PROBLEM_SUGGESTION_POSTFACE = 'PROBLEM_SUGGESTION_POSTFACE',

  /**
   * Identifies the formal solution of the main claim part in the solution of the problem subchapter.
   */
  FORMAL_SOLUTION_MAIN_CLAIM = 'FORMAL_SOLUTION_MAIN_CLAIM',

  /**
   * Identifies the text in the formal solution of the main claim part.
   */
  FORMAL_SOLUTION_MAIN_CLAIM_TEXT = 'FORMAL_SOLUTION_MAIN_CLAIM_TEXT',

  /**
   * Identifies the explanation of the invention part in the solution of the problem subchapter.
   */
  EXPLANATION_INVENTION = 'EXPLANATION_INVENTION',

  /**
   * Identifies the preface of the explanation of the invention part.
   */
  EXPLANATION_INVENTION_PREFACE = 'EXPLANATION_INVENTION_PREFACE',

  /**
   * Identifies the text of the explanation of the invention part.
   */
  EXPLANATION_INVENTION_TEXT = 'EXPLANATION_INVENTION_TEXT',

  /**
   * Identifies the reproduction of the characterizing part in the solution of the problem subchapter with a term definition.
   */
  REPRODUCTION_CHARACTERIZING_PART_WITH_TERM_DEFINITION = 'REPRODUCTION_CHARACTERIZING_PART_WITH_TERM_DEFINITION',

  /**
   * Identifies the reproduction of the characterizing part in the solution of the problem subchapter.
   */
  REPRODUCTION_CHARACTERIZING_PART = 'REPRODUCTION_CHARACTERIZING_PART',

  /**
   * Identifies the preface of the reproduction of the characterizing part.
   */
  REPRODUCTION_CHARACTERIZING_PART_PREFACE = 'REPRODUCTION_CHARACTERIZING_PART_PREFACE',

  /**
   * Identifies the advantage sub claims list in the solution of the problem subchapter.
   */
  ADVANTAGE_SUB_CLAIM_LIST = 'ADVANTAGE_SUB_CLAIM_LIST',

  /**
   * Identifies the advantage of a sub claim in the advantage sub claims list with a term definition.
   */
  ADVANTAGE_SUB_CLAIM_WITH_TERM_DEFINITION = 'ADVANTAGE_SUB_CLAIM_WITH_TERM_DEFINITION',

  /**
   * Identifies the advantage of a sub claim in the advantage sub claims list.
   */
  ADVANTAGE_SUB_CLAIM = 'ADVANTAGE_SUB_CLAIM',

  /**
   * Identifies the text of the advantage of the sub claim part.
   */
  ADVANTAGE_SUB_CLAIM_TEXT = 'ADVANTAGE_SUB_CLAIM_TEXT',

  /**
   * Identifies the formal solution parallel claim list in the solution of the problem subchapter.
   */
  FORMAL_SOLUTION_PARALLEL_CLAIM_LIST = 'FORMAL_SOLUTION_PARALLEL_CLAIM_LIST',

  /**
   * Identifies the formal solution of a parallel claim part in the formal solution parallel claim list.
   */
  FORMAL_SOLUTION_PARALLEL_CLAIM = 'FORMAL_SOLUTION_PARALLEL_CLAIM',

  /**
   * Identifies the context for a solution of a parallel claim in the formal solution of a parallel claim part.
   */
  SOLUTION_PARALLEL_CLAIM_CONTEXT = 'SOLUTION_PARALLEL_CLAIM_CONTEXT',

  /**
   * Identifies the preface in the context for a solution of a parallel claim.
   */
  FORMAL_SOLUTION_PARALLEL_CLAIM_PREFACE = 'FORMAL_SOLUTION_PARALLEL_CLAIM_PREFACE',

  /**
   * Identifies the text in the context for a solution of a parallel claim.
   */
  FORMAL_SOLUTION_PARALLEL_CLAIM_TEXT = 'FORMAL_SOLUTION_PARALLEL_CLAIM_TEXT',

  /**
   * Identifies the postface in the context for a solution of a parallel claim.
   */
  FORMAL_SOLUTION_PARALLEL_CLAIM_POSTFACE = 'FORMAL_SOLUTION_PARALLEL_CLAIM_POSTFACE',

  /**
   * Identifies the characterizing part reference part in the formal solution of a parallel claim part with a term definition.
   */
  CHARACTERIZING_PART_REFERENCE_WITH_TERM_DEFINITION = 'CHARACTERIZING_PART_REFERENCE_WITH_TERM_DEFINITION',

  /**
   * Identifies the characterizing part reference part in the formal solution of a parallel claim part.
   */
  CHARACTERIZING_PART_REFERENCE = 'CHARACTERIZING_PART_REFERENCE',

  /**
   * Identifies the preface of the characterizing part reference part.
   */
  CHARACTERIZING_PART_REFERENCE_PREFACE = 'CHARACTERIZING_PART_REFERENCE_PREFACE',

  /**
   * Identifies the generic term reference part in the formal solution of a parallel claim part.
   */
  GENERIC_TERM_REFERENCE = 'GENERIC_TERM_REFERENCE',

  /**
   * Identifies the preface in the generic term reference part.
   */
  GENERIC_TERM_REFERENCE_PREFACE = 'GENERIC_TERM_REFERENCE_PREFACE',

  /**
   * Identifies the list in the generic term reference part.
   */
  GENERIC_TERM_REFERENCE_LIST = 'GENERIC_TERM_REFERENCE_LIST',

  /**
   * Identifies the postface in the generic term reference part.
   */
  GENERIC_TERM_REFERENCE_POSTFACE = 'GENERIC_TERM_REFERENCE_POSTFACE',

  // Claim

  /**
   * Identifies the context of a main claim
   */
  MAIN_CLAIM_CONTEXT = 'MAIN_CLAIM_CONTEXT',

  /**
   * Identifies a main claim paragraph in an applicaiton document's claim list.
   */
  MAIN_CLAIM = 'MAIN_CLAIM',

  /**
   * Identifies the context of a parallel claim
   */
  PARALLEL_CLAIM_CONTEXT = 'PARALLEL_CLAIM_CONTEXT',

  /**
   * Identifies a parallel claim paragraph in an applicaiton document's claim list.
   */
  PARALLEL_CLAIM = 'PARALLEL_CLAIM',

  /**
   * Identifies a sub claim paragraph in a main or parallel claim.
   */
  SUB_CLAIM = 'SUB_CLAIM',

  /**
   * Identifies a preamble in a claim.
   */
  PREAMBLE = 'PREAMBLE',

  /**
   * Identifies a preamble in a claim sub claim.
   */
  PREAMBLE_SUB_CLAIM = 'PREAMBLE_SUB_CLAIM',

  /**
   * Identifies the generic term of a claim.
   */
  GENERIC_TERM = 'GENERIC_TERM',

  /**
   * Identifies the text that complements the generic term of a claim.
   */
  GENERIC_TERM_COMPLEMENT_TEXT = 'GENERIC_TERM_COMPLEMENT_TEXT',

  /**
   * Identifies the preamble text of a claim.
   */
  PREAMBLE_TEXT = 'PREAMBLE_TEXT',

  /**
   * Identifies the characterizing part of a claim.
   */
  CHARACTERIZING_PART = 'CHARACTERIZING_PART',

  /**
   * Identifies the preface of a claim's characterizing part.
   */
  CHARACTERIZING_PART_PREFACE = 'CHARACTERIZING_PART_PREFACE',

  /**
   * Identifies the text of a claim's characterizing part.
   */
  CHARACTERIZING_PART_TEXT = 'CHARACTERIZING_PART_TEXT',

  /**
   * Identifies the free text block.
   */
  FREE_TEXT_BLOCK = 'FREE_TEXT_BLOCK',

  /**
   * Identifies the free text block text.
   */
  FREE_TEXT_BLOCK_TEXT = 'FREE_TEXT_BLOCK_TEXT'
}

/**
 * Inline Mode of a Block.
 */
export enum InlineMode {

  /**
   * Identifies inline is not set.
   */
  NONE = 'none',

  /**
   * Identifies implicit set of inline state.
   */
  IMPLICIT = 'implicit',

  /**
   * Identifies explicit set of inline state.
   */
  EXPLICIT = 'explicit'
}

// ##### ViewModels ###############################################################################

/**
 * Abstract view model for all kinds of blocks.
 */
export interface AbstractBlockViewModel {
  guid: string;
  semanticType: SemanticType;
  logicalBlock: boolean;
  generatedBlock: boolean;
  optionalBlock: boolean;
  conditionalBlock: boolean;
  readOnly: boolean;
  active: boolean;
  reviewNeeded: boolean;
  sourceBlockId: string | null;
  properties: any;

  parent: AbstractBlockViewModel | null;
}

/**
 * A block holding any type of block as children.
 */
export interface StructuralBlockViewModel extends AbstractBlockViewModel {
  children: AbstractBlockViewModel[];
  creatableBlocks: string[];
  inlineMode: InlineMode;
}

export function isStructuralBlockViewModel(blockViewModel: AbstractBlockViewModel): blockViewModel is StructuralBlockViewModel {
  return (blockViewModel as StructuralBlockViewModel).children !== undefined;
}

/**
 * The view model for a simple text block.
 */
export interface TextBlockViewModel extends AbstractBlockViewModel {
  richText: string;
}

export function isTextBlockViewModel(blockViewModel: AbstractBlockViewModel): blockViewModel is TextBlockViewModel {
  return (blockViewModel as TextBlockViewModel).richText !== undefined;
}


export interface FigureBlockViewModel extends AbstractBlockViewModel {
  figureId: number;
  figureName: string;
  description: string;
}

// ##### Events ###################################################################################

/**
 * Event to create a new Block in an application document
 */
export interface CreateBlockEvent {

  // The GUID of the block's designated parent block
  parentGuid: string;

  // The GUID of the block's designated sibling block after which it should be created (if possible)
  childGuid: string;

  // The semantic type of the new block
  semanticType: SemanticType;
}

/**
 * Event to update an existing block in an application document
 */
export interface UpdateBlockViewModel {

  guid: string;

  // The updated HTML-styled text for the block
  richText: string;

  libraryReferences: Array<string>;
}

/**
 * Event to update existing blocks in an application document
 */
export interface UpdateBlocksEvent {

  // List of single update events for several blocks
  blocks: Array<UpdateBlockViewModel>;
}

/**
 * Event to update an existing block with its inline state in an application document
 */
export interface UpdateBlockInlineEvent {

  // The GUID of the block which inline state will be changed
  guid: string;

  // The updated inline state for the block
  isInline: boolean;
}

/**
 * Event to generate an existing block in an application document
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GenerateBlockEvent {

  guid: string;
}

// ##### VmUpdates ################################################################################

/**
 * A object to describe blocks within localized messages for commands
 */
export interface BlockDescriptor {
  type: string;
}

/**
 * A block descriptor to describe a single block, e.g. "main claim" or "parallel claim 3"
 */
export interface SingleBlockDescriptor extends BlockDescriptor {
  semanticType: SemanticType;
  additionalValue: string;
}


/**
 * A block descriptor to describe a block within another block, e.g. "characterizing part in support of parallel claim 3"
 */
export interface WithinBlockDescriptor extends BlockDescriptor {
  child: SingleBlockDescriptor;
  parent: SingleBlockDescriptor;
}

/**
 * A block descriptor to describe a block that refers to another block, e.g. "generic term of main claim"
 */
export interface ReferenceBlockDescriptor extends BlockDescriptor {
  referer: SingleBlockDescriptor;
  reference: SingleBlockDescriptor;
}

/**
 * A block descriptor for a list of other block descriptors,
 * e.g. "generic term, complement text of generic term, abstract of complement text"
 */
export interface MultipleBlockDescriptor extends BlockDescriptor {
  list: BlockDescriptor[];
}

/**
 * Special LocalizedMessage for CommandStackItems
 */
export interface CommandLocalizedMessage extends LocalizedMessage {
  values: BlockDescriptor[];
}

export interface CommandStackItemViewModel {
  guid: string;
  description: CommandLocalizedMessage;
}

export interface CommandStackViewModel {
  stackItems: Array<CommandStackItemViewModel>;
  stackPointer: string;
}

export interface BlockVmUpdateBase {
  commandStack: CommandStackViewModel;
  name: string;
}

export interface CommandStackVmUpdate {
  commandStack: CommandStackViewModel;
  vmUpdates: Array<BlockVmUpdateBase>;
}

/**
 * View model update for a newly created Block
 */
export interface BlockCreatedVmUpdate extends BlockVmUpdateBase {

  // the GUID of the block to add the new block as child to
  parentGuid: string;

  // the position of the new block within it's parent's children
  positionAsChild: number;

  // the view model of the newly created block
  block: AbstractBlockViewModel;

  // other blocks that were affected by the creation
  updatedBlocks: Array<AbstractBlockViewModel>;
}

/**
 * View model update for multiple updated blocks
 */
export interface BlocksUpdatedVmUpdate extends BlockVmUpdateBase {
  blocks: Array<AbstractBlockViewModel>;

  // other blocks that were affected by the creation
  affectedBlocks: Array<AbstractBlockViewModel>;

  applicationDocumentAffected: boolean;

  inline: boolean; // TODO: is this used?
}

/**
 * View model update for inline updated blocks
 */
export interface BlockInlineVmUpdate extends BlockVmUpdateBase {

  guid: string; // the GUID of the block that was originally changed by the user

  blocks: Array<AbstractBlockViewModel>; // affected blocks
}

/**
 * View model update for a deleted block
 */
export interface BlockDeletedVmUpdate extends BlockVmUpdateBase {

  // the GUID of the deleted block
  guid: string;

  // the GUID of the parent of the deleted block
  parentGuid: string;

  // other blocks that were affected by the deletion
  updatedBlocks: Array<AbstractBlockViewModel>;
}

/**
 * View model update for a reviewed block
 */
export interface BlockReviewedVmUpdate {

  // the GUID of the reviewed block
  guid: string;
}

/**
 * View model update for generated blocks
 */
export interface BlocksGeneratedVmUpdate extends BlockVmUpdateBase {

  // the guid of the block that was generated (if it was not a 'generate all')
  guid?: string;

  // the view models of the newly added blocks
  addedBlocks: Array<BlockCreatedVmUpdate>;
  // the view models of the generated blocks
  updatedBlocks: BlocksUpdatedVmUpdate;
  // the view models of the deleted blocks
  deletedBlocks: Array<BlockDeletedVmUpdate>;
  // reference signs - potentially reordered
  referenceSigns: Array<ReferenceSign>;
}

// ##### NodeUpdates ################################################################################

/**
 * DTO for insert a new subtree into the document.
 */
export class NodeInsertUpdate {

  // the GUID of the node to add the new subtree as child to
  parentGuid: string;

  // the position of the new subtree within it's parent's children
  positionAsChild: number;

  // the view model of the newly created node
  block: AbstractBlockViewModel;

  constructor(parentGuid: string, positionAsChild: number, block: AbstractBlockViewModel) {
    this.parentGuid = parentGuid;
    this.positionAsChild = positionAsChild;
    this.block = block;
  }
}

/**
 * DTO for replacing an existing subtree by a new subtree.
 */
export class NodeReplaceUpdate {

  // the new version of the subtree
  block: AbstractBlockViewModel;

  constructor(block: AbstractBlockViewModel) {
    this.block = block;
  }
}

/**
 * DTO for deleting an existing subtree.
 */
export class NodeDeleteUpdate {

  // guid of the parent of where the subtree which is to be deleted is a child of
  parentGuid: string;

  // the GUID of the subtree to delete
  guid: string;

  constructor(parentGuid: string, guid: string) {
    this.parentGuid = parentGuid;
    this.guid = guid;
  }
}

/**
 * DTO for updating the attributes of a node.
 */
export class NodeAttributeUpdate {

  // the new version of the subtree
  block: AbstractBlockViewModel;

  constructor(block: AbstractBlockViewModel) {
    this.block = block;
  }
}

/**
 * DTO for document update
 */
export class DocumentUpdate {

  constructor(kind: string) {
    this._kind = kind;
    this._nodeInsertUpdate = [];
    this._nodeReplaceUpdate = [];
    this._nodeDeleteUpdate = [];
    this._nodeAttributeUpdate = [];
  }

  private _kind: string;

  private _nodeInsertUpdate: NodeInsertUpdate[] = [];   // Contains all nodes that must be inserted
  private _nodeReplaceUpdate: NodeReplaceUpdate[] = []; // Contains all nodes that must be replaced
  private _nodeDeleteUpdate: NodeDeleteUpdate[] = [];   // Contains all nodes that must be deleted
  private _nodeAttributeUpdate: NodeAttributeUpdate[] = [];   // Contains all nodes that must be updated

  public addInsert(insert: NodeInsertUpdate[]) {
    this._nodeInsertUpdate.push(...insert);
  }

  public addReplace(insert: NodeReplaceUpdate[]) {
    this._nodeReplaceUpdate.push(...insert);
  }

  public addDelete(insert: NodeDeleteUpdate[]) {
    this._nodeDeleteUpdate.push(...insert);
  }

  public addAttribute(insert: NodeAttributeUpdate[]) {
    this._nodeAttributeUpdate.push(...insert);
  }

  get nodeInsertUpdate(): NodeInsertUpdate[] {
    return this._nodeInsertUpdate;
  }

  get nodeReplaceUpdate(): NodeReplaceUpdate[] {
    return this._nodeReplaceUpdate;
  }

  get nodeDeleteUpdate(): NodeDeleteUpdate[] {
    return this._nodeDeleteUpdate;
  }

  get nodeAttributeUpdate(): NodeAttributeUpdate[] {
    return this._nodeAttributeUpdate;
  }

  get kind() {
    return this._kind;
  }

  public toString(): string {
    return JSON.stringify(this, function (key: any, value: any) {

      if (key === 'parent') {
        return value.guid;
      }
      return value;
    });
  }
}

export enum DocumentEditorSplitMode {
  ONE = 'ONE',
  TWO = 'TWO'
}

export enum TwoDocumentEditorsSplitMode {
  SPLIT_HORIZONTALLY = 'SPLIT_HORIZONTALLY',
  SPLIT_VERTICALLY = 'SPLIT_VERTICALLY'
}

export type SingleDocumentEditorSplitState = {
  mode: DocumentEditorSplitMode.ONE;
}

type SingleDocumentEditorSplitStateKeys = keyof SingleDocumentEditorSplitState;

export type ModeAndSplit = {
  mode: DocumentEditorSplitMode;
  split?: TwoDocumentEditorsSplitMode | undefined;
}

export type ModeAndSplitKeys = keyof ModeAndSplit;

export type TwoDocumentEditorSplitState = {
  mode: DocumentEditorSplitMode.TWO;
  split: TwoDocumentEditorsSplitMode;
  ratio: number;
}

type TwoDocumentEditorSplitStateKeys = keyof TwoDocumentEditorSplitState;

export type DocumentEditorSplitState = SingleDocumentEditorSplitState
                                     | TwoDocumentEditorSplitState;

export const initialModeAndSplit = {
  mode: DocumentEditorSplitMode.ONE,
  split: undefined
}

export const initialSingleEditorSplitState: SingleDocumentEditorSplitState = {
  mode: DocumentEditorSplitMode.ONE
}

export const initialDoubleEditorSplitStateHorizontal: TwoDocumentEditorSplitState = {
  mode: DocumentEditorSplitMode.TWO,
  split: TwoDocumentEditorsSplitMode.SPLIT_HORIZONTALLY,
  ratio: 0.50
}
export const initialDoubleEditorSplitStateVertical: TwoDocumentEditorSplitState = {
  mode: DocumentEditorSplitMode.TWO,
  split: TwoDocumentEditorsSplitMode.SPLIT_VERTICALLY,
  ratio: 0.50
}

type SplitStateKeys = SingleDocumentEditorSplitStateKeys
                    | TwoDocumentEditorSplitStateKeys;

export function isSplitStateEqual(lhs: DocumentEditorSplitState, rhs: DocumentEditorSplitState){
  const comparisonKeys: SplitStateKeys[] = ['mode', 'split', 'ratio'];
  return isEqual(
    pick(lhs, comparisonKeys),
    pick(rhs, comparisonKeys)
  );
}

export function isModeAndSplitEqual(lhs: ModeAndSplit, rhs: ModeAndSplit) {
  const comparisonKeys: SplitStateKeys[] = ['mode', 'split'];
  return isEqual(
    pick(lhs, comparisonKeys),
    pick(rhs, comparisonKeys)
  );
}