import {Extension} from '@tiptap/vue-3';
import {EditorState} from '@tiptap/pm/state';
import {EditorView} from '@tiptap/pm/view';

/**
 * Tries an undo action. Needs to fit the command interface. https://prosemirror.net/docs/ref/#commands
 * @param state the EditorState for the action.
 * @param dispatch The dispatch function for the command.
 * @param view The view on which the command was executed.
 * @return true if the command could execute, else false.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const paste = ({state, view}: { state: EditorState, view: EditorView }): boolean => {

  /* // TODO: This can not work in Firefox. We must decide if to
         A. remove it
         B. only use it in Browsers it works in
         C. instead display a dialog telling to use shortcuts instead (see Google Docs)
  // if we are are currently doing something else, don't undo anything
  if (EditorModule.isLoading) {
    return false;
  }

  if (dispatch) {

    const anchor = state.selection.$anchor
    const from = state.selection.from;
    const to = state.selection.to;
    const node = anchor.node(anchor.depth);
    if (node.isTextblock) {

      // window.clipboardData.getData('Text')


      navigator.clipboard.readText()
        .then(text => {
          console.log(text);
          const tr = state.tr.insertText(text, from, to);
          if (dispatch) {
            tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'PasteExtension - paste');
            dispatch(tr);
          }
        })
        .catch(err => {
          // Do nothing if this fails. It is meant to fail if handlePaste already handled this one.
          console.error('Failed to read clipboard contents: ', err);
        });
    }
  }*/
  return false;
};

export const PasteExtension = Extension.create(
  {
    name: 'paste',

    addKeyboardShortcuts() {
      return {
        'Mod-v': () => paste(this.editor)
      };
    }
  });
