<template>

  <div ref="applicationEditorContent"
       :id="editorContentIdFromEditorName()"
       class="content"
       :class="showAsActive ? 'active-editor-view' : ''"
       @mousedown="onClickEditorContentContainerDown($event)"
       @scroll.passive="onScroll($event)">
    <div v-show="isDocumentLoading" class="spinner-div">
      <i class="exi-lg exi-large-spinner-unmasked rotating"/>
    </div>
    <ScrollZoom>
      <div ref="aiButtonRef" class="ai-button" :style="aiButtonPosition" @mousedown="aiButtonClicked($event)"
           v-show="showAiButton">
        <div class="container">
          <b-button
            class="ai-button-inner"
            :title="$t('aiAssistantDialog.hoverText')"
          >
            <i class="exs-big ai-feature-button ai-button-icon" :class="{'is-active': aiDialogActive}"/>
          </b-button>
        </div>
      </div>

      <BorderVisualizationCanvas v-if="editor"
                                 ref="canvas"
                                 :editor="editor"
                                 :editor-content-id="editorContentIdFromEditorName()"
                                 :editor-id="editorIdFromEditorName()"
                                 :zoomLevel="zoomLevel">
      </BorderVisualizationCanvas>

      <editor-content
        :id="editorIdFromEditorName()"
        class="editor"
        :class="isGenerateUnRedoLoading ? ' editor-read-only' : ''"
        v-bind:editor="editor"
      />
    </ScrollZoom>
  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import SpellcheckOverlay from '@/components/applicationEditor/menubar/SpellcheckOverlay.vue';
import MenuBubble from '@/components/applicationEditor/menubar/MenuBubble.vue';
import Menubar from '@/components/applicationEditor/menubar/Menubar.vue';
import BorderVisualizationCanvas, {
  BorderVisualizationCanvas as BorderVisualizationCanvasClass
} from '@/components/BorderVisualizationCanvas.vue';
import {EditorContent} from '@tiptap/vue-3';
import SpellcheckDictionaryManagement from '@/components/SpellcheckDictionaryManagement.vue';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';
import EditorModule from '@/store/modules/EditorModule';
import {findNextTextblockPositionByCords} from '@/store/util/editor.util';
import {ResizeSensor} from 'css-element-queries';
import {NamedEditor} from '@/components/ApplicationEditor.vue';
import ScrollZoom from '@/components/ScrollZoom.vue';
import AiAssistantDialog from '@/components/applicationEditor/menubar/AiAssistantDialog.vue';
import AuthModule from '@/store/modules/AuthModule';
import {TopAndZoomLevelOfCurrentBlock} from '@/api/models/utility.model';

export type EditorScrollEvent = {
  applicationEditor: ApplicationEditorView;
  scrollTop: number;
  scrollLeft: number;
};

@Component(
  {
    components: {
      AiAssistantDialog,
      SpellcheckOverlay,
      MenuBubble,
      Menubar,
      ScrollZoom,
      BorderVisualizationCanvas,
      EditorContent,
      SpellcheckDictionaryManagement,
      ConfirmationDialog
    },
  })
class ApplicationEditorView extends Vue {

  @Prop({required: true}) private editor!: NamedEditor;
  @Prop({required: true}) private isActive!: boolean;
  @Prop({required: true}) private showAsActive!: boolean;
  @Prop({required: true}) private hideMenuBubble!: boolean;
  @Prop({required: true}) private locale!: string;
  @Prop({required: true}) private appDocGuid!: string;
  @Prop({required: true}) private posOfCurrentBlock!: TopAndZoomLevelOfCurrentBlock
  @Prop({required: true}) private aiDialogActive!: boolean;

  @Ref('applicationEditorContent') private applicationEditorContent!: HTMLElement;
  @Ref('canvas') private canvas!: BorderVisualizationCanvasClass;
  @Ref('aiButtonRef') private aiButton!: HTMLDivElement;

  private zoomWrapper!: HTMLElement;
  private debug = false;
  private resizeSensor: ResizeSensor | undefined;

  private aiButtonClicked(event: MouseEvent) {
    // Fix: Use on mousedown event in order to execute before blur.
    if (event.button === 2) {
      event.preventDefault();
      return;
    }
    this.$emit('aiButtonClicked', this.aiButton);
  }

  /**
   *
   * top coordinate: based on the position of the current block. This position is corrected by
   * the zoomWrapperTop coordinate and the zoomLevel, and corrected by 10 px at the end.
   * right coordinate: 20 px away from right edge
   * */
  get aiButtonPosition(): any {
    const zoomWrapperTop = this.zoomWrapper ? this.zoomWrapper.getBoundingClientRect().top : 0;
    return {top: (this.posOfCurrentBlock.top - zoomWrapperTop) / this.posOfCurrentBlock.zoomLevel - 10 + 'px', right: '20px'}
  }

  private onScroll(event: Event): void {
    this.emitEditorScrolled();
  }

  private onResize(): void {
    this.canvas.draw();
    this.$emit('editorResized')
  }

  get selectionGuidForEditor(): string | null {
    return EditorModule.selectionGuidForEditor;
  }

  get showAiButton(): boolean {
    return this.isActive && (this.aiDialogActive || !!this.selectionGuidForEditor);
  }

  get getTenant(): string | null | undefined {
    return AuthModule.user?.tenant;
  }

  mounted() {
    // TODO: besser ueber eine Ref implementieren
    this.zoomWrapper = this.applicationEditorContent.querySelector("#zoomWrapper") as HTMLElement;
    this.emitReady();
  }

  @Emit('ready')
  emitReady() {
    return this;
  }

  @Emit('editorScrolled')
  emitEditorScrolled() {
    return this.getEditorScrollPosition();
  }

  unmounted(): void {
    this.resizeSensor?.detach();
  }

  private getEditorScrollPosition(): EditorScrollEvent | null {
    if (this.applicationEditorContent) {
      return {
        applicationEditor: this,
        scrollTop: this.applicationEditorContent.scrollTop,
        scrollLeft: this.applicationEditorContent.scrollLeft
      };
    }
    return null;
  }

  public editorContentIdFromEditorName() {
    return "applicationEditorContent-" + this.editor?.editorName;
  }

  public editorIdFromEditorName() {
    return "editor-" + this.editor?.editorName;
  }

  public scrollEditorContentTo(opts: ScrollToOptions) {
    this.applicationEditorContent.scrollTo(opts);
  }

  get isDocumentLoading(): boolean {
    return EditorModule.isCurrentApplicationDocumentLoading;
  }

  get isGenerateUnRedoLoading(): boolean {
    return EditorModule.isGenerateUnRedoLoading;
  }

  get zoomLevel(): number {
    return EditorModule.zoomLevel;
  }

  private onClickEditorContentContainerDown(event: any): void {
    if (!this.editor || !this.detectBrowser.isFirefox || this.editor.view.hasFocus()) {
      return;
    }

    const posAtCords = this.editor.view.posAtCoords({left: event.clientX, top: event.clientY});
    if (!posAtCords) {
      return;
    }

    const resolvedPos = this.editor.state.doc.resolve(posAtCords.pos);
    const resolvedNode = resolvedPos.node();
    const resolvedType = resolvedNode.type.name;
    const resolvedGuid = resolvedNode.attrs.guid;
    let targetPos = posAtCords.pos;
    this.log(`-----------------------------------------------------------------`);
    this.log(`Mouse Down on ${event.clientX}/${event.clientY} Pos: ${targetPos} Inside: ${posAtCords.inside}`);
    this.log(` - resolvedNode : `, resolvedNode);
    this.log(` - type / guid  :  ${resolvedType} / ${resolvedGuid}`);

    // compare the found situation with the settled selection
    setTimeout(() => {
      if (!this.editor) {
        return;
      }
      const selectionPos = this.editor.state.doc.resolve(this.editor.state.selection.from);
      const selectionNode = selectionPos.node();
      const selectionType = selectionNode.type.name;
      const selectionGuid = selectionNode.attrs.guid;
      this.log(`Firefox selection after timeout:                           | selectionNode: `, selectionNode);
      this.log(` - type / guid  :  ${selectionType} / ${selectionGuid}`);

      if (selectionGuid === resolvedGuid) {
        // check ok! Nothing to do!
        this.log(` - check ok! Nothing to do!`);
        return;
      }
      // check FAILED! Fix required!
      this.log(` - check FAILED! Fix required!`);

      targetPos = findNextTextblockPositionByCords(this.editor, event.clientX, event.clientY);
      this.log(`Firefox: - Moving form ${posAtCords.pos} to ${targetPos} `);
      if (targetPos > 0) {
        this.editor.commands.focus(targetPos);
      }
    }, 20);
  }

  private log(message?: any, ...optionalParams: any[]) {
    if (this.debug) {
      console.log(message, ...optionalParams);
    }
  }
}

export default toNative(ApplicationEditorView);
export {ApplicationEditorView};
</script>

<style scoped lang="scss">
@import 'src/assets/styles/colors.scss';
@import 'src/assets/styles/constants.scss';


.content {
  position: relative;
  width: 100%;
  height: 100%;

  overflow-y: scroll;
  margin-bottom: 0px;
  border: 2px solid transparent;

  &.active-editor-view {
    border: 2px solid $pengine-blue-dark;
  }

  background-color: $pengine-grey;

  .spinner-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .editor {
    padding: 80px;
    background-color: white;
    width: 900px;
    color: black;
  }

  .ai-button {
    border: none;
    background-color: #FFFFFF;
    position: absolute;
    align-content: center;
    width: 40px;
    height: 40px;
    z-index: 10;
    scale: 0.85;
    transition: all 0.3s ease 0s;
  }

  .ai-button-inner {
    width: 40px;
    height: 40px;
    background-color: #FFFFFF;
    border-color: #FFFFFF;

    &:hover {
      background-color: #FFFFFF;
    }
  }

  .ai-button-icon {
    scale: 0.85;

    .i {
      &.is-active {
        background-color: $pengine-blue-dark;
      }

      &:hover {
        background-color: $pengine-blue-dark;
      }
    }
  }

  .container {
    position: relative;
    text-align: center;
  }

  .centered {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .button:not(:disabled) {
    i {
      &.is-active {
        background-color: $pengine-blue-dark;
      }

      &:hover {
        background-color: $pengine-blue-dark;
      }
    }

    span {
      &.is-active {
        color: $pengine-blue-dark;
      }

      &:hover {
        color: $pengine-blue-dark;
      }
    }
  }
}
</style>