<template>
  <div id="figure" class="figure">
    <splitpanes :dbl-click-splitter="false"
                :push-other-panes="false"
                @splitter-click="onSplitterClick()"
                @resize="onResize($event)"
                class="default-theme figure-main-splitpanes">
      <pane ref="leftPane"
            :size="leftPaneSizeComputed"
            class="figure-editor-pane">
        <FigureEditor
          :application-guid="applicationGuid"
          :figure-guid="figureGUID"
          :figures="figures"
          :on-figure-guid-changed="this.onFigureGuidChange"
        />
      </pane>
      <pane :size="rightPaneSizeComputed" min-size="0">
        <splitpanes horizontal
                    class="default-theme figure-main-splitpanes">
          <pane v-if="isReferenceSignListVisible " min-size="0" class="reference-sign-list-pane">
            <ReferenceSignList
              :application-guid="applicationGuid"
              :application-editor-instance="null"
              :edit-reference-signs-mode="EditReferenceSignMode.EDIT_ENABLED"
              :drag-mode="DragMode.DRAG_DROP_ENABLED"
              :show-apply-reference-sign-button="false"
              :show-hightlight-reference-sign-button="false"
            />
          </pane>
          <pane min-size="0" class="reference-sign-list-pane">
            <DraggableSymbolList
              :symbolHeader="$t('symbols')"
              :symbolsList="symbolsList"
              :disabled="this.figureGUID === null"
            />
          </pane>
          <pane min-size="0" class="reference-sign-list-pane">
            <DraggableSymbolList
              :symbolHeader="$t('helpLines')"
              :symbolsList="helpLinesList"
              :disabled="this.figureGUID === null"
            />
          </pane>
        </splitpanes>
      </pane>
    </splitpanes>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import FigureEditor from '@/components/FigureEditor.vue';
import DraggableSymbolList from '@/components/DraggableSymbolList.vue';
import Splitpanes from '@/components/splitpane/Splitpanes.vue';
import Pane, {Pane as PaneClass} from '@/components/splitpane/Pane.vue';
import ReferenceSignList, {DragMode, EditReferenceSignMode} from '@/components/ReferenceSignList.vue';
import ApplicationFigureModule from '@/store/modules/ApplicationFigureModule';
import {ApplicationFigureThumbnail} from '@/api/models/applicationFigure.model';
import FigureModule from '@/store/modules/FigureModule';
import {DRAGGABLE_HELP_LINE_LIST, DRAGGABLE_SYMBOL_LIST, DraggableSymbolEntry} from '@/store/models/figure.model';
import {useDefaultErrorHandling} from '@/errorHandling';

@Component(
  {
    name: 'figureComponent',
    components: {
      Pane,
      Splitpanes,
      ReferenceSignList,
      FigureEditor,
      DraggableSymbolList
    }
  })
class Figure extends Vue {

  @Prop({required: true})
  private figureGuid!: string;

  @Prop({default: true}) private showReferenceSignList!: boolean;

  @Prop({required: true})
  private applicationGuid!: string;

  @Ref('leftPane') private readonly leftPane!: PaneClass;

  private defaultLeftPaneSize = 80;
  private defaultRightPaneSize = 20;

  private leftPaneSize = this.defaultLeftPaneSize;
  private rightPaneSize = this.defaultRightPaneSize;
  private rightPaneMinized = false;

  private isReferenceSignListVisible = true;

  private internalFigureGuid: string | null = null;

  private onResize(event: { size: number }[]): void {
    this.rightPaneMinized = false;
    this.setSplitPaneSize([event[0].size, event[1].size]);
  }

  private onSplitterClick(): void {
    this.rightPaneMinized = !this.rightPaneMinized;
  }

  private get leftPaneSizeComputed() {
    return this.rightPaneMinized ? 100 : this.leftPaneSize;
  }

  private get rightPaneSizeComputed() {
    return this.rightPaneMinized ? 0 : this.rightPaneSize;
  }

  get DragMode() {
    return DragMode;
  }

  get EditReferenceSignMode() {
    return EditReferenceSignMode;
  }

  get figures(): ApplicationFigureThumbnail[] {
    return ApplicationFigureModule.applicationFigures;
  }

  get symbolsList(): DraggableSymbolEntry[] {
    return DRAGGABLE_SYMBOL_LIST;
  }

  get helpLinesList(): DraggableSymbolEntry[] {
    return DRAGGABLE_HELP_LINE_LIST;
  }

  private onFigureGuidChange(newGuid: string | null): void {
    this.internalFigureGuid = newGuid;
  }

  @Watch('figureGUID', {immediate: true})
  figurePropChanged(newValue: string | null, oldValue: string | null): void {
    if (newValue && newValue !== oldValue) {
      // FigureModule.fetchApplicationFigure(newValue);
      this.onFigureGuidChange(newValue);
    }
  }

  get figureGUID(): string | null {
    return this.internalFigureGuid;
  }

  private setSplitPaneSize(sizes: number[]): void {
    this.leftPaneSize = sizes[0];
    this.rightPaneSize = sizes[1];
  }

  /**
   * Called after the instance has been mounted.
   */
  mounted(): void {
    this.setSplitPaneSize([this.leftPaneSize, this.rightPaneSize]);

    this.internalFigureGuid = this.figureGuid;

    if (this.internalFigureGuid) {
      Promise.all([
        ApplicationFigureModule.fetchApplicationFiguresForApplicationDocument(this.applicationGuid),
        FigureModule.fetchApplicationFigure(this.internalFigureGuid)
      ])
      .catch(useDefaultErrorHandling)
    }
  }
}

export default toNative(Figure);
</script>

<style lang="scss">
.figure {
  .figure-main-splitpanes {
    > div:nth-child(1) {
      border-left: none !important;
      border-right-width: 1px;
      border-right-style: solid;
    }
  }
}
</style>

<style lang="scss" scoped>
.figure {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .splitpanes__pane {
    flex-direction: column;
  }

  .figure-editor-pane {
    justify-content: normal;
  }

  .reference-sign-list-pane {
    overflow-y: auto;
    justify-content: normal;
  }
}

.scrollable {
  overflow: auto;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>
