// refactored to typescript from here https://github.com/antoniandre/splitpanes
// adapted to have better splitter control
<script lang="ts">
import {h, nextTick} from 'vue';
import {Component, Emit, Prop, Provide, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import Pane from '@/components/splitpane/Pane.vue';

type UpdateFunction = (style: Record<string, any>) => void;

interface PaneProps {
  id: string;
  index: number;
  size: number | null;
  min: number;
  max: number;
  givenSize: number | null;
  update: UpdateFunction;
}

interface Touch {
  mouseDown: boolean;
  dragging: boolean;
  activeSplitter: number | null;
}

interface SplitterTaps {
  splitter: number | null;
  timeoutId: number | null;
}

type Sums = {
  prevPanesSize: number;
  nextPanesSize: number;
  prevReachedMinPanes: number;
  nextReachedMinPanes: number;
};

@Component({
             components: {
               Pane
             },
           })
class Splitpanes extends Vue {
  @Prop({default: false, type: Boolean}) readonly horizontal!: boolean;
  @Prop({default: true, type: Boolean}) readonly pushOtherPanes!: boolean;
  @Prop({default: true, type: Boolean}) readonly dblClickSplitter!: boolean;
  @Prop({default: false, type: Boolean}) readonly rtl!: boolean; // Right to left direction.
  @Prop({default: false, type: Boolean}) readonly firstSplitter!: boolean;
  @Prop({default: true, type: Boolean}) readonly autoEqualize!: boolean;
  @Prop({default: false, type: Boolean}) readonly debugOutput!: boolean;
  @Ref("container") readonly container!: HTMLDivElement;

  lastValidSizes: number[] | null = null;

  ready = false;
  panes: any[] = [];
  touch: Touch = {
    mouseDown: false,
    dragging: false,
    activeSplitter: null,
  };
  splitterTaps: SplitterTaps = {
    splitter: null,
    timeoutId: null,
  };

  get panesCount() {
    return this.panes.length;
  }

  get indexedPanes() {
    return this.panes.reduce((obj: { [key: string]: any }, pane) => {
      obj[pane.id] = pane;
      return obj;
    }, {});
  }

  updatePaneComponents(): void {
    if (this.debugOutput) {
      console.log('Updating panes', this.panes.map(pane => pane.size.toString()),
                  this.panes.map(pane => pane.size).reduce((previousValue, currentValue) => previousValue + currentValue, 0).toString())
    }
    this.panes.forEach(pane => {
      if (pane.update) {
        pane.update({
                      [this.horizontal ? 'height' : 'width']: `${this.indexedPanes[pane.id].size}%`
                    });
      }
    });
  }

  bindEvents(): void {
    document.addEventListener('mousemove', this.onMouseMove, {passive: false});
    document.addEventListener('mouseup', this.onMouseUp);
  }

  unbindEvents(): void {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  onMouseDown(event: MouseEvent, splitterIndex: number): void {
    this.bindEvents();
    this.touch.mouseDown = true;
    this.touch.activeSplitter = splitterIndex;
    this.lastValidSizes = this.calcLastValidSizes();
  }

  onMouseMove(event: MouseEvent): void {
    if (this.touch.mouseDown) {
      // Prevent scrolling while touch dragging (only works with an active event, e.g., passive: false).
      event.preventDefault();
      this.touch.dragging = true;
      if (this.debugOutput) {
        console.log('Pane sizes internal', this.panes.map(pane => pane.size), this.touch.activeSplitter);
      }
      this.lastValidSizes = this.calcLastValidSizes();
      this.calculatePanesSize(this.getCurrentMouseDrag(event));
      this.checkAndCorrectForInvalidSizes();
      if (this.debugOutput) {
        console.log('Pane sizes internal2', this.panes.map(pane => pane.size));
      }
      this.emitResize();
    }
  }

  onMouseUp(): void {
    if (this.touch.dragging) {
      this.emitResized();
    }
    this.touch.mouseDown = false;
    this.lastValidSizes = null;
    // Keep dragging flag until click event is finished (click happens immediately after mouseup)
    // in order to prevent emitting `splitter-click` event if splitter was dragged.
    setTimeout(() => {
      this.touch.dragging = false;
      this.unbindEvents();
    }, 100);
  }

  onSplitterClick(event: MouseEvent | TouchEvent, splitterIndex: number): void {
    if ('ontouchstart' in window) {
      event.preventDefault();

      // Detect splitter double taps if the option is on.
      if (this.dblClickSplitter) {
        if (this.splitterTaps.splitter === splitterIndex) {
          clearTimeout(this.splitterTaps.timeoutId!);
          this.splitterTaps.timeoutId = null;
          this.onSplitterDblClick(event, splitterIndex);
          this.splitterTaps.splitter = null; // Reset for the next tap check.
        } else {
          this.splitterTaps.splitter = splitterIndex;
          this.splitterTaps.timeoutId = setTimeout(() => {
            this.splitterTaps.splitter = null;
          }, 500) as unknown as number;
        }
      }
    }

    if (!this.touch.dragging) {
      this.emitSplitterClick(splitterIndex);
    }
  }

  onSplitterDblClick(event: MouseEvent | TouchEvent, splitterIndex: number): void {

    let totalMinSizes = 0;
    this.panes = this.panes.map((pane, i) => {
      pane.size = i === splitterIndex ? pane.max : pane.min;
      if (i !== splitterIndex) {
        totalMinSizes += pane.min;
      }

      return pane;
    });
    this.panes[splitterIndex].size -= totalMinSizes;

    this.emitPaneMaximize(splitterIndex);
  }

  @Provide('onPaneClick')
  onPaneClick(event: MouseEvent, paneId: number): void {
    this.emitPaneClick(paneId);
  }

  getCurrentMouseDrag(event: MouseEvent): { x: number; y: number } {
    const rect = this.container?.getBoundingClientRect() || {left: 0, top: 0};
    const {clientX, clientY} = event;

    return {
      x: clientX - rect.left,
      y: clientY - rect.top
    };
  }

  calcLastValidSizes(): number[] {
    return this.panes.map(pane => pane.size);
  }

  sumPaneSizes(): number {
    return this.panes.map(pane => pane.size).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  sumPrevPanesSize(splitterIndex: number): number {
    return this.panes.reduce((total: number, pane: { size: number }, i: number) => total + (i < splitterIndex ? pane.size : 0), 0);
  }

  sumNextPanesSize(splitterIndex: number): number {
    return this.panes.reduce((total: number, pane: { size: number }, i: number) => total + (i > (splitterIndex + 1) ? pane.size : 0), 0);
  }

  findPrevExpandedPane(splitterIndex: number): { index?: number; size?: number; min?: number } | undefined {
    return [...this.panes].reverse().find((p: { index: number; size: number; min: number }) => p.index < splitterIndex && p.size > p.min);
  }

  findNextExpandedPane(splitterIndex: number): { index?: number; size?: number; min?: number } | undefined {
    return this.panes.find((p: { index: number; size: number; min: number }) => p.index > splitterIndex && p.size > p.min);
  }


  getCurrentDragPercentage(drag: { x: number; y: number }): number {
    let dragValue = drag[this.horizontal ? 'y' : 'x'];
    // In the code below 'size' refers to 'width' for vertical and 'height' for horizontal layout.
    const containerSize = this.horizontal ? this.container?.clientHeight ?? 0 : this.container?.clientWidth ?? 0;
    if (this.rtl && !this.horizontal) {
      dragValue = containerSize - dragValue;
    }

    return (dragValue * 100) / containerSize;
  }

  checkAndCorrectForInvalidSizes() {
    const sum = this.sumPaneSizes();
    if(this.debugOutput){
      console.log('Checking sizes:', sum);
    }
    const overflow = sum > 100;
    if (overflow) {
      this.lastValidSizes!.forEach((value, index) => this.panes[index].size = value);
    }
  }

  calculatePanesSize(drag: { x: number; y: number }): void {
    // Assuming `this.touch.activeSplitter` is a number or null.
    const splitterIndex: number | null = this.touch.activeSplitter;
    if (splitterIndex === null) {
      return;
    } // Guard clause to ensure splitterIndex is not null.

    if(this.debugOutput){
      console.log("Splitter-Index", splitterIndex);
    }

    if(this.debugOutput){
      console.log("Splitter-Index", splitterIndex);
    }

    let sums: Sums = {
      prevPanesSize: this.sumPrevPanesSize(splitterIndex),
      nextPanesSize: this.sumNextPanesSize(splitterIndex),
      prevReachedMinPanes: 0,
      nextReachedMinPanes: 0
    };

    if (this.debugOutput) {
      console.log('Sums', sums);
    }
    const minDrag: number = 0 + (this.pushOtherPanes ? 0 : sums.prevPanesSize);
    const maxDrag: number = 100 - (this.pushOtherPanes ? 0 : sums.nextPanesSize);
    const currentDragPercentage = this.getCurrentDragPercentage(drag);
    const effectiveDragPercentage: number = Math.max(Math.min(currentDragPercentage, maxDrag), minDrag);
    if (this.debugOutput) {
      console.log('Drags:', minDrag, maxDrag, currentDragPercentage, effectiveDragPercentage);
    }
    let panesToResize: number[] = [splitterIndex, splitterIndex + 1];
    let paneBefore = this.panes[panesToResize[0]] || null;
    let paneAfter = this.panes[panesToResize[1]] || null;

    const paneBeforeMaxReached: boolean = paneBefore && paneBefore.max < 100
      && (effectiveDragPercentage >= (paneBefore.max + sums.prevPanesSize));
    const paneAfterMaxReached: boolean = paneAfter && paneAfter.max < 100 && (effectiveDragPercentage <= 100
      - (paneAfter.max + this.sumNextPanesSize(splitterIndex + 1)));

    if (this.debugOutput) {
      console.log(
        `minDrag=${minDrag} maxDrag=${maxDrag} dragPercentage=${effectiveDragPercentage}, panesToResize=[${panesToResize[0]},${panesToResize[1]}] paneBeforeMaxReached=${paneAfterMaxReached} paneAfterMaxReached=${paneAfterMaxReached}`);
      console.log(
        `paneBefore=${paneBefore} paneAfter=${paneAfter} paneBeforeMaxReached=${paneBeforeMaxReached} paneAfterMaxReached=${paneAfterMaxReached}`);
    }

    if (paneBeforeMaxReached || paneAfterMaxReached) {
      if (paneBeforeMaxReached && paneBefore) {
        paneBefore.size = paneBefore.max;
        paneAfter = paneAfter ? {...paneAfter, size: Math.max(100 - paneBefore.max - sums.prevPanesSize - sums.nextPanesSize, 0)}
          : paneAfter;
      } else if (paneAfterMaxReached && paneAfter) {
        paneBefore = paneBefore ? {
          ...paneBefore,
          size: Math.max(100 - paneAfter.max - sums.prevPanesSize - this.sumNextPanesSize(splitterIndex + 1), 0)
        } : paneBefore;
        paneAfter.size = paneAfter.max;
      }
      return;
    }

    if (this.pushOtherPanes) {
      const vars = this.doPushOtherPanes(sums, effectiveDragPercentage);
      if (!vars) {
        return;
      } // Prevent other calculation.
      ({sums, panesToResize} = vars);
      paneBefore = this.panes[panesToResize[0]] || null;
      paneAfter = this.panes[panesToResize[1]] || null;
    }

    if (this.debugOutput) {
      console.log(
        `BeforeChanges: paneBefore=${paneBefore} paneAfter=${paneAfter} paneBefore.size=${paneBefore.size} paneAfter.size=${paneAfter.size}`);
    }
    if (paneBefore !== null) {
      const relativeDragPercentage = effectiveDragPercentage - sums.prevPanesSize - sums.prevReachedMinPanes;
      const clampedDragPercentageLeft = Math.max(relativeDragPercentage, paneBefore.min);
      const newSize =  Math.min(clampedDragPercentageLeft, paneBefore.max);
      if(this.debugOutput) {
        console.log('PaneBefore calculation', effectiveDragPercentage, sums.prevPanesSize, sums.prevReachedMinPanes, relativeDragPercentage, clampedDragPercentageLeft, newSize);
      }
      paneBefore.size = newSize;
    }
    if (paneAfter !== null) {
      const relativeDragPercentage = 100 - effectiveDragPercentage - sums.nextPanesSize - sums.nextReachedMinPanes;
      const clampedDragPercentageLeft = Math.max(relativeDragPercentage, paneAfter.min);
      const newSize = Math.min(clampedDragPercentageLeft, paneAfter.max);
      if(this.debugOutput) {
        console.log('PaneAfter calculation', effectiveDragPercentage, sums.nextPanesSize, sums.nextReachedMinPanes, relativeDragPercentage, clampedDragPercentageLeft, newSize);
      }
      paneAfter.size = newSize;
    }
    if (this.debugOutput) {
      console.log(`AfterChanges: paneBefore=${paneBefore} paneAfter=${paneAfter}`);
    }
  }

  doPushOtherPanes(sums: Sums, dragPercentage: number): { sums: Sums; panesToResize: number[] } | null {
    const splitterIndex: number | null = this.touch.activeSplitter;
    if (splitterIndex === null) {
      return null;
    } // Ensure splitterIndex is not null.

    const panesToResize: number[] = [splitterIndex, splitterIndex + 1];

    // Pushing Down
    if (dragPercentage < sums.prevPanesSize + this.panes[panesToResize[0]].min) {
      let prevExpandedPaneIndex = this.findPrevExpandedPane(splitterIndex)?.index;
      if (prevExpandedPaneIndex === undefined) {
        prevExpandedPaneIndex = 0; // Default to the first pane if none is expanded before the splitter
      }
      panesToResize[0] = prevExpandedPaneIndex;
      sums.prevReachedMinPanes = 0;

      if (prevExpandedPaneIndex < splitterIndex) {
        this.panes.forEach((pane, i) => {
          if (i > prevExpandedPaneIndex! && i <= splitterIndex) {
            pane.size = pane.min;
            sums.prevReachedMinPanes += pane.min;
          }
        });
      }
      sums.prevPanesSize = this.sumPrevPanesSize(prevExpandedPaneIndex);
    }

    // Pushing Up
    if (dragPercentage > 100 - sums.nextPanesSize - this.panes[panesToResize[1]].min) {
      let nextExpandedPaneIndex = this.findNextExpandedPane(splitterIndex)?.index;
      if (nextExpandedPaneIndex === undefined) {
        nextExpandedPaneIndex = this.panes.length - 1; // Default to the last pane if none is expanded after the splitter
      }
      panesToResize[1] = nextExpandedPaneIndex;
      sums.nextReachedMinPanes = 0;

      if (nextExpandedPaneIndex > splitterIndex + 1) {
        this.panes.forEach((pane, i) => {
          if (i > splitterIndex && i < nextExpandedPaneIndex!) {
            pane.size = pane.min;
            sums.nextReachedMinPanes += pane.min;
          }
        });
      }
      sums.nextPanesSize = this.sumNextPanesSize(nextExpandedPaneIndex - 1);
    }

    // The section you mentioned missing is actually not needed because of how we now define `panesToResize` indices.
    // We now explicitly set to the first or last pane index when no previous or next expanded pane is found,
    // which eliminates the condition where `panesToResize[1]` could be undefined.

    return {sums, panesToResize};
  }

  checkSplitpanesNodes(): void {
    if (!this.container) {
      return;
    }
    const children: Element[] = Array.from(this.container.children);
    children.forEach((child) => {
      const isPane: boolean = child.classList.contains('splitpanes__pane');
      const isSplitter: boolean = child.classList.contains('splitpanes__splitter');

      // Node is not a Pane or a splitter: remove it.
      if (!isPane && !isSplitter) {
        child.parentNode?.removeChild(child); // Improved null check for parentNode
        console.warn('Splitpanes: Only <pane> elements are allowed at the root of <splitpanes>. One of your DOM nodes was removed.');
      }
    });
  }

  addSplitter(paneIndex: number, nextPaneNode: Element, isVeryFirst = false): void {
    const splitterIndex = paneIndex - 1;
    const elm = document.createElement('div');
    elm.classList.add('splitpanes__splitter');

    if (!isVeryFirst) {
      elm.onmousedown = (event: MouseEvent) => this.onMouseDown(event, splitterIndex);
      elm.onclick = (event: MouseEvent) => this.onSplitterClick(event, splitterIndex + 1);
    }

    if (this.dblClickSplitter) {
      elm.ondblclick = (event: MouseEvent) => this.onSplitterDblClick(event, splitterIndex + 1);
    }

    nextPaneNode.parentNode?.insertBefore(elm, nextPaneNode);
  }

  removeSplitter(node: HTMLElement): void {
    node.onmousedown = null;
    node.onclick = null;
    node.ondblclick = null;
    if (node.parentNode) {
      node.parentNode.removeChild(node); // Improved null check for parentNode
    }
  }

  redoSplitters(): void {
    if (!this.container) {
      return;
    } // Guard clause if container is not defined
    const children: HTMLElement[] = Array.from(this.container.children).map(element => element as HTMLElement);
    children.forEach(el => {
      if (el.className.includes('splitpanes__splitter')) {
        this.removeSplitter(el);
      }
    });
    let paneIndex = 0;
    children.forEach(el => {
      if (el.className.includes('splitpanes__pane')) {
        if (!paneIndex && this.firstSplitter) {
          this.addSplitter(paneIndex, el, true);
        } else if (paneIndex) {
          this.addSplitter(paneIndex, el);
        }
        paneIndex++;
      }
    });
  }

  @Provide('requestUpdate')
  requestUpdate({target, ...args}: { target: { _: any }; [key: string]: any }): void {
    const pane = this.indexedPanes[target._.uid];
    if (!pane) {
      return;
    } // Guard clause to ensure the pane exists
    Object.entries(args).forEach(([key, value]) => {
      pane[key] = value;
    });
  }

  @Provide('onPaneAdd')
  onPaneAdd(pane: {
    $el: Element;
    _: any;
    minSize: string | number;
    maxSize: string | number;
    size: number | null;
    update: UpdateFunction
  }):
    void {
    if (this.debugOutput) {
      console.log('Adding pane')
    }
    // 1. Add pane to array at the same index it was inserted in the <splitpanes> tag.
    let index = -1;
    Array.from(pane.$el.parentNode?.children || []).some((el: Element) => {
      if (el.className.includes('splitpanes__pane')) {
        index++;
      }
      return el === pane.$el;
    });

    const min = parseFloat(pane.minSize.toString());
    const max = parseFloat(pane.maxSize.toString());
    const paneToAdd: PaneProps = {
      id: pane._.uid,
      index,
      min: isNaN(min) ? 0 : min,
      max: isNaN(max) ? 100 : max,
      size: pane.size === null ? null : parseFloat(pane.size.toString()),
      givenSize: pane.size,
      update: pane.update
    };

    this.panes.splice(index, 0, paneToAdd);

    // Redo indexes after insertion for other shifted panes.
    this.panes.forEach((p, i) => {
      p.index = i;
    });

    if (this.debugOutput) {
      console.log('Panes after pane has been added', this.panes.map(pane => pane.size));
    }

    if (this.ready) {
      nextTick(() => {
        this.redoSplitters(); // 2. Add the splitter.
        this.resetPaneSizes({addedPane: paneToAdd}); // 3. Resize the panes.
        this.emitPaneAdd(index);
      });
    }
  }

  @Provide('onPaneRemove')
  onPaneRemove(pane: { _: any }): void {
    if (this.debugOutput) {
      console.log('Removing pane');
    }
    const index = this.panes.findIndex(p => p.id === pane._.uid);
    const removed = this.panes.splice(index, 1)[0];
    this.panes.forEach((p, i) => p.index = i);

    nextTick(() => {
      this.redoSplitters();
      this.resetPaneSizes({removedPane: {...removed, index}});
      this.emitPaneRemove(removed);
    });
  }

  resetPaneSizes(changedPanes: { addedPane?: PaneProps; removedPane?: PaneProps } = {}): void {
    if (!changedPanes.addedPane && !changedPanes.removedPane) {
      if (this.debugOutput) {
        console.log('resetPaneSizes - initialPaneSizing')
      }
      this.initialPanesSizing();
    } else if (this.panes.some(pane => pane.givenSize !== null || pane.min || pane.max < 100)) {
      if (this.debugOutput) {
        console.log('resetPaneSizes - equalizeAfterAddOrRemove')
      }
      if (this.autoEqualize) {
        this.equalizeAfterAddOrRemove(changedPanes);
      }
    } else {
      if (this.debugOutput) {
        console.log('resetPaneSizes - equalize')
      }
      if (this.autoEqualize) {
        this.equalize();
      }
    }

    if (this.ready) {
      this.emitResized();
    }
  }

  equalize(): void {
    const equalSpace: number = 100 / this.panesCount;
    let leftToAllocate = 0;
    const ungrowable: string[] = [];
    const unshrinkable: string[] = [];

    this.panes.forEach(pane => {
      pane.size = Math.max(Math.min(equalSpace, pane.max), pane.min);
      leftToAllocate -= pane.size;
      if (pane.size >= pane.max) {
        ungrowable.push(pane.id);
      }
      if (pane.size <= pane.min) {
        unshrinkable.push(pane.id);
      }
    });

    if (leftToAllocate > 0.1) {
      this.readjustSizes(leftToAllocate, ungrowable, unshrinkable);
    }
  }

  initialPanesSizing(): void {
    let leftToAllocate = 100;
    const ungrowable: string[] = [];
    const unshrinkable: string[] = [];
    let definedSizes = 0;

    if (this.debugOutput){
      this.panes.forEach(pane => console.log(pane));
    }

    this.panes.forEach(pane => {
      if(pane.size === null){
        return;
      }
      if (pane.size !== null) {
        leftToAllocate -= pane.size;
        definedSizes++;
      }
      if (pane.size >= pane.max) {
        ungrowable.push(pane.id);
      }
      if (pane.size <= pane.min) {
        unshrinkable.push(pane.id);
      }
    });

    if (leftToAllocate > 0.1) {
      this.panes.forEach(pane => {
        if (pane.size === null) {
          const leftoverSizePerPane = leftToAllocate / (this.panesCount - definedSizes)
          const clampToMax = Math.min(leftoverSizePerPane, pane.max);
          const size = Math.max(clampToMax, pane.min);
          if (this.debugOutput) {
            console.log(`Setting initial pane size. leftToAllocate=${leftToAllocate}, panesCount=${this.panesCount}, definedSizes=${definedSizes}, leftoverSizePerPane=${leftoverSizePerPane}, pane.max=${pane.max}, clampToMax=${clampToMax}, pane.min=${pane.min}, size=${size}`);
          }
          pane.size = size;
        }
      });

      if (this.debugOutput){
        this.panes.forEach(pane => console.log(pane));
      }

      leftToAllocate = 100 - this.panes.map(pane => pane.size).reduce((previousValue, currentValue) => previousValue + currentValue, 0);

      this.readjustSizes(leftToAllocate, ungrowable, unshrinkable);
    }

    if (this.debugOutput){
      this.panes.forEach(pane => console.log(pane));
    }
  }

  equalizeAfterAddOrRemove({addedPane, removedPane}: { addedPane?: PaneProps; removedPane?: PaneProps } = {}): void {
    let equalSpace: number = 100 / this.panesCount;
    let leftToAllocate = 0;
    const ungrowable: string[] = [];
    const unshrinkable: string[] = [];

    if (addedPane && addedPane.givenSize !== null) {
      equalSpace = (100 - addedPane.givenSize) / (this.panesCount - 1);
    }

    this.panes.forEach(pane => {
      leftToAllocate -= pane.size ?? 0; // Coalesce null size to 0
      if (pane.size !== null && pane.size >= pane.max) {
        ungrowable.push(pane.id);
      }
      if (pane.size !== null && pane.size <= pane.min) {
        unshrinkable.push(pane.id);
      }
    });

    if (Math.abs(leftToAllocate) < 0.1) {
      return;
    } // If the space is nearly balanced, no further action is required.

    this.panes.forEach(pane => {
      if (!(addedPane && addedPane.givenSize !== null && addedPane.id === pane.id)) {
        pane.size = Math.max(Math.min(equalSpace, pane.max), pane.min);
        leftToAllocate -= pane.size ?? 0; // Adjust for pane sizes set in this loop
      }
      // Recheck conditions after potential resizing
      if (pane.size !== null && pane.size >= pane.max) {
        ungrowable.push(pane.id);
      }
      if (pane.size !== null && pane.size <= pane.min) {
        unshrinkable.push(pane.id);
      }
    });

    if (leftToAllocate > 0.1) {
      this.readjustSizes(leftToAllocate, ungrowable, unshrinkable);
    }
  }

  readjustSizes(leftToAllocate: number, ungrowable: string[], unshrinkable: string[]): void {

    if (this.debugOutput) {
      console.log(`readjustSizes: leftToAllocate=${leftToAllocate}, ungrowable=${ungrowable.join(',')} unshrinkable=${unshrinkable.join(',')}`)
    }

    let equalSpaceToAllocate: number;
    if (leftToAllocate > 0) {
      equalSpaceToAllocate = leftToAllocate / (this.panesCount - ungrowable.length);
    } else {
      equalSpaceToAllocate = leftToAllocate / (this.panesCount - unshrinkable.length);
    }

    this.panes.forEach((pane: { id: string; size: number; min: number; max: number; update: (style: Record<string, string>) => void }) => {
      if (leftToAllocate > 0 && !ungrowable.includes(pane.id)) {
        const newPaneSize: number = Math.max(Math.min(pane.size + equalSpaceToAllocate, pane.max), pane.min);
        const allocated: number = newPaneSize - pane.size;
        leftToAllocate -= allocated;
        pane.size = newPaneSize;
      } else if (leftToAllocate <= 0 && !unshrinkable.includes(pane.id)) {
        const newPaneSize: number = Math.max(Math.min(pane.size + equalSpaceToAllocate, pane.max), pane.min);
        const allocated: number = newPaneSize - pane.size;
        leftToAllocate -= allocated;
        pane.size = newPaneSize;
      }

      // Update each pane through the registered `update` method, adjusting styles based on the new size.
      pane.update({
                    [this.horizontal ? 'height' : 'width']: `${pane.size}%`
                  });
    });

    if (Math.abs(leftToAllocate) > 0.1) { // Check if there's a significant amount of space left to allocate.
      nextTick(() => {
        if (this.ready) {
          console.warn('Splitpanes: Could not resize panes correctly due to their constraints.');
        }
      });
    }
  }

  @Watch('panes', {deep: true, immediate: false})
  onPanesChange() {
    if (this.debugOutput) {
      console.log('onPanesChange');
    }
    this.updatePaneComponents();
  }

  @Watch('horizontal')
  onHorizontalChange() {
    if (this.debugOutput) {
      console.log('onHorizontalChange');
    }
    this.updatePaneComponents();
  }

  @Watch('firstSplitter')
  onFirstSplitterChange() {
    this.redoSplitters();
  }

  @Watch('dblClickSplitter')
  onDblClickSplitterChange(enable: boolean) {
    const splitters = [...this.container.querySelectorAll('.splitpanes__splitter')].map(element => element as HTMLElement);
    splitters.forEach((splitter, i) => {
      splitter.ondblclick = enable ? (event: MouseEvent) => this.onSplitterDblClick(event, i) : null;
    });
  }

  beforeUnmount() {
    this.ready = false;
  }

  mounted() {
    if (this.debugOutput) {
      console.log('Splitpanes mounted');
    }
    this.checkSplitpanesNodes();
    this.redoSplitters();
    this.resetPaneSizes();
    this.ready = true;
    this.emitReady();
  }

  @Emit('ready')
  emitReady() {
    return this.ready;
  }

  @Emit('resize')
  emitResize() {
    if (this.debugOutput) {
      console.log('Resize');
      this.panes.forEach(pane => console.log(pane));
    }
    return this.panes.map(pane => ({min: pane.min, max: pane.max, size: pane.size}));
  }

  @Emit('resized')
  emitResized() {
    if (this.debugOutput) {
      console.log('Resize');
      this.panes.forEach(pane => console.log(pane));
    }
    return this.panes.map(pane => ({min: pane.min, max: pane.max, size: pane.size}));
  }

  @Emit('splitter-click')
  emitSplitterClick(splitterIndex: number) {
    return this.panes[splitterIndex];
  }

  @Emit('pane-maximize')
  emitPaneMaximize(splitterIndex: number) {
    return this.panes[splitterIndex];
  }

  @Emit('pane-click')
  emitPaneClick(paneId: number) {
    return this.indexedPanes[paneId];
  }

  @Emit('pane-add')
  emitPaneAdd(index: number) {
    return {index, panes: this.panes.map(p => ({min: p.min, max: p.max, size: p.size}))}; // 4. Fire `pane-add` event.
  }

  @Emit('pane-remove')
  emitPaneRemove(removed: any) {
    return {removed, panes: this.panes.map(pane => ({min: pane.min, max: pane.max, size: pane.size}))};
  }

  render() {
    return h(
      'div',
      {
        ref: 'container',
        class: [
          'splitpanes',
          `splitpanes--${this.horizontal ? 'horizontal' : 'vertical'}`,
          {'splitpanes--dragging': this.touch.dragging},
        ],
      },
      this.$slots.default?.()
    );
  }
}

export default toNative(Splitpanes);
export {Splitpanes};
</script>

<style lang="scss">
.splitpanes {
  display: flex;
  width: 100%;
  height: 100%;

  &--vertical {
    flex-direction: row;
  }

  &--horizontal {
    flex-direction: column;
  }

  &--dragging * {
    user-select: none;
  }

  &__pane {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .splitpanes--vertical & {
      transition: width 0.2s ease-out;
    }

    .splitpanes--horizontal & {
      transition: height 0.2s ease-out;
    }

    .splitpanes--dragging & {
      transition: none;
    }
  }

  // Disable default zoom behavior on touch device when double tapping splitter.
  &__splitter {
    touch-action: none;
  }

  &--vertical > .splitpanes__splitter {
    min-width: 1px;
    cursor: col-resize;
  }

  &--horizontal > .splitpanes__splitter {
    min-height: 1px;
    cursor: row-resize;
  }
}

.splitpanes.default-theme {
  .splitpanes__pane {
    background-color: #f2f2f2;
  }

  .splitpanes__splitter {
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    flex-shrink: 0;

    &:before, &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: rgba(0, 0, 0, .15);
      transition: background-color 0.3s;
    }

    &:hover:before, &:hover:after {
      background-color: rgba(0, 0, 0, .25);
    }

    &:first-child {
      cursor: auto;
    }
  }
}

.default-theme {
  &.splitpanes .splitpanes .splitpanes__splitter {
    z-index: 1;
  }

  &.splitpanes--vertical > .splitpanes__splitter,
  .splitpanes--vertical > .splitpanes__splitter {
    width: 7px;
    border-left: 1px solid #eee;
    margin-left: -1px;

    &:before, &:after {
      transform: translateY(-50%);
      width: 1px;
      height: 30px;
    }

    &:before {
      margin-left: -2px;
    }

    &:after {
      margin-left: 1px;
    }
  }

  &.splitpanes--horizontal > .splitpanes__splitter,
  .splitpanes--horizontal > .splitpanes__splitter {
    height: 7px;
    border-top: 1px solid #eee;
    margin-top: -1px;

    &:before,
    &:after {
      transform: translateX(-50%);
      width: 30px;
      height: 1px;
    }

    &:before {
      margin-top: -2px;
    }

    &:after {
      margin-top: 1px;
    }
  }
}
</style>
