import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import store from '@/store';
import {AiTemplateState} from '@/store/models/aiTemplate.model';
import {AiTemplate} from '@/api/models/aiTemplate.model';
import {GetAiTemplates} from '@/api/services/aiAssisstant.api';
import EditorModule from '@/store/modules/EditorModule';
import LlmModule from '@/store/modules/LlmModule';

@Module({dynamic: true, namespaced: true, store, name: 'aiTemplate'})
class AiTemplateModule extends VuexModule implements AiTemplateState {

    // initial state
    private _isLoading = false;
    private _aiTemplates: AiTemplate[] = [];
    private _selectedAiTemplate: AiTemplate | null  = null;

    get selectedSemanticType(): string | null {
        return EditorModule.semanticType;
    }
    get selectedLlm(): string | undefined {
        return LlmModule.selectedLlm?.llmName;
    }
    get filteredAiTemplates(): AiTemplate[] {
        if(this.selectedSemanticType === null || this.selectedLlm === undefined) {
            return [];
        }
        return this._aiTemplates.filter(aiTemplate => aiTemplate.semanticType === this.selectedSemanticType)
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    @Mutation
    private setLoading(): void {
        this._isLoading = true;
    }

    get aiTemplates(): AiTemplate[] {
        return this._aiTemplates;
    }
    get selectedAiTemplate(): AiTemplate | null {
        return this._selectedAiTemplate;
    }

    @Mutation
    public setSelectedAiTemplate(value: AiTemplate | null) {
        this._selectedAiTemplate = value;
    }

    @Mutation
    public setAiTemplates(value: AiTemplate[]) {
        this._aiTemplates = value;
    }

     @Mutation
     private getAiTemplatesEnd(results: Array<AiTemplate> | null) {
        if (results != null) {
            this._aiTemplates = results;
        }
        this._isLoading = false;
    }

    @Action
    async getaiTemplates(applicationDocumentGuid: string): Promise<Array<AiTemplate>> {
        this.setLoading();
        return GetAiTemplates(applicationDocumentGuid).then((results: Array<AiTemplate>) => {
            this.getAiTemplatesEnd(results);
            return results;
        }).catch((error) => {
            this.getAiTemplatesEnd(null);
            throw error;
        });
    }
}

export default getModule(AiTemplateModule);