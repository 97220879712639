import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import store from '@/store';
import {InterpretAiTemplate} from '@/api/services/aiAssisstant.api';
import EditorModule from '@/store/modules/EditorModule';
import {AiAssistantState} from '@/store/models/AiAssistantState.model';
import {InterpretAiTemplateRequestDto, InterpretAiTemplateResponseDto} from '@/api/models/aiAssisstant.model';
import {GetLocalizedExceptionMessageFor} from '@/api/services/exceptions';
import i18n from '@/i18n';
import ApplicationModule from '@/store/modules/ApplicationModule';


@Module({dynamic: true, namespaced: true, store, name: 'aiAssistant'})
class AiAssistantModule extends VuexModule implements AiAssistantState {

  // initial state
  _numLoading = 0;
  _text: string = '';
  _textWithPlaceholdersSubstituted = '';
  _showsSubstitutedText: boolean = false;
  _lastSelectedBlock: string | null = null;

  get isLoading(): boolean {
    return this._numLoading > 0;
  }

  @Mutation
  increaseLoading() {
    this._numLoading = this._numLoading + 1;
  }

  @Mutation
  decreaseLoading() {
    this._numLoading = this._numLoading - 1;
  }

  get text(): string {
    return this._text;
  }

  @Mutation
  changeText(newText: string) {
    this._text = newText;
  }

  get textWithPlaceholdersSubstituted() {
    return this._textWithPlaceholdersSubstituted;
  }

  @Mutation
  changeSubstituedText(newText: string) {
    this._textWithPlaceholdersSubstituted = newText;
  }

  get currentApplicationDocumentUuid(): string | null {
    if (ApplicationModule.currentApplicationDocument?.guid === undefined) {
      return null;
    }
    return ApplicationModule.currentApplicationDocument?.guid;
  }

  get lastSelectedBlock(): string | null {
    return this._lastSelectedBlock;
  }

  @Mutation
  public setLastSelectionGuid(newGuid: string | null) {
    this._lastSelectedBlock = newGuid;
  }

  get showsSubstitutedText(): boolean {
    return this._showsSubstitutedText;
  }

  @Mutation
  setShowsSubstitutedText(newValue: boolean): void {
    this._showsSubstitutedText = newValue;
  }

  @Action
  onShowSubstitutedTextChanged(newValue: boolean): Promise<InterpretAiTemplateResponseDto> {
    this.setShowsSubstitutedText(newValue);
    if (this.currentApplicationDocumentUuid === null) {
      this._textWithPlaceholdersSubstituted = 'No application document selected';
      return Promise.reject();
    }
    if (this.lastSelectedBlock === null) {
      this._textWithPlaceholdersSubstituted = 'No context block selected';
      return Promise.reject();
    }

    const request: InterpretAiTemplateRequestDto = {
      aiTemplateString: this.text,
      applicationDocumentGuid: this.currentApplicationDocumentUuid,
      contextBlockGuid: this.lastSelectedBlock
    };

    this.increaseLoading();
    return InterpretAiTemplate(request)
      .then(value => {
        switch (value.type) {
          case "success":
            this.changeSubstituedText(value.interpretationResult);
            break;
          case "failure":
            this.changeSubstituedText(GetLocalizedExceptionMessageFor(value.errorMessage));
            break;
          default: {
            // here to exhaustively check
            const exhaustiveCheck: never = value;
          }
        }
        return value;
      })
      .catch(reason => {
        this.changeSubstituedText(i18n.global.t('aiAssistantDialog.unexpectedInterpretationError'));
        throw reason;
      })
      .finally(() => this.decreaseLoading());
  }
}

export default getModule(AiAssistantModule);