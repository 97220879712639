import {EditorState as PmEditorState, Plugin, PluginKey, TextSelection, Transaction as PmTransaction} from '@tiptap/pm/state'
import {Fragment, Node as PmNode, ResolvedPos as PmResolvedPos, Slice} from '@tiptap/pm/model';
import {ReplaceStep, Step as PmStep} from '@tiptap/pm/transform';
import {isLeftmostTextblockOfLogicalBlock} from '@/components/applicationEditor/utils/node.util';
import {
  determineDocStartPos,
  findNextTextblock,
  getMappedTransactionPosition,
  SearchDirection
} from '@/components/applicationEditor/utils/prosemirror.util';
import EditorModule from '@/store/modules/EditorModule';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';

const BlockNodeDeletionPluginKey = new PluginKey('BlockNodeDeletionPlugin');

/**
 * This plugin ensures that
 * * the user/proseMirror can not delete/merge nodes (instead of deleting a node, its content will be set to a space)
 * * the content of the first textblock of a logical block is trimmed when the content changes from blank to not blank.
 * * the content always beginns with a seperation character (space, comma etc.) (except for the first textblock of a logical block)
 * * the guid of all changed nodes are stored in the store such their content can be persisted later.
 *
 * Therefore we use appendTransaction to add a step which undos the "wrong" step and adds other step/steps that does
 * the user-action in the correct way and set the cursor if necessary.
 *
 * Background:
 * It would be also possible to block/filter the complete transaction by implementing a function "filterTransaction" that returns
 * false. However it turns out that:
 *
 * 1) Prosemirror calls filterTransaction before it calls appendTransaction. However, if filterTransaction returns false,
 *    appendTransaction is not called at all.
 * 2) At that point in time, when filterTransaction/appendTransaction is called, the change/steps of the user are already applied to the
 *    state. However, if filterTransaction return false, this state is not "stored". Therefore, modifying the transaction/steps in
 *    filterTransaction/appendTransaction has no effect.
 */

// Do not process correct transactions annotated with this meta keys
export type DoNotProcessInBlockNodeDeletionPluginMetaKeysType = ProsemirrorTransactionMeta.INITIAL_STATE
  | ProsemirrorTransactionMeta.PATENTENGINE_HISTORY_PLUGIN
  | ProsemirrorTransactionMeta.SPELLCHECK_RESULT
  | ProsemirrorTransactionMeta.UPDATE_ACTIVE_EDITOR
  | ProsemirrorTransactionMeta.UPDATE_FROM_BACKEND
  | ProsemirrorTransactionMeta.UPDATE_LOGICAL_DEPTH_ATTRIBUTE;

export const DoNotProcessInBlockNodeDeletionPluginMetaKeys: DoNotProcessInBlockNodeDeletionPluginMetaKeysType[] = [
  ProsemirrorTransactionMeta.INITIAL_STATE,
  ProsemirrorTransactionMeta.PATENTENGINE_HISTORY_PLUGIN,
  ProsemirrorTransactionMeta.SPELLCHECK_RESULT,
  ProsemirrorTransactionMeta.UPDATE_ACTIVE_EDITOR,
  ProsemirrorTransactionMeta.UPDATE_FROM_BACKEND,
  ProsemirrorTransactionMeta.UPDATE_LOGICAL_DEPTH_ATTRIBUTE,
];

export class BlockNodeDeletionPlugin extends Plugin {

  debug = false;

  constructor() {
    super({
            key: BlockNodeDeletionPluginKey,
            props: {},

            appendTransaction(transactions: readonly PmTransaction[], oldState: PmEditorState, newState: PmEditorState) {
              const plugin = BlockNodeDeletionPluginKey.get(oldState) as BlockNodeDeletionPlugin;

              return plugin.correctTransactionSteps(transactions, oldState, newState);
            }
          });
  }

  public correctTransactionSteps(transactions: readonly PmTransaction[], oldState: PmEditorState, newState: PmEditorState) {

    const selection = oldState.selection;
    const additionalTransaction: PmTransaction = newState.tr;
    additionalTransaction.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'BlockNodeDeletionPlugin');

    transactions.forEach((transaction: PmTransaction) => {

      if (DoNotProcessInBlockNodeDeletionPluginMetaKeys.some(key => transaction.getMeta(key) !== undefined)) {
        return;
      }

      if (this.isUndoRedoTransaction(transaction, oldState)) {
        // for an undo transaction we need to remember the node so it can be saved to the backend
        const changedNode = newState.selection.$anchor.node();
        if (changedNode) {
          EditorModule.addChange(changedNode.attrs.guid);
        }
        return;
      }

      const dispatchSource = transaction.getMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE);
      if (this.debug) {
        if (dispatchSource == undefined) {
          // The editor view uses a few metadata properties: it will attach a property "pointer" with the value true to selection
          // transactions directly caused by mouse or touch input, and a "uiEvent" property of that may be "paste", "cut", or "drop".
          if (transaction.getMeta('pointer')) {
            console.log('correctTransactionSteps: "pointer transaction" caused by mouse or touch input');
          } else if (transaction.getMeta('uiEvent')) {
            console.log('correctTransactionSteps: "uiEvent transaction":', transaction.getMeta('uiEvent'));
          } else {
            console.log('correctTransactionSteps: The dispatch source is undefined for transaction:', transaction);
          }
        } else {
          this.logDebug('correctTransactionSteps dispatchSource', dispatchSource);
        }
      }

      const newNode = newState.selection.$anchor.node();
      const newContent = newNode.textContent;
      const oldNode = oldState.selection.$anchor.node();
      const oldContent = oldNode.textContent;

      const isSearchReplacement = transaction.getMeta(ProsemirrorTransactionMeta.IS_SEARCH_REPLACEMENT);
      const isPasteReplacement = transaction.getMeta(ProsemirrorTransactionMeta.PASTE);
      let checkNewTextAndSepChar = false;

      transaction.setMeta(ProsemirrorTransactionMeta.PROCESSED_IN_BLOCK_NODE_DELETION_PLUGIN, true);
      transaction.steps.forEach((step: PmStep) => {
        // If the step only adds/removes marks, just remember the guids of all involved textnodes in the store (to save the changes later).
        if (this.isAddMarkStep(step) || this.isRemoveMarkStep(step)) {
          this.logDebug('isAddMarkStep or isRemoveMarkStep', step);
          this.storeInvolvedTextnode(transaction.before, step);
          return;
        }

        checkNewTextAndSepChar = true;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const from: number = step['from'];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const to: number = step['to'];

        if (isSearchReplacement) {
          this.logDebug('1 isSearchReplacement', step);

          this.saveStepChanges(step, transaction);

        } else if (oldState.selection.$anchor.node().attrs.guid !== oldState.selection.$head.node().attrs.guid
          // exclude transactions with dispatch source 'paste' as they are from patent-engines custom paste mechanism.
          // This logic knows about the document structure constraints and adheres to them.
          // So if a text gets inserted via the paste mechanism this plugin should only record that the block has changed,
          // i.e. execute the last case in this if-/else cascade
          && dispatchSource !== ProsemirrorTransactionMeta.PASTE) {
          this.logDebug('2 Anchor and head have different guids', step);

          this.addUndoStep(step, transaction.before, additionalTransaction);
          this.correctUserMultiNodeEdit(step, oldState, newState, additionalTransaction, isPasteReplacement);

        } else if (oldNode.attrs.isReadOnly === true) {
          this.logDebug('3 isReadOnly', step);

          this.addUndoStep(step, transaction.before, additionalTransaction);
          additionalTransaction.setSelection(TextSelection.create(additionalTransaction.doc, selection.from));

        } else if (selection.from === selection.to && from < selection.from && selection.to < to && oldNode.nodeSize === 3) {
          this.logDebug('4 New char(s) in block that was empty', step);

          this.addUndoStep(step, transaction.before, additionalTransaction);
          const resolvedPos = transaction.before.resolve(selection.from);
          this.correctUserDeletesLastCharacter(resolvedPos, oldState, newState, additionalTransaction);

        } else if (newContent.length === 0) {
          this.logDebug('5 Content is empty', step);

          this.addUndoStep(step, transaction.before, additionalTransaction);
          this.correctUserDeletedCompleteNodeContent(oldState, newState, additionalTransaction);

        } else {
          if (transaction.docChanged && step instanceof ReplaceStep) {
            const fromNode = transaction.before.resolve(step.from).node();
            const toNode = transaction.before.resolve(step.to).node();
            const notText = !fromNode.isTextblock;
            const equal = fromNode.eq(toNode);
            // Find all cases, in which a transaction includes exactly the borders of a structuralBlock and prevent the blocks deletion
            if (notText && equal) {
              this.logDebug('6 Deletion of structuralBlock', step);
              this.addUndoStep(step, transaction.before, additionalTransaction);
            } else {
              // Make sure to save the changes
              this.saveStepChanges(step, transaction);
            }
          } else {
            this.logDebug('7 Else', step);
            this.saveStepChanges(step, transaction);
          }
        }

      });

      const resolvedPos = transaction.doc.resolve(transaction.selection.from);
      if (checkNewTextAndSepChar && resolvedPos.node().attrs.isReadOnly == false) {
        if (oldContent.trim().length === 0 && newContent.trim().length > 0) {
          this.logDebug('New Text in previously empty node');
          this.correctUserFilledBlanknode(oldState, newState, additionalTransaction);
        } else if (!this.stringStartsWithSeperationCharacter(newContent)) {
          this.logDebug('Content doesn\'t start with seperation character');
          this.correctUserDeletesSeperationCharacter(oldState, newState, additionalTransaction);
        }
      }
    })

    return additionalTransaction//.setMeta(ProsemirrorTransactionMeta.APPENDED_TRANSACTION, true);
  }

  logDebug(text: string, object?: any) {
    if (this.debug) {
      console.log(text, object);
    }
  }


  /**
   * Go through all mappings of a transaction step and add the nodes, resolved at the given positions, to the list of nods to update.
   *
   * @param step one step of the transaction
   * @param transaction the transaction this step belongs to
   */
  private saveStepChanges(step: PmStep, transaction: PmTransaction): void {

    // Determine the complete text of this step
    const slice: Slice = (step as any).slice;
    const fragment: Fragment = slice.content;
    const stepNodes: Array<PmNode> = (fragment as any).content;
    const stepText: string = stepNodes.reduce((accumulator: string, node: PmNode) => accumulator + node.textContent, '');

    step.getMap().forEach((oldStart: number, oldEnd: number, newStart: number, newEnd: number) => {
      const docStartPos = determineDocStartPos(transaction.doc);

      // First make sure this step's position refers to a position inside of the text blocks we can edit
      // On GenerateAll Prosemirror may trigger a transaction with oldStart = 1 if the document has a figure.
      if (oldStart >= docStartPos) {
        const resolvedPos = transaction.doc.resolve(oldStart);
        const replaceNode = resolvedPos.node();

        let leavesText = '';
        this.findLeafs(replaceNode).forEach((leaf) => {
          leavesText += leaf.textContent;
        });

        // Special case: Check if the texts are the same:
        // After generating (all) blocks prosemirror might trigger transactions
        // that would lead to replacements with oneself (maybe only for the read only block SHORT_DESCRIPTION_FIGURE_TEXT)
        if (stepText !== leavesText) {
          // User/Prosemirror has done something "normal" or a replace(All)
          // Just store the GUID of the node in the store, such that we can later persist the change in the backend
          // Special case needed for search & replace -> Take all children (leaves)
          // Attention, this can rarely (deterministic) lead to saving more than needed or even the whole document.
          // It may depend on from where to where we replace, depending on whether these replacements start or end at block boundaries, a
          // resolvedPos.node() can return a too high father node or even the root. Since you cannot see which of the children is affected,
          // this can happen.
          this.findLeafs(replaceNode).forEach((leaf) => {
            EditorModule.addChange(leaf.attrs.guid);
          });
        }
      }
    });
  }

  private findLeafs(node: PmNode): PmNode[] {
    if (node.isTextblock) {
      return [node];
    }
    let nodes: PmNode[] = [];
    for (let childIndex = 0; childIndex < node.childCount; childIndex++) {
      nodes = nodes.concat(this.findLeafs(node.child(childIndex)));
    }
    return nodes;
  }

  private storeInvolvedTextnode(root: PmNode, step: PmStep): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const from: number = step['from'];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const to: number = step['to'];
    root.nodesBetween(from, to, (node: PmNode) => {
      if (node.isTextblock && node.attrs.guid) {
        EditorModule.addChange(node.attrs.guid);
      }
      return true;
    });
  }

  /**
   * Adds a step that inverts the given step.
   *
   * @param step that should be inverted
   * @param root node of the document
   * @param transaction on which the inverting-step should be executed
   */
  private addUndoStep(step: PmStep, root: PmNode, transaction: PmTransaction): void {
    transaction.step(step.invert(root));
  }

  /**
   * Handles the user-action: User deleted of the last character of a node. This is done by:
   * 1) Replace the node-content with a space
   * 2) Move the cursor either to the previous block or (if we are already in the first textblock of the current logical block) to the start
   * 3) Stores the GUID of the node in the store, such that we can later persist the change in the backend
   *
   *
   * Test situations (cursor is represented with | and a selection is represented with []):
   *   Node that is the first leaf in a logical block has following content (and cursor): 'a|'
   *   Node that is the first leaf in a logical block has following content (and cursor): '|a'
   *   Node that is the first leaf in a logical block has following content (and cursor): ' |'
   *   Node that is the first leaf in a logical block has following content (and cursor): '| '
   *   Node that is not the first leaf in a logical block has following content (and cursor): ' |'
   *
   * Test each situation above with
   *   pressing <del> (if cursor is left)
   *   pressing <backspace> (if cursor is right)
   */
  private correctUserDeletesLastCharacter(resolvedPos: PmResolvedPos,
                                          oldState: PmEditorState,
                                          newState: PmEditorState,
                                          additionalTransaction: PmTransaction): void {
    const nodeBefore = resolvedPos.node();
    const oldNode = oldState.selection.$anchor.node();

    additionalTransaction.replaceWith(resolvedPos.start(), resolvedPos.end(), newState.schema.text(' '));

    const isLeftmostBlock = isLeftmostTextblockOfLogicalBlock(oldState.selection.$anchor, oldNode);
    let pos = isLeftmostBlock ? resolvedPos.start() : resolvedPos.pos;
    const backspacePressed = oldState.selection.from !== newState.selection.from;

    if (!isLeftmostBlock && backspacePressed) {
      pos = pos - 3;
    }

    if (pos !== -1) {
      additionalTransaction.setSelection(TextSelection.create(additionalTransaction.doc, pos))
    }

    EditorModule.addChange(nodeBefore.attrs.guid);
  }

  /**
   * Handles the user-action: Select more than one node and press a key => Modify several nodes simultaniously.
   * This is done by:
   * 1) Modify the first node: dependent on whether the user deleted/inserted text and if the whole node or only a suffix was selected.
   * 2) Clear all nodes between the first and the last node: Replace its content with space.
   * 3) Modify the last node: Replace the selection with a space, except if it's the first textblock of a logical block and the user has
   *    selected a proper prefix.
   * 4) Stores the GUID of all modified nodes in the store, such that we can later persist the changes in the backend
   * 5) Place the cursor properly.
   *
   * Test cases:
   *   select start of a node -> end of a node with no nodes between
   *   select middle of a node -> end of a node with no nodes between
   *   select start of a node -> middle of a node with no nodes between
   *   select middle of a node -> middle of a node with no nodes between
   *   select start of a node -> end of a node with multiple nodes between
   *   select middle of a node -> end of a node with multiple nodes between
   *   select start of a node -> middle of a node with multiple nodes between
   *   select middle of a node -> middle of a node with multiple nodes between
   * Test each selection with
   *   pressing <del>
   *   pressing <backspace>
   *   pressing a key
   *   pressing <space>
   *   inserting text from clipboard
   */
  private correctUserMultiNodeEdit(step: PmStep,
                                   oldState: PmEditorState,
                                   newState: PmEditorState,
                                   additionalTransaction: PmTransaction, isPaste: boolean): void {

    const oldSelection = oldState.selection;
    const left = oldSelection.anchor < oldSelection.head ? oldSelection.$anchor : oldSelection.$head;
    const right = oldSelection.anchor < oldSelection.head ? oldSelection.$head : oldSelection.$anchor;

    // We store every modification in an array and executed them at the end in reverse order to ensure
    // that a modification does not change the document positions for another modification.
    // Using https://prosemirror.net/docs/ref/#transform.Position_Mapping would be a better solution.
    interface Modification {
      guid: string;
      start: number;
      end: number;
      content: string;
    }

    const modifications: Modification[] = [];

    // Handle first node
    const content = this.detectUserInput(step, left, isPaste);
    let leftNodeContent = content;
    if (left.node().attrs.isReadOnly !== true) {
      leftNodeContent = this.isTrailingBlankRequired(left, content) ? ` ${content}` : content;
      modifications.push({guid: left.node().attrs.guid, start: left.pos, end: left.end(), content: leftNodeContent});
    }

    // Clear nodes between
    oldState.doc.descendants((node: PmNode, pos: number) => {
      const nodeStart = pos + 1;
      const nodeEnd = pos + node.nodeSize - 1;
      if (left.end() < nodeStart && nodeEnd < right.start()) {
        if (node.attrs.isReadOnly === true) {
          return false;
        }
        if (node.isTextblock) {
          modifications.push({guid: node.attrs.guid, start: nodeStart, end: nodeEnd, content: ' '});
        }
      }
      return true;
    });

    // Handle last node
    if (right.node().attrs.isReadOnly !== true) {
      const spacePrefixRequired = right.end() === right.start() || !isLeftmostTextblockOfLogicalBlock(right, right.node());
      modifications.push({guid: right.node().attrs.guid, start: right.start(), end: right.pos, content: spacePrefixRequired ? ' ' : ''});
    }


    // Reverse modifications order
    modifications.sort((a, b) => a.start - b.start);
    modifications.reverse();

    // Execute modifications
    modifications.forEach((modification) => {
      if (modification.content && modification.content.length > 0) {
        additionalTransaction.replaceWith(modification.start, modification.end, newState.schema.text(modification.content));
      } else {
        additionalTransaction.delete(modification.start, modification.end);
      }
      EditorModule.addChange(modification.guid);
    });

    // Set cursor
    const cursorPosition = getMappedTransactionPosition(additionalTransaction, left.pos);
    additionalTransaction.setSelection(TextSelection.create(additionalTransaction.doc, cursorPosition))
  }


  /**
   * Detects the user input of a given step, e.g. which character(s) had the user pressed that caused that step.
   * This content is stored in the slice in that node that contains the left selection.
   *
   * @param step caused by the user input.
   * @param resolvedPos the left resolved position of the old selection
   */
  private detectUserInput(step: PmStep, resolvedPos: PmResolvedPos, isPaste?: boolean): string {
    const guid = resolvedPos.node().attrs.guid;
    let nodeSlice: null | PmNode = null;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const slice: Slice = step['slice'];
    slice.content.descendants((node: PmNode) => {
      if (node.attrs.guid === guid || isPaste) {
        nodeSlice = node;
      }
    });
    return (nodeSlice !== null && (nodeSlice as Node).textContent) || '';
  }

  /**
   * Calculates if a trailing blank is required if the given content would be inserted at the given resolved position
   * (replacing the previous content from the resolved position to the end of the node).
   *
   * @param resolvedPos where the content would be inserted
   * @param content that would be inserted
   */
  private isTrailingBlankRequired(resolvedPos: PmResolvedPos, content: string): boolean {
    if (resolvedPos.start() !== resolvedPos.pos) {
      return false;
    }
    if (this.stringStartsWithSeperationCharacter(content)) {
      return false;
    }
    if (isLeftmostTextblockOfLogicalBlock(resolvedPos, resolvedPos.node())) {
      return content.trim().length === 0;
    } else {
      return true;
    }
  }

  /**
   * Handles the user-action: User selected the complete node and delete its content. This is done by:
   * 1) Replace the node-content with a space
   * 2) Store the GUID of the node in the store, such that we can later persist the change in the backend
   *
   *
   * Test situations (cursor is represented with | and a selection is represented with []):
   *   Node that is the first leaf in a logical block has following content (and selection): '[aaa]'
   *   Node that is the first leaf in a logical block has following content (and selection): '[   ]'
   *   Node that is not the first leaf in a logical block has following content (and selection): '[aaa]'
   *   Node that is not the first leaf in a logical block has following content (and selection): '[   ]'
   *
   * Test each situation above with
   *   pressing <del>
   *   pressing <backspace>
   *   pressing <space> << Known Bug "ReplaceBySpace"
   */
  private correctUserDeletedCompleteNodeContent(oldState: PmEditorState,
                                                newState: PmEditorState,
                                                additionalTransaction: PmTransaction): void {

    const oldNode = oldState.selection.$anchor.node();

    const start = oldState.selection.$anchor.start();
    const end = oldState.selection.$anchor.end();

    if (oldNode.attrs.isReadOnly !== true) {
      additionalTransaction.replaceWith(start, end, newState.schema.text(' '));

      EditorModule.addChange(oldNode.attrs.guid);

      // user did hit backspace, moving the cursor exactly one place to the left
      // in that case we need to move the cursor to the end of text block immediately previous of the current block we are in
      if (start + 1 == end) {
        const indexOfBlockToTheLeft = getMappedTransactionPosition(
          additionalTransaction,
          findNextTextblock(additionalTransaction.doc, oldState.selection.$anchor, SearchDirection.LEFT)
        );
        const newSelection = TextSelection.create(additionalTransaction.doc, indexOfBlockToTheLeft);
        additionalTransaction.setSelection(newSelection);
      }
    }
  }

  /**
   * Handles the user-action: User filled the node that previously was blank (contained only spaces). This is done by:
   * 1) In the first textblock of the current logical block, we must trim() the content (to remove the artificial space)
   * 2) If not in the fist textblock of the current logical block, we must ensure that the content starts with a seperating character.
   * 3) Store the GUID of the node in the store, such that we can later persist the change in the backend
   *
   *
   * Test situations (cursor is represented with | and a selection is represented with []):
   *   Node that is the first leaf in a logical block has following content (and cursor): '| '
   *   Node that is the first leaf in a logical block has following content (and cursor): '|  '
   *   Node that is the first leaf in a logical block has following content (and cursor): '  |  '
   *   Node that is the first leaf in a logical block has following content (and cursor): '  |'
   *   Node that is the first leaf in a logical block has following content (and cursor): ' |'
   *   Node that is the first leaf in a logical block has following content (and selection): '[ ]'
   *   Node that is the first leaf in a logical block has following content (and selection): '[  ]'
   *   Node that is the first leaf in a logical block has following content (and selection): '[  ]  '
   *   Node that is the first leaf in a logical block has following content (and selection): '  [  ]'
   *   Node that is the first leaf in a logical block has following content (and selection): '  [ ]  '
   *   Node that is not the first leaf in a logical block has following content (and cursor):' |'
   *   Node that is not the first leaf in a logical block has following content (and cursor):'  |'
   *   Node that is not the first leaf in a logical block has following content (and cursor):' | '
   *   Node that is not the first leaf in a logical block has following content (and cursor):'  |  '
   *   Node that is not the first leaf in a logical block has following content (and selection):'[ ]'
   *   Node that is not the first leaf in a logical block has following content (and selection):'[  ]'
   *   Node that is not the first leaf in a logical block has following content (and selection):'[  ]  '
   *   Node that is not the first leaf in a logical block has following content (and selection):'  [  ]'
   *   Node that is not the first leaf in a logical block has following content (and selection):'  [  ]  '
   *
   * Test each situation above with
   *   inserting a character
   *   inserting a a seperating character (,)
   *   inserting text from clipboard
   */
  private correctUserFilledBlanknode(oldState: PmEditorState, newState: PmEditorState, additionalTransaction: PmTransaction): void {

    const newResolvedPos = newState.selection.$anchor;
    const newNode = newResolvedPos.node();

    if (isLeftmostTextblockOfLogicalBlock(newResolvedPos, newNode)) {
      this.logDebug('correctLeftmostTextblockOfLogicalBlock');
      const resultNodeArray = this.trimNodeEdges(newNode, newState);
      const fragment = Fragment.fromArray(resultNodeArray);

      const oldResolvedPos = oldState.selection.$anchor;
      const oldNode = oldResolvedPos.node();

      if (newNode.attrs.guid === oldNode.attrs.guid) {
        additionalTransaction.replaceWith(newResolvedPos.start(), newResolvedPos.end(), fragment);
      }
    } else {
      // Intentionally empty - non left most block case is handled by input rule
    }

    const currentlyChangedNodeGuid = this.getAndKeepCurrentlyChangedNodeAttrs(oldState, newState, additionalTransaction)?.guid;
    EditorModule.addChange(currentlyChangedNodeGuid);
  }

  /**
   * Handles the user-action: User deletes the seperation character in a node. This is done by:
   * 1) If not in the first textblock of the current logical block, we must ensure that the content starts with a seperation character.
   * 2) If not in the first textblock of the current logical block and user explicitly deletes the space, we move the curser to the previous
   *    textblock.
   * 3) Store the GUID of the node in the store, such that we can later persist the change in the backend
   *
   *
   * Test situations (cursor is represented with | and a selection is represented with []):
   *   Node that is the first leaf in a logical block has following content (and cursor):'| aaa'
   *   Node that is the first leaf in a logical block has following content (and cursor):' |aaa'
   *   Node that is the first leaf in a logical block has following content (and selection):'[ ]aaa'
   *   Node that is the first leaf in a logical block has following content (and selection):'[ ]aaa'
   *   Node that is the first leaf in a logical block has following content (and selection):'[ a]aa'
   *   Node that is the first leaf in a logical block has following content (and selection):'[ a]aa'
   *   Node that is not the first leaf in a logical block has following content (and cursor):' |aaa'
   *   Node that is not the first leaf in a logical block has following content (and selection):'[ ]aaa'
   *   Node that is not the first leaf in a logical block has following content (and selection):'[ ]aaa'
   *   Node that is not the first leaf in a logical block has following content (and selection):'[ a]aa'
   *   Node that is not the first leaf in a logical block has following content (and selection):'[ a]aa'
   *
   * Test each situation above with
   *   pressing <del> (only interesting if situation contains a cursor)
   *   pressing <backspace> (only interesting if situation contains a cursor)
   *   inserting a character (only interesting if situation contains a selection)
   *   inserting a seperation character (only interesting if situation contains a selection)
   *   inserting text from clipboard (only interesting if situation contains a selection)
   */
  private correctUserDeletesSeperationCharacter(oldState: PmEditorState, newState: PmEditorState, additionalTransaction: PmTransaction): void {

    const newNode = newState.selection.$anchor.node();
    const newContent = newNode.textContent;

    const oldNode = oldState.selection.$anchor.node();
    const oldContent = oldNode.textContent;

    if (!isLeftmostTextblockOfLogicalBlock(newState.selection.$anchor, newNode)) {
      const start = newState.selection.$anchor.start();
      additionalTransaction.replaceWith(start, start, newState.schema.text(' '));

      // Check if the space was explizitly deleted (and not overriden by another character/content)
      if (oldContent.length === (newContent.length + 1)) {
        const pos = getMappedTransactionPosition(
          additionalTransaction,
          findNextTextblock(oldState.doc, oldState.selection.$anchor, SearchDirection.LEFT)
        );
        if (pos !== -1) {
          additionalTransaction.setSelection(TextSelection.create(additionalTransaction.doc, pos));
        }
      }
    }

    // remain in block if the whole Block was deleted
    additionalTransaction.setSelection(TextSelection.create(additionalTransaction.doc, newState.selection.head));

    const currentlyChangedNodeGuid = this.getAndKeepCurrentlyChangedNodeAttrs(oldState, newState, additionalTransaction)?.guid;
    this.logDebug('correctUserDeletesSeperationCharacter currentlyChangedNodeGuid:', currentlyChangedNodeGuid);
    EditorModule.addChange(currentlyChangedNodeGuid);
  }

  private stringStartsWithSeperationCharacter(str: string) {
    return /^[.!?,;\s]/.test(str);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private isUndoRedoTransaction(transaction: PmTransaction, state: PmEditorState) {
    if (transaction.getMeta(ProsemirrorTransactionMeta.PATENTENGINE_HISTORY_PLUGIN)) {
      return true;
    }
    return false;
  }


  private isAddMarkStep(step: PmStep): boolean {
    return step.toJSON().stepType === 'addMark';
  }

  private isRemoveMarkStep(step: PmStep): boolean {
    return step.toJSON().stepType === 'removeMark';
  }

  private trimNodeEdges(newNode: PmNode, newState: PmEditorState): PmNode[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const nodeArray: PmNode[] = newNode.content['content'];
    return this.trimRightMostNode(this.trimLeftMostNode(nodeArray, newState), newState);
  }

  private trimLeftMostNode(nodeArray: PmNode[], newState: PmEditorState): PmNode[] {
    const firstNode = nodeArray[0];
    if (firstNode?.isText) {
      const newNodeArray = nodeArray.slice(1, nodeArray.length);
      const leftTrimmedString = firstNode.textContent.trimLeft();
      if (leftTrimmedString.length > 0) {
        const newNode = newState.schema.text(leftTrimmedString);
        newNodeArray.unshift(newNode.mark(firstNode.marks));
      }
      return newNodeArray;
    }
    return nodeArray;
  }

  private trimRightMostNode(nodeArray: PmNode[], newState: PmEditorState): PmNode[] {
    const lastNode = nodeArray[nodeArray.length - 1];
    if (lastNode?.isText) {
      const newNodeArray = nodeArray.slice(0, nodeArray.length - 1);
      const rightTrimmedString = lastNode.textContent.trimRight();
      if (rightTrimmedString.length > 0) {
        const newNode = newState.schema.text(rightTrimmedString);
        newNodeArray.push(newNode.mark(lastNode.marks));
      }
      return newNodeArray;
    }
    return nodeArray;
  }

  /**
   * Keeps the attributes removed by prosemirror from the currently changed node when overriding its contents containing line
   * breaks in Google Chrome. This problem is not present in Firefox though.
   * Prosemirror happens to remove the attributes of the affected node and leaves default values { guid: "0", semanticType: "", ...}.
   * As a consequence wrong update operations are sent to the backend leaving the application in an unstable state.
   * @param oldState The old state within the transaction.
   * @param newState The new state within the transaction.
   * @param additionalTransaction The additional transaction containing the node to be updated.
   * @return The attributes to be kept.
   * @private
   */
  private getAndKeepCurrentlyChangedNodeAttrs(oldState: PmEditorState,
                                              newState: PmEditorState,
                                              additionalTransaction: PmTransaction): { [key: string]: any } | undefined {

    const newNode = newState.selection.$anchor.node();
    const oldNode = oldState.selection.$anchor.node();

    if (newNode?.attrs?.guid !== '0') {
      return newNode.attrs;
    } else if (oldNode?.attrs?.guid !== '0') {
      // Special case: we have to copy the attributes from oldNode to newNode
      const oldAttrs = oldNode.attrs;
      newState.doc.descendants((node: PmNode, pos: number) => {
        if (node.attrs.guid === '0') {
          // Keeps the attributes on nodes containing attribute guid set to '0'
          additionalTransaction.setNodeMarkup(pos, node.type, oldAttrs);
          // Avoids to descend any further
          return false;
        }
      });
      return oldAttrs;
    }
    return undefined;
  }
}

