import {Extension} from '@tiptap/vue-3';
import {EditorState} from '@tiptap/pm/state';
import {EditorView} from '@tiptap/pm/view';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';

/**
 * Command that creates a newline at the point where the anchor currently is, deleting selected text if necessary
 * @param state See https://prosemirror.net/docs/guide/#commands
 * @param view See https://prosemirror.net/docs/guide/#commands
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createNewlineAtAnchor = ({state, view}: { state: EditorState, view: EditorView }): boolean => {
  const anchor = state.selection.$anchor;
  const from = state.selection.from;
  const to = state.selection.to;
  const node = anchor.node(anchor.depth);
  if (node.isTextblock) {
    const hardBreak = state.schema.nodes.hardBreak;
    const tr = state.tr.replaceWith(from, to, hardBreak.create());
    if (view.dispatch) {
      tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'CreateNewlineAtAnchorExtension - createNewlineAtAnchor');
      view.dispatch(tr);
    }
  }
  return true;
}

/**
 * Command that inserts a newline in form of a <br> at the cursor position
 */
export const CreateNewlineAtAnchorExtension = Extension.create(
  {
    name: 'createNewlineAtAnchor',

    addKeyboardShortcuts() {
      return {
        'Enter': () => createNewlineAtAnchor(this.editor)
      };
    }
  });
