<template>
  <div class="applicationEditor"
       @mouseup="onDialogDragEnd"
       @copy="this.copyListener($event)"
       ref="applicationEditorRef">
    <div class="header">
      <Menubar v-if="computedActiveEditor !== null" :editor="computedActiveEditor" :applicationeditor="this"/>
    </div>

    <SpellcheckDictionaryManagement v-if="computedActiveEditor !== null"
                                    class="hover-button dialog-button"
                                    :showButton="false"
                                    :editor="computedActiveEditor"
                                    ref="spellcheckDictionaryManagementDialog"/>
    <ConfirmationDialog ref="deleteAllWordsFromIgnoreListDialog"
                        titleKey="spellcheckDictionaryManagement.delete.title"
                        questionKey="deleteReferenceSign.question"/>

    <MenuBubble :editor="activeEditor"
                v-if="activeEditor !== null"
                v-show="!hideMenuBubble && !isGenerateUnRedoLoading"
                :afterCommandExecution="this.cancelSaveOnBlur"/>

    <SpellcheckOverlay
      v-if="activeEditor !== null || true"
      :editor="activeEditor"
      :locale="locale"
      :appDocGuid="appDocGuid"
      ref="spellcheckOverlay"/>

    <AiAssistantDialog
      v-show="showAiDialog"
      :showing="showAiDialog"
      @dialogDragged="onDialogDragged($event)"
      @dialogDragStart="onDialogDragStart($event)"
      @dialogDragEnd="onDialogDragEnd"
      :dialog-postion="aiDialogPositionFunction"
      :editor="activeEditor"
      :locale="locale"
      :appDocGuid="appDocGuid"
      @close="onExitButtonClicked()"
      ref="aiAssistantDialog"/>

    <div class="document-editor-area" ref="document-editor-area">
      <ApplicationEditorView v-if="this.editorSplitMode === EditorSplitMode.ONE"
                             :is-active="true"
                             :show-as-active="false"
                             :editor="this.editorComputed"
                             :hide-menu-bubble="this.hideMenuBubble"
                             :locale="this.locale"
                             :app-doc-guid="this.appDocGuid"
                             :pos-of-current-block="this.posOfCurrentBlock"
                             @editorScrolled="this.onScroll"
                             @ready="onEditorViewReady"
                             @aiButtonClicked="onAiButtonClicked($event)"
                             :aiDialogActive="this.showAiDialog"
                             ref="editor1Ref"
      />
      <Splitpanes
        v-else-if="this.editorSplit === TwoDocumentEditorsSplitMode.SPLIT_HORIZONTALLY"
        :horizontal="true"
        @resized="onResized($event)"
      >
        <Pane :size="this.editorSplitRatio">
          <ApplicationEditorView v-if="this.editorComputed !== null"
                                 :editor="this.editorComputed"
                                 :is-active="this.computedActiveEditorName === this.editorComputed.editorName"
                                 :show-as-active="this.computedActiveEditorName === this.editorComputed.editorName"
                                 :hide-menu-bubble="this.hideMenuBubble"
                                 :locale="this.locale"
                                 :app-doc-guid="this.appDocGuid"
                                 :pos-of-current-block="this.posOfCurrentBlock"
                                 @editorScrolled="this.onScroll"
                                 @ready="onEditorViewReady"
                                 @aiButtonClicked="onAiButtonClicked($event)"
                                 :aiDialogActive="this.showAiDialog"
                                 ref="editor1Ref"
          />
        </Pane>
        <Pane :size="100.0 - this.editorSplitRatio">
          <ApplicationEditorView v-if="this.editor2Computed !== null"
                                 :editor="this.editor2Computed"
                                 :is-active="this.computedActiveEditorName === this.editor2Computed.editorName"
                                 :show-as-active="this.computedActiveEditorName === this.editor2Computed.editorName"
                                 :hide-menu-bubble="this.hideMenuBubble"
                                 :locale="this.locale"
                                 :app-doc-guid="this.appDocGuid"
                                 :pos-of-current-block="this.posOfCurrentBlock"
                                 @editorScrolled="this.onScroll"
                                 @ready="onEditorViewReady"
                                 @aiButtonClicked="onAiButtonClicked($event)"
                                 :aiDialogActive="this.showAiDialog"
                                 ref="editor2Ref"
          />
        </Pane>
      </Splitpanes>
      <Splitpanes
        v-else-if="this.editorSplit === TwoDocumentEditorsSplitMode.SPLIT_VERTICALLY"
        @resized="onResized($event)"
      >
        <Pane :size="this.editorSplitRatio">
          <ApplicationEditorView v-if="this.editorComputed !== null"
                                 :editor="this.editorComputed"
                                 :is-active="this.computedActiveEditorName === this.editorComputed.editorName"
                                 :show-as-active="this.computedActiveEditorName === this.editorComputed.editorName"
                                 :hide-menu-bubble="this.hideMenuBubble"
                                 :locale="this.locale"
                                 :app-doc-guid="this.appDocGuid"
                                 :pos-of-current-block="this.posOfCurrentBlock"
                                 @editorScrolled="this.onScroll"
                                 @ready="onEditorViewReady"
                                 @aiButtonClicked="onAiButtonClicked($event)"
                                 :aiDialogActive="this.showAiDialog"
                                 ref="editor1Ref"
          />
        </Pane>
        <Pane :size="100.0 - this.editorSplitRatio">
          <ApplicationEditorView v-if="this.editor2Computed !== null"
                                 :editor="this.editor2Computed"
                                 :is-active="this.computedActiveEditorName === this.editor2Computed.editorName"
                                 :show-as-active="this.computedActiveEditorName === this.editor2Computed.editorName"
                                 :hide-menu-bubble="this.hideMenuBubble"
                                 :locale="this.locale"
                                 :app-doc-guid="this.appDocGuid"
                                 :pos-of-current-block="this.posOfCurrentBlock"
                                 @editorScrolled="this.onScroll"
                                 @ready="onEditorViewReady"
                                 @aiButtonClicked="onAiButtonClicked($event)"
                                 :aiDialogActive="this.showAiDialog"
                                 ref="editor2Ref"
          />
        </Pane>
      </Splitpanes>
    </div>
  </div>
</template>

<script lang="ts">

import {Component, Emit, Provide, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import {Editor as CoreEditor} from '@tiptap/core';
import {AnyExtension, Editor, EditorContent, EditorOptions} from '@tiptap/vue-3';
import {EditorState, TextSelection, Transaction} from '@tiptap/pm/state';
import {DOMParser as PmDOMParser, DOMSerializer, Node as PmNode, Schema} from '@tiptap/pm/model';
import {EditorView} from '@tiptap/pm/view';
import {Bold} from '@tiptap/extension-bold';
import {HardBreak} from '@tiptap/extension-hard-break';
import {Italic} from '@tiptap/extension-italic';
import {Underline} from '@tiptap/extension-underline';
import {Paragraph} from '@tiptap/extension-paragraph';
import EditorModule from '@/store/modules/EditorModule';
import Menubar from '@/components/applicationEditor/menubar/Menubar.vue';
import {toProseMirrorXml} from '@/components/applicationEditor/utils/converter.util';
import {
  calcGuidOfLogicalBlock,
  calcSemanticTypeOfLogicalBlock,
  findChildNode,
  findFirstTextBlockInRange,
  findLastTextBlockInRange,
  findLowestDepth,
  findNodeAtPositionInState,
  findNodeByGuid,
  findNodeRangeOfGuid,
  findParentNode,
  getNodeRange
} from '@/components/applicationEditor/utils/node.util';
import {PlaceholderPlugin} from '@/components/applicationEditor/plugins/PlaceholderPlugin';
import {VisualizationExtension} from '@/components/applicationEditor/extensions/VisualizationExtension';
import {QuickTextblockNavigationExtension} from '@/components/applicationEditor/extensions/QuickTextblockNavigationExtension';
import {CreateNewlineAtAnchorExtension} from '@/components/applicationEditor/extensions/CreateNewlineAtAnchorExtension';
import {DeveloperDebugExtension} from '@/components/applicationEditor/extensions/DeveloperDebugExtension';
import {ReferenceSignMarkExtension} from '@/components/applicationEditor/extensions/ReferenceSignMarkExtension';
import {
  AbstractBlockViewModel,
  DocumentEditorSplitMode,
  DocumentUpdate,
  ModeAndSplit,
  NodeAttributeUpdate,
  NodeDeleteUpdate,
  NodeInsertUpdate,
  NodeReplaceUpdate,
  TwoDocumentEditorsSplitMode,
} from '@/api/models/editor.model';
import {BlockNodeDeletionPlugin} from '@/components/applicationEditor/plugins/BlockNodeDeletionPlugin';
import {PatentengineHistoryPlugin} from '@/components/applicationEditor/plugins/PatentengineHistoryPlugin';
import handleKeyPress from '@/components/applicationEditor/hooks/handleKeyPress';
import {handlePaste} from '@/components/applicationEditor/hooks/handlePaste';
import {handleCopy} from '@/components/applicationEditor/hooks/handleCopy';
import {BackspaceExtension} from '@/components/applicationEditor/extensions/BackspaceExtension';
import {MaxLengthExtension} from '@/components/applicationEditor/extensions/MaxLengthExtension';
import {CoverSheetNode} from '@/components/applicationEditor/nodes/coversheet/nodes/CoverSheetNode';
import {TextBlockNode} from '@/components/applicationEditor/nodes/baseElements/nodes/TextBlockNode';
import {TableEntryNode} from '@/components/applicationEditor/nodes/baseElements/nodes/TableEntryNode';
import {PlaceholderNode} from '@/components/applicationEditor/nodes/baseElements/nodes/PlaceholderNode';
import {ApplicationDocumentNode} from '@/components/applicationEditor/nodes/applicationDocument/nodes/ApplicationDocumentNode';
import {determineDocStartPos, findNextTextblock, SearchDirection} from '@/components/applicationEditor/utils/prosemirror.util';
import {UnsavedBlockVisualizationExtension} from '@/components/applicationEditor/extensions/UnsavedBlockVisualizationExtension';
import {SpellcheckDictionaryWord, SpellcheckIgnoreWord} from '@/api/models/spellcheck.model';
import SpellcheckModule from '@/store/modules/SpellcheckModule';
import {performSpellcheck} from '@/components/applicationEditor/utils/spellcheck.util';
import SpellcheckOverlay, {SpellcheckOverlay as SpellcheckOverlayClass} from '@/components/applicationEditor/menubar/SpellcheckOverlay.vue';
import ApplicationModule from '@/store/modules/ApplicationModule';
import {ApplicationDocument} from '@/api/models/application.model';
import SpellcheckDictionaryManagement, {
  SpellcheckDictionaryManagement as SpellcheckDictionaryManagementClass
} from '@/components/SpellcheckDictionaryManagement.vue';
import ConfirmationDialog, {ConfirmationDialog as ConfirmationDialogClass} from '@/components/common/ConfirmationDialog.vue';
import {StructuralBlockNode} from '@/components/applicationEditor/nodes/baseElements/nodes/StructuralBlockNode';
import BorderVisualizationCanvas from '@/components/BorderVisualizationCanvas.vue';
import {StructuralInlineBlockNode} from '@/components/applicationEditor/nodes/baseElements/nodes/StructuralInlineBlockNode';
import {NodeDepthPlugin} from '@/components/applicationEditor/plugins/NodeDepthPlugin';
import {LastBlockExtension} from '@/components/applicationEditor/extensions/LastBlockExtension';
import {SpellingMistake} from '@/store/models/spellcheck.model';
import {NonLogicalStructuralBlockNode} from '@/components/applicationEditor/nodes/baseElements/nodes/NonLogicalStructuralBlockNode';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';
import {registerApplicationEditorCopyListener} from '@/util/copy.util';
import {findNextTextblockPositionByCords} from '@/store/util/editor.util';
import ApplicationEditorView, {
  ApplicationEditorView as ApplicationEditorViewClass,
  EditorScrollEvent
} from '@/components/ApplicationEditorView.vue';
import {PatentengineSpellcheckPlugin} from '@/components/applicationEditor/plugins/PatentengineSpellcheckPlugin';
import {RootNode} from '@/components/applicationEditor/nodes/rootNode/nodes/RootNode';
import MenuBubble from '@/components/applicationEditor/menubar/MenuBubble.vue';
import {FilterTransactionPlugin} from '@/components/applicationEditor/plugins/FilterTransactionPlugin';
import UserProfileModule from '@/store/modules/UserProfileModule';
import {PasteExtension} from '@/components/applicationEditor/extensions/PasteExtension';
import {AddReferenceSignExtension} from '@/components/applicationEditor/extensions/AddReferenceSignExtension';
import {PatentEngineHistoryExtension} from '@/components/applicationEditor/extensions/PatentEngineHistoryExtension';
import {SearchAndReplaceExtension} from '@/components/applicationEditor/extensions/SearchAndReplaceExtension';
import {Text} from '@tiptap/extension-text';
import {nextTick} from 'vue';
import {syncEditors} from '@/util/prosemirror-tx-replication.util';
import {UpdateDocumentEditorSplitStateForTwoEditorsParam} from '@/api/services/userprofile.api';
import AuthModule from '@/store/modules/AuthModule';
import Splitpanes from '@/components/splitpane/Splitpanes.vue';
import Pane from '@/components/splitpane/Pane.vue';
import {useDefaultErrorHandling} from '@/errorHandling';
import AiAssistantDialog, {AiAssistantDialog as AiAssistantDialogClass} from '@/components/applicationEditor/menubar/AiAssistantDialog.vue';
import {ComputedPosition} from '@/components/types.util';
import {TopAndZoomLevelOfCurrentBlock} from '@/api/models/utility.model';

export class NamedEditor extends Editor {
  _editorName: string;

  constructor(name: string, options: Partial<EditorOptions> = {}) {
    super(options);
    this._editorName = name;
  }

  get editorName() {
    return this._editorName;
  }
}

const emptyDocument = {
  type: 'rootNode',
  topNode: true,
  content: [
    {
      type: 'applicationDocument',
      content: [
        {
          type: 'paragraph',
          content: []
        }
      ]
    },
  ]
};

export type SaveChangesFn = (keepLocalHistory: boolean) => Promise<void>;

@Component(
  {
    components: {
      AiAssistantDialog,
      Pane,
      Splitpanes,
      MenuBubble,
      ApplicationEditorView,
      SpellcheckOverlay,
      Menubar,
      BorderVisualizationCanvas,
      EditorContent,
      SpellcheckDictionaryManagement,
      ConfirmationDialog
    }
  })
class ApplicationEditor extends Vue {

  private showAiDialog = false;
  private debug = false;
  private serializer?: DOMSerializer;

  @Ref('spellcheckOverlay') private spellcheckOverlay!: SpellcheckOverlayClass | null;
  @Ref('spellcheckDictionaryManagementDialog') private spellcheckDictionaryManagementDialog!: SpellcheckDictionaryManagementClass;
  @Ref('deleteAllWordsFromIgnoreListDialog') private deleteAllWordsFromIgnoreListDialog!: ConfirmationDialogClass;
  @Ref('applicationEditorRef') private applicationEditor!: HTMLDivElement;
  private copyListener: ((event: ClipboardEvent) => void) | undefined;

  // Delay before checking if cursor moved after pressing arrow key. This can be 0 in Chrome, but Firefox needs some time.
  private checkCursorMovedOnKeyDownDelay = 60;

  @Ref("editor1Ref")
  private editor1Ref!: ApplicationEditorViewClass;
  private editor: null | NamedEditor = null;
  private editor1LastScrollPosition: { scrollTop: number; scrollLeft: number } = {scrollTop: 0, scrollLeft: 0};

  @Ref("editor2Ref")
  private editor2Ref!: ApplicationEditorViewClass;
  private editor2: null | NamedEditor = null;
  private editor2LastScrollPosition: { scrollTop: number; scrollLeft: number } = {scrollTop: 0, scrollLeft: 0};

  @Ref("aiAssistantDialog")
  private aiAssistantDialog!: AiAssistantDialogClass;

  @Ref("document-editor-area")
  private documentEditorArea!: HTMLElement;

  // TODO PENGINESUP-560 - make private as soon as other things are working
  public activeEditor: null | NamedEditor = null;

  private guidOfLastNodeSelected = ''; // Determines when the node content is saved
  private lastSelectedPos: {from: number, to: number } | null = null;

  // handler for save on blur timeout
  private saveOnBlurHandler: number | null = null;

  private locale = '';
  private appDocGuid = '';
  private navigationTimer: number | undefined;

  // Timer polls queue with scheduled spellcheck requests, see #handleScheduledSpellcheckRequests()
  private spellcheckTimer: number | undefined;

  private posOfCurrentBlock: TopAndZoomLevelOfCurrentBlock = {zoomLevel: 1.0, top: 0};
  private hideMenuBubble = false; // hide the MenuBubble when the scroll event is detected on ApplicationEditor.
  private aiDialogPosition: ComputedPosition | null = null;
  private aiDialogPositionOffset: ComputedPosition = {
    left: 0,
    top: 0
  };

  private dragging = false;
  private counterToForceUpdateOfComputedActiveEditorRef = 0;

  private onAiButtonClicked(event: HTMLDivElement) {
    if (this.aiDialogPosition === null) {
      this.aiDialogPosition = this.getAbsoluteScreenCoordinates(event);
    }
    this.showAiDialog = !this.showAiDialog;
  }

  private getAbsoluteScreenCoordinates(elem: HTMLDivElement): ComputedPosition {
    return {
      top: this.applicationEditor.scrollHeight / 2 - this.aiAssistantDialog.getDefaultSize.height / 2 + this.applicationEditor.offsetTop,
      left: this.applicationEditor.scrollWidth / 2 - this.aiAssistantDialog.getDefaultSize.width / 2 + this.applicationEditor.offsetLeft
    };
  }

  private onExitButtonClicked() {
    this.showAiDialog = false;
    this.$emit('showAiDialog', this.showAiDialog);
  }

  private onDialogDragged(difference: ComputedPosition) {
    if (this.dragging) {
      this.aiDialogPositionOffset = difference;
    }
  }

  private onDialogDragStart(dragging: boolean) {
    this.dragging = dragging
  }

  private onDialogDragEnd() {
    if (this.dragging && this.aiDialogPosition !== null) {
      this.aiDialogPosition = {
        left: this.aiDialogPosition.left + this.aiDialogPositionOffset.left,
        top: this.aiDialogPosition.top + this.aiDialogPositionOffset.top
      };
    }
    this.aiDialogPositionOffset = {left: 0, top: 0};
    this.dragging = false;
  }

  get aiDialogPositionFunction(): ComputedPosition {
    if (this.aiDialogPosition === null) {
      return {left: 0, top: 0};
    }
    let computedLeft = this.aiDialogPosition.left + this.aiDialogPositionOffset.left
    if (computedLeft < 0) {
      computedLeft = 0;
    }
    let computedTop = this.aiDialogPosition.top + this.aiDialogPositionOffset.top
    if (computedTop < 0) {
      computedTop = 0;
    }
    return {left: computedLeft, top: computedTop}
  }

  set aiDialogPositionFunction(newPosition: ComputedPosition) {
    this.aiDialogPosition = newPosition;
  }

  @Provide('applicationEditor.saveChanges')
  public saveChanges(keepLocalHistory: boolean): Promise<void> {

    /*
     * It would be better to save the changes in the store when they occure instead of saving the GUIDs of changed blocks.
     * This way, we would not have to call this function from various components but could handle everything in the store.
     * But since the complex handling of changes (with undo/redo) is already handled within prosemirror,
     * we do not want to duplicate this logik in our store. Therefore, we determine changes here according to changed blocks.
     */

    if (this.activeEditor === null) {
      return Promise.resolve();
    }

    const root = this.activeEditor.state.doc;

    const toHtml = this.prosemirrorToHTML;
    const guidsOfChangedNodes = EditorModule.guidsOfChangedNodes;
    const changes: Array<{ guid: string; content: string; semanticType: string; libraryReferences: Array<string> }> = [];
    guidsOfChangedNodes.forEach(function (guid) {
      let semanticType = "";
      const node = findChildNode(root, (innerNode: PmNode) => {
        semanticType = innerNode.attrs.semanticType;
        return innerNode && innerNode.attrs.guid === guid;
      })

      if (node) {
        changes.push({
                       guid: guid, content: toHtml(node), semanticType: semanticType,
                       libraryReferences: node.attrs.libraryReferences
                         ? node.attrs.libraryReferences.filter((guid: string) => guid != 'null')
                         : []
                     });
      }
    });
    if (changes.length > 0) {
      EditorModule.clearChanges();
      const shouldKeepLocalHistory = keepLocalHistory;
      return EditorModule.saveBlocks({changes: changes, keepLocalHistory: shouldKeepLocalHistory}).catch((err) => {
        if (this.activeEditor && EditorModule.guidsOfUnsavedNodes?.length > 0) {
          const guidOfLastUnsavedNode = EditorModule.guidsOfUnsavedNodes[EditorModule.guidsOfUnsavedNodes.length - 1];

          // this transaction is needed to trigger the reevaluation of the UnsavedBlockVisualizationExtension
          const transaction = this.activeEditor.state.tr?.setMeta(ProsemirrorTransactionMeta.UPDATE_BLOCK_FAILED, true);
          transaction.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'ApplicationEditor - saveChanges');
          this.activeEditor.view.dispatch(transaction);

          if (!this.activeEditor.isFocused) {
            this.scrollAndSelectTo(guidOfLastUnsavedNode)
          }
        }
      });

    } else {
      return Promise.resolve();
    }
  }

  @Provide('applicationEditor.activeEditorState')
  get activeEditorState(): EditorState {
    return this.computedActiveEditor!.state;
  }

  onEditorViewReady(event: ApplicationEditorViewClass) {
    nextTick(() => {
      const scrollOpts: ScrollToOptions = {top: this.editor1LastScrollPosition.scrollTop, behavior: 'auto'};
      event.scrollEditorContentTo(scrollOpts);
    })
  }

  get openSpellcheckDictionaryManagement(): boolean {
    return EditorModule.openSpellcheckDictionaryManagement;
  }

  get computedActiveEditor() {
    return this.activeEditor;
  }

  get computedActiveEditorName() {
    if (!this.computedActiveEditor) {
      return "";
    }
    return this.computedActiveEditor?.editorName;
  }

  get computedActiveEditorRef(): ApplicationEditorViewClass | null {
    if (this.computedActiveEditorName === this.editor?.editorName && this.counterToForceUpdateOfComputedActiveEditorRef >= 0) {
      return this.editor1Ref;
    } else if (this.computedActiveEditorName === this.editor2?._editorName && this.counterToForceUpdateOfComputedActiveEditorRef >= 0) {
      return this.editor2Ref;
    }
    return null;
  }

  get TwoDocumentEditorsSplitMode() {
    return TwoDocumentEditorsSplitMode;
  }

  get EditorSplitMode() {
    return DocumentEditorSplitMode;
  }

  /*
  *  In oder to force an update of the getter computedActiveEditorRef after the editorSplit variable changed,
  *  we need to introduce this helper counter, which is used in the getter computedActiveEditorRef to force its update
  * */
  @Watch('editorSplit', {immediate: true})
  watchOnComputedActiveEditorRef(): void {
    this.counterToForceUpdateOfComputedActiveEditorRef += 1;
  }

  @Watch('openSpellcheckDictionaryManagement', {immediate: true})
  private openSpellcheckDictionaryManagementChanged(openSpellcheckDictionaryManagement: boolean) {
    if (openSpellcheckDictionaryManagement) {
      this.spellcheckDictionaryManagementDialog.open();
      EditorModule.setOpenSpellcheckDictionaryManagement(false);
    }
  }

  private get editorComputed(): NamedEditor | null {
    return this.editor;
  }

  private get editor2Computed(): NamedEditor | null {
    return this.editor2;
  }

  private onResized(event: { size: number }[]): void {
    const splitMode = this.editorSplit;
    if (!splitMode) {
      return;
    }
    const ratio = event[0].size / 100.0;
    const updateRatio: UpdateDocumentEditorSplitStateForTwoEditorsParam = {
      userId: AuthModule.user!.id,
      splitState: {
        split: splitMode,
        ratio: ratio
      }
    }
    UserProfileModule.updateDocumentEditorSplitStateForTwoEditors(updateRatio).catch(useDefaultErrorHandling);
  }

  @Watch('triggerSaveCounter', {immediate: true})
  private triggerSaveChanged() {
    this.saveChanges(false).catch(useDefaultErrorHandling);
  }

  get triggerSaveCounter(): number {
    return EditorModule.triggerSaveCounter;
  }

  get isDocumentLoading(): boolean {
    return EditorModule.isCurrentApplicationDocumentLoading;
  }

  get currentApplicationDocument(): ApplicationDocument | null {
    return ApplicationModule.currentApplicationDocument;
  }

  get editorModeAndSplit(): ModeAndSplit {
    return UserProfileModule.modeAndSplit;
  }

  get editorSplitMode(): DocumentEditorSplitMode {
    return this.editorModeAndSplit.mode;
  }

  get editorSplit(): TwoDocumentEditorsSplitMode | undefined {
    return this.editorModeAndSplit.split;
  }

  get editorSplitRatio(): number {
    switch (this.editorSplit) {
      case undefined:
        return 100.0;
      case TwoDocumentEditorsSplitMode.SPLIT_HORIZONTALLY:
        return UserProfileModule.twoDocumentEditorSplitStateHorizontal.ratio * 100.0;
      case TwoDocumentEditorsSplitMode.SPLIT_VERTICALLY:
        return UserProfileModule.twoDocumentEditorSplitStateVertical.ratio * 100.0;
    }
    return 100.0;
  }

  @Watch('currentApplicationDocument', {immediate: true})
  private currentApplicationDocumentChanged(applicationDocument: ApplicationDocument | null) {
    if (!applicationDocument) {
      return;
    }
    this.locale = applicationDocument.locale;
    this.appDocGuid = applicationDocument.guid as string;

    // Load spellcheck ignored words and dictionary words
    SpellcheckModule.getWordsFromDictionary({locale: this.locale}).catch(useDefaultErrorHandling);
    SpellcheckModule.getWordsFromIgnoreList(this.appDocGuid).catch(useDefaultErrorHandling);
  }

  get spellingMistakes(): SpellingMistake[] {
    return SpellcheckModule.spellingMistakes;
  }

  // Listen to spellcheck results from the server to trigger the generation of the decorations
  @Watch('spellingMistakes')
  private onSpellingMistakesChanged(): void {
    if (!this.activeEditor) {
      // If there still are ongoing spellcheck request, don't refresh the decorations for now
      return;
    }

    const updateSpellcheckResults = (tr: Transaction) => {
      // Will be recognized in the PatentengineSpellcheckPlugin
      const newTr = tr.setMeta(ProsemirrorTransactionMeta.SPELLCHECK_RESULT, true);
      return newTr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'ApplicationEditor - onSpellingMistakesChanged');
    }

    // run after the editor has updated its state
    this.$nextTick(() => this.broadcastTransaction(this.allEditors, updateSpellcheckResults));

    // Tell the module we used the update
    SpellcheckModule.setSpellcheckUpdated(false);
  }

  get spellcheckBlockRequests(): string[] {
    return SpellcheckModule.spellcheckBlockRequests;
  }

  /**
   * Polls queue with scheduled spellcheck requests and might move entries into the request queue
   */
  private handleScheduledSpellcheckRequests(): void {
    SpellcheckModule.handleDelayedSpellcheckBlockRequests();
  }

  // Listen to the next logical block to check
  @Watch('spellcheckBlockRequests', {deep: true})
  private async onSpellcheckBlockRequestsChanged(): Promise<void> {
    if (!this.activeEditor || !SpellcheckModule.isActive) {
      return;
    }

    const logicalBlockGuid = await SpellcheckModule.popSpellcheckBlockRequest().catch(useDefaultErrorHandling);
    if (logicalBlockGuid) {
      performSpellcheck(this, this.activeEditor.state, logicalBlockGuid);
    }
  }

  get isSpellcheckActive(): boolean {
    return SpellcheckModule.isActive;
  }

  // Listen to toggling spellcheck
  @Watch('isSpellcheckActive')
  private onIsSpellcheckActiveChanged(isActive: boolean): void {
    if (!this.activeEditor) {
      return;
    }
    if (isActive) {
      SpellcheckModule.resetSpellcheckData(true);
      // Trigger that the whole document gets checked (again)
      const transaction = this.activeEditor.state.tr.setMeta(ProsemirrorTransactionMeta.UPDATE_FROM_BACKEND, [-1]);
      transaction.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'ApplicationEditor - onIsSpellcheckActiveChanged - isActive');
      this.activeEditor.view.dispatch(transaction);
    } else {
      // We must remove all the decorations. For this just trigger any transaction dispatch.
      // Then the PatentengineSpellcheckPlugin will take care.
      const transaction = this.activeEditor.state.tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE,
                                                             'ApplicationEditor - onIsSpellcheckActiveChanged - !isActive');
      this.activeEditor.view.dispatch(transaction);
    }
  }

  get newDictionaryWord(): SpellcheckDictionaryWord | null {
    return SpellcheckModule.newDictionaryWord;
  }

  // Listen to new words in the dictionary
  @Watch('newDictionaryWord')
  private newDictionaryWordChanged(newDictionaryWord: SpellcheckDictionaryWord | undefined): void {
    if (!this.activeEditor || !newDictionaryWord) {
      return;
    }
    // Remove matches with this word
    SpellcheckModule.removeMistakesForDictionaryWord(newDictionaryWord);

    const updateSpellcheckResults = (tr: Transaction) => {
      // Will be recognized in the PatentengineSpellcheckPlugin
      const newTr = tr.setMeta(ProsemirrorTransactionMeta.SPELLCHECK_RESULT, true);
      return newTr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'ApplicationEditor - newDictionaryWordChanged');
    }

    this.broadcastTransaction(this.allEditors, updateSpellcheckResults);
  }

  get newIgnoredWord(): SpellcheckIgnoreWord | null {
    return SpellcheckModule.newIgnoredWord;
  }

  // Listen to new words to ignore
  @Watch('newIgnoredWord')
  private newIgnoredWordChanged(newIgnoredWord: SpellcheckIgnoreWord | undefined): void {
    if (!this.activeEditor || !newIgnoredWord) {
      return;
    }
    // Remove matches with this word when they have the same type
    SpellcheckModule.removeMistakesForIgnoredWord(newIgnoredWord);
    const updateSpellcheckResults = (tr: Transaction) => {
      // Will be recognized in the PatentengineSpellcheckPlugin
      const newTr = tr.setMeta(ProsemirrorTransactionMeta.SPELLCHECK_RESULT, true);
      return newTr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'ApplicationEditor - newIgnoredWordChanged');
    }

    this.broadcastTransaction(this.allEditors, updateSpellcheckResults);
  }

  get deleteAllWordsFromIgnoreListCounter(): number {
    return SpellcheckModule.deleteAllWordsFromIgnoreListCounter;
  }

  // Listen for the command to delete all ignored words
  @Watch('deleteAllWordsFromIgnoreListCounter')
  private deleteAllWordsFromIgnoreListCounterChanged(deleteAllWordsFromIgnoreListCounter: number): void {

    const confirmKey = 'header.tab.compose.spellingAndGrammar.resetIgnoredWords.confirm.';
    this.deleteAllWordsFromIgnoreListDialog.open(
      {
        titleKey: confirmKey + 'title',
        questionKey: confirmKey + 'question',
        options: [
          {
            labelKey: confirmKey + 'button',
            class: 'button-delete',
            callback: () => SpellcheckModule.deleteAllWordsFromIgnoreList().catch(useDefaultErrorHandling),
            autofocus: true
          }, {
            labelKey: 'general.cancel',
            class: 'button-cancel'
          }]
      });
  }

  get isGenerateUnRedoLoading(): boolean {
    return EditorModule.isGenerateUnRedoLoading;
  }

  @Watch('isGenerateUnRedoLoading')
  private isGenerateUnRedoLoadingChanged(isGenerateUnRedoLoading: boolean): void {
    if (isGenerateUnRedoLoading) {
      // Hide spellcheck overlay
      this.spellcheckOverlay?.hide();
    }
    if (isGenerateUnRedoLoading && this.activeEditor?.isFocused) {
      this.lastSelectedPos = { from: this.activeEditor.state.selection.from, to: this.activeEditor.state.selection.to };
    }
    // Set editor to read only. The CSS class 'editor-read-only' is used to prevent most effects.
    this.activeEditor?.setOptions({editable: !isGenerateUnRedoLoading});

    if (!isGenerateUnRedoLoading && this.lastSelectedPos && EditorModule.selectionGuidForEditor) {
      const docSize = this.activeEditor?.state.doc.content.size || 0;

      // Ensure the position is within bounds
      if (this.lastSelectedPos.from >= 0 && this.lastSelectedPos.to <= docSize && this.activeEditor) {
        this.activeEditor.view.focus();
        const setSelectionTr = this.activeEditor.state.tr;
        setSelectionTr.setSelection(TextSelection.create(setSelectionTr.doc, this.lastSelectedPos.from, this.lastSelectedPos.to));
        this.activeEditor?.view.dispatch(setSelectionTr);
      }
    }
  }

  addPlugins(editor: NamedEditor, applicationDocumentGuid: string): NamedEditor {
    // order of plugins matters here as each plugin will receive all additional transactions created by the plugins that are coming first
    // in the plugin pipeline.
    // Recommended order for plugins is:
    // 1. FilterTransactionPlugin that will block all transactions containing steps that cant be replicated to the second editor when in
    //    in editor split mode.
    // 2. BlockNodeDeletionPlugin that ensures that the document is a valid state after a user input
    // 3. All plugins that are creating their own document modifying transactions and are depending on a valid document
    // 4. All plugins that are only updating their own state or issueing transactions that are not modifying the document (marker transactions)

    // 1.
    editor.registerPlugin(new FilterTransactionPlugin());

    // 2.
    editor.registerPlugin(new BlockNodeDeletionPlugin());

    // 3.
    editor.registerPlugin(new PlaceholderPlugin());
    editor.registerPlugin(new NodeDepthPlugin());

    // 4.
    editor.registerPlugin(new PatentengineHistoryPlugin({newGroupDelay: 500}, applicationDocumentGuid));
    const spellcheckPlugin = new PatentengineSpellcheckPlugin(editor.editorName, this.spellcheckOverlay!);
    editor.registerPlugin(spellcheckPlugin);

    return editor;
  }

  private get allEditors(): (NamedEditor | null)[] {
    return [this.editor, this.editor2];
  }

  private isOneEditorFocused() {
    return this.allEditors.some(it => it?.isFocused);
  }

  private makeNewExtensions(applicationEditor: ApplicationEditor): AnyExtension[] {
    return [
      // Nodes
      RootNode,
      ApplicationDocumentNode,

      // Nodes - coversheet
      CoverSheetNode,

      // Nodes - base nodes
      StructuralBlockNode,
      NonLogicalStructuralBlockNode,
      StructuralInlineBlockNode,
      TextBlockNode,
      TableEntryNode,
      PlaceholderNode,

      // Marks
      ReferenceSignMarkExtension,

      // Extensions
      VisualizationExtension(this.isOneEditorFocused),
      UnsavedBlockVisualizationExtension,
      HardBreak,
      BackspaceExtension,
      MaxLengthExtension,
      LastBlockExtension,
      Paragraph,
      Text,

      // Menubar
      Bold,
      Italic,
      Underline,
      PasteExtension,
      AddReferenceSignExtension,

      // Commands
      QuickTextblockNavigationExtension,
      CreateNewlineAtAnchorExtension,

      // History plugin
      PatentEngineHistoryExtension,

      // Search & Replace
      SearchAndReplaceExtension(applicationEditor, {
        disableRegex: false,
      }),

      // Commands for debuging
      DeveloperDebugExtension
    ];
  }

  makeEditorOptions(applicationEditor: ApplicationEditor): Partial<EditorOptions> {
    const options: Partial<EditorOptions> = {
      content: emptyDocument,
      extensions: this.makeNewExtensions(applicationEditor),
      editorProps: {
        handleKeyPress,
        handleKeyDown: this.onKeyDown,
        handleClick: this.onEditorClick,
        handleDOMEvents: {
          copy: handleCopy,
          paste: handlePaste,
        }
      },
      editable: true,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onTransaction: this.onTransaction
    }
    return options;
  }

  mounted(): void {
    // Get guid of application document
    const applicationDocumentGuid = this.$route.params.applicationGuid as string;

    this.editor = new NamedEditor("editor", this.makeEditorOptions(this));
    this.editor2 = new NamedEditor("editor2", this.makeEditorOptions(this));

    this.editor = this.addPlugins(this.editor, applicationDocumentGuid);
    this.editor2 = this.addPlugins(this.editor2, applicationDocumentGuid);

    this.activeEditor = this.editor;

    if (applicationDocumentGuid) {
      // If we have a guid - load the root block for the current application document
      EditorModule.loadDocument(applicationDocumentGuid);
    }

    // polls the queue every 100ms
    this.spellcheckTimer = setInterval(() => {
      this.handleScheduledSpellcheckRequests();
    }, 100);


    if (!this.copyListener) {
      this.copyListener = registerApplicationEditorCopyListener(this.editor);
    }
    this.updateReferenceSignShowing(EditorModule.showReferenceSignsInText);

    EditorModule.selectGuidForEditor(null);
    EditorModule.selectGuidForDocumentStructureTree(null)
  }

  unmounted(): void {
    if (this.copyListener) {
      document.removeEventListener('copy', this.copyListener);
      this.copyListener = undefined;
    }
    this.editor?.destroy();
    this.editor2?.destroy();
  }

  beforeUnmount(): void {
    if (this.spellcheckTimer) {
      clearInterval(this.spellcheckTimer);
    }
  }

  private broadcastTransaction(editors: (NamedEditor | null)[], transactionAcceptor: (tr: Transaction) => Transaction | null): void {
    for (const editor of editors) {
      if (!editor) {
        continue;
      }
      const tr = transactionAcceptor(editor.state.tr);
      if (tr) {
        editor.view.dispatch(tr);
      }
    }
  }

  /**
   * When the editor gets the focus, we must set the guid of the currently selected logical block in the store.
   * This notifies the tree which logical block is currently selected.
   */
  private onFocus({editor, event, transaction}: { editor: CoreEditor; event: FocusEvent; transaction: Transaction }) {

    const changedEditor = this.onActiveEditorChanged(editor);
    if (!changedEditor) {
      return;
    }

    if(this.activeEditor){
      this.lastSelectedPos = { from: this.activeEditor.state.selection.from, to: this.activeEditor.state.selection.to };
    }

    const guidSelectedLogicalBlock = calcGuidOfLogicalBlock(this.activeEditor!.state.doc, this.activeEditor!.state.selection.to);
    EditorModule.selectGuidForDocumentStructureTree(guidSelectedLogicalBlock);

    return false;
  }

  @Watch('editorSplit')
  private onSplitmodeChanged(newSplitMode: TwoDocumentEditorsSplitMode | undefined, oldSplitMode: TwoDocumentEditorsSplitMode | undefined) {
    this.log('EditSplit changed', newSplitMode, oldSplitMode);
    // if we change from horizontal or vertical split mode to a non text split, then we have to focus the first editor
    if (newSplitMode === undefined && this.editor !== null) {
      // split was removed, focus first editor
      this.onActiveEditorChanged(this.editor)
    }
  }

  private onActiveEditorChanged(editor: CoreEditor): boolean {
    if (this.activeEditor === null) {
      return false;
    }

    // Ugly but effective way to decide which editor is the active one
    if (this.editor!.view === editor.view) {
      this.activeEditor = this.editor;
    } else if (this.editor2!.view === editor.view) {
      this.activeEditor = this.editor2;
    }

    // Tell both editors that the active editor has changed
    const activeEditorName = this.activeEditor?.editorName
    const updateEditorName = (tr: Transaction) => {
      if (!activeEditorName) {
        return null;
      }
      return tr.setMeta(ProsemirrorTransactionMeta.UPDATE_ACTIVE_EDITOR, activeEditorName);
    }

    this.broadcastTransaction(this.allEditors, updateEditorName);
    return true;
  }

  private onBlur({editor}: { editor: CoreEditor }) {
    this.log('Blur');

    // Hide overlay when losing focus.
    this.spellcheckOverlay?.hideWithDelay();

    // Fix: If blur is triggered by disabling the editor, don't update selected guids.
    if (editor.options.editable) {
      EditorModule.selectGuidForDocumentStructureTree(null);
      EditorModule.selectGuidForEditor(null);
    }

    /*
     * Save on blur after a little timeout to give other components the chance to explicitly save changes (like the creation of a block)
     * or interrupt the saving if it is not intended (like formatting text using the bubble menu).
     * If a component has saved changes explicitly, there is nothing to save anymore when the timeout triggers.
     */
    this.saveOnBlurHandler = window.setTimeout(() => this.saveChanges(false).catch(useDefaultErrorHandling), 250);

    return false;
  }

  /**
   * Allows other components to cancel a save on blur if it is not intendet to do so (e.g. when formatting text using the bubble menu).
   */
  public cancelSaveOnBlur() {
    nextTick(() => {
      if (this.saveOnBlurHandler) {
        window.clearTimeout(this.saveOnBlurHandler);
        this.saveOnBlurHandler = null;
      }
    });
  }

  public paste(): void {

    if (!this.guidOfLastNodeSelected) {
      return;
    }

    this.scrollAndSelectTo(this.guidOfLastNodeSelected);

    setTimeout(() => {
      document.execCommand('paste');
    }, 3000);

  }

  @Watch('activeEditor.state.selection.to')
  private onSelectionToChanged(newValue: number): void {
    if (this.activeEditor === null || newValue === 0) {
      return;
    }

    this.log('onSelectionToChanged with newValue: ' + newValue)

    this.hideMenuBubble = false;

    const selectedNode = findNodeAtPositionInState(this.activeEditor.state, newValue);
    const guidSelectedNode = selectedNode.attrs.guid;

    // When node changed
    if (guidSelectedNode !== this.guidOfLastNodeSelected) {
      this.saveChanges(false).catch(useDefaultErrorHandling);

      this.guidOfLastNodeSelected = guidSelectedNode;
    }

    if (this.activeEditor.isFocused) {
      const guidSelectedLogicalBlock = calcGuidOfLogicalBlock(this.activeEditor.state.doc, newValue);
      EditorModule.selectGuidForDocumentStructureTree(guidSelectedLogicalBlock);
      EditorModule.selectGuidForDocumentStructureTree(guidSelectedLogicalBlock);
      EditorModule.selectGuidForEditorNoScroll(guidSelectedLogicalBlock);
      const semanticTypeOfLogicalBlock = calcSemanticTypeOfLogicalBlock(this.activeEditor!.state.doc,
                                                                        this.activeEditor!.state.selection.to);
      EditorModule.selectSemanticType(semanticTypeOfLogicalBlock);
    }
  }

  private onClickEditorContentContainerDown(event: any): void {
    if (!this.activeEditor || !this.detectBrowser.isFirefox || this.activeEditor.view.hasFocus()) {
      return;
    }

    const posAtCords = this.activeEditor.view.posAtCoords({left: event.clientX, top: event.clientY});
    if (!posAtCords) {
      return;
    }

    const resolvedPos = this.activeEditor.state.doc.resolve(posAtCords.pos);
    const resolvedNode = resolvedPos.node();
    const resolvedType = resolvedNode.type.name;
    const resolvedGuid = resolvedNode.attrs.guid;
    let targetPos = posAtCords.pos;
    this.log(`-----------------------------------------------------------------`);
    this.log(`Mouse Down on ${event.clientX}/${event.clientY} Pos: ${targetPos} Inside: ${posAtCords.inside}`);
    this.log(` - resolvedNode : `, resolvedNode);
    this.log(` - type / guid  :  ${resolvedType} / ${resolvedGuid}`);

    // compare the found situation with the settled selection
    setTimeout(() => {
      if (!this.activeEditor) {
        return;
      }
      const selectionPos = this.activeEditor.state.doc.resolve(this.activeEditor.state.selection.from);
      const selectionNode = selectionPos.node();
      const selectionType = selectionNode.type.name;
      const selectionGuid = selectionNode.attrs.guid;
      this.log(`Firefox selection after timeout:                           | selectionNode: `, selectionNode);
      this.log(` - type / guid  :  ${selectionType} / ${selectionGuid}`);

      if (selectionGuid === resolvedGuid) {
        // check ok! Nothing to do!
        this.log(` - check ok! Nothing to do!`);
        return;
      }
      // check FAILED! Fix required!
      this.log(` - check FAILED! Fix required!`);

      targetPos = findNextTextblockPositionByCords(this.activeEditor, event.clientX, event.clientY);
      this.log(`Firefox: - Moving form ${posAtCords.pos} to ${targetPos} `);
      if (targetPos > 0) {
        this.activeEditor.commands.focus(targetPos);
      }
    }, 20);
  }

  private log(message?: any, ...optionalParams: any[]) {
    if (this.debug) {
      console.log(message, ...optionalParams);
    }
  }

  get selectionGuidForEditor(): string | null {
    return EditorModule.selectionGuidForEditor;
  }

  @Watch('selectionGuidForEditor', {immediate: true})
  private scrollAndSelectTo(guid: string | null): void {
    this.log('SelectionGUID for editor changed', guid);
    if (guid === null) {
      return;
    }
    // Select node in next tick since text may change in current tick (which leads to an invalid selection)
    nextTick(() => this.selectNode(guid, EditorModule.scrollOnSelectionGuidForEditorChanged));
  }

  private selectNode(guid: string | null, scroll: boolean, targetEnd = false, lastPosition: { from: number; to: number } | null = null) {
    if (!(guid) || !(this.activeEditor)) {
      return;
    }
    const root = this.activeEditor.state.doc;
    const nodeRange = findNodeRangeOfGuid(root, guid);

    if (!nodeRange) {
      return;
    }
    const node = findNodeByGuid(root, guid);

    if (node) {
      const domNode = this.activeEditor.view.domAtPos(nodeRange.start + 1).node as HTMLElement;
      this.posOfCurrentBlock = {zoomLevel: EditorModule.zoomLevel, top: domNode.getBoundingClientRect().top};

      if (scroll) {
        let targetPos;
        if (lastPosition) {
          targetPos = lastPosition;
        } else if (node.isTextblock) {
          const blockEnd = nodeRange.end - 1;
          targetPos = { from: blockEnd, to: blockEnd };
        } else {
          const textBlockPosition = targetEnd
            ? findLastTextBlockInRange(root, nodeRange)
            : findFirstTextBlockInRange(root, nodeRange);
          const textPos = textBlockPosition ? textBlockPosition : nodeRange.start + 1;
          targetPos = { from: textPos, to: textPos };
        }
        this.activeEditor.view.focus();
        const tr = this.activeEditor.state.tr;
        tr.setSelection(TextSelection.create(tr.doc, targetPos.from, targetPos.to));
        this.activeEditor.view.dispatch(tr);
      }
    }

    if (scroll) {
      // Because we have nodes that may be hidden until selected, we have to wait a little bit for the node to become unhidden
      window.setTimeout(() => this.scrollToNode(guid), 100);
    }
  }

  private scrollToNode(guid: string | null) {
    if (!(guid) || !(this.activeEditor)) {
      return;
    }

    const applicationEditorDomElement = document.getElementById(guid);
    const view = this.computedActiveEditorRef;

    if (applicationEditorDomElement && view) {
      const headerOffset = 62;
      const subheaderOffset = 50;
      const marginTop = 40; // The offset was chosen so that the menu bubble fits over the scrolled position without being cut off
      let offsetTop = applicationEditorDomElement.offsetTop + headerOffset + subheaderOffset - marginTop;
      offsetTop *= EditorModule.zoomLevel; // scale offset
      const scrollOpts: ScrollToOptions = {top: offsetTop, behavior: 'smooth'};
      view.scrollEditorContentTo(scrollOpts);
    }
  }


  get reload(): number {
    return EditorModule.reloadCount;
  }

  @Watch('reload', {immediate: true})
  private onRootBlockChange(): void {
    if (this.activeEditor === null) {
      return;
    }

    this.log('onRootBlockChange')

    // If meanwhile the frontEnd received new changes, save the changes first in the backEnd
    if (EditorModule.guidsOfChangedNodes.length > 0) {
      this.saveChanges(false).catch(useDefaultErrorHandling);
      return;
    }

    const newNode = this.convertBlockViewModelToPmNode(EditorModule.currentRootBlock, this.activeEditor!.schema);
    if (!newNode) {
      return;
    }

    // Reset spellcheck data
    SpellcheckModule.resetSpellcheckData();

    const rootNode = this.activeEditor!.state.doc;

    const rootNodePosition = rootNode.resolve(0);
    const rootStart = rootNodePosition.start();
    const rootEnd = rootNodePosition.end();
    const emptySelection = TextSelection.create(rootNode, 0);

    const transaction = this.activeEditor!.state.tr
      .setSelection(emptySelection) // Avoid that the tree opens its last node after the editor gets initally the focus
      .replaceWith(rootStart, rootEnd, newNode) // Replace the whole content with the new node
      .setMeta(ProsemirrorTransactionMeta.UPDATE_FROM_BACKEND, [-1])
      .setMeta(ProsemirrorTransactionMeta.INITIAL_STATE, true);
    transaction.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'ApplicationEditor - onRootBlockChange');

    this.activeEditor!.view.dispatch(transaction);

    EditorModule.resetUpdatedNodes();
  }

  get updateFromBackend(): number {
    return EditorModule.updateCount;
  }

  get keepLocalHistory(): boolean {
    return EditorModule.keepLocalHistory;
  }

  get documentUpdate(): DocumentUpdate[] {
    return EditorModule.documentUpdate;
  }

  @Watch('updateFromBackend', {immediate: true})
  private onDocumentUpdate(): void {
    if (this.activeEditor === null) {
      return;
    }
    this.log('onDocumentUpdate()', this.activeEditor.editorName);

    this.lastSelectedPos = { from: this.activeEditor.state.selection.from, to: this.activeEditor.state.selection.to };

    // Remember Selection
    const transaction = this.activeEditor.state.tr;
    const hasRootReplace = this.documentUpdate.some(it => it.nodeReplaceUpdate.some(it => it.block.guid ===
      EditorModule.currentRootBlock!.guid));

    // Keep track of all positions where something was changed
    const updatePositions: number[] = [];

    // Fix: handle root replacements of bulk-undoes inserting duplicate blocks
    if (hasRootReplace) {
      this.replaceNode(transaction, EditorModule.currentRootBlock!);
    } else {
      this.documentUpdate.forEach(documentUpdate => {
        documentUpdate.nodeDeleteUpdate.forEach(
          (nodeDeleteUpdate: NodeDeleteUpdate) => updatePositions.push(
            this.removeNode(transaction, nodeDeleteUpdate.guid, nodeDeleteUpdate.parentGuid, this.activeEditor!.state.schema!)));

        documentUpdate.nodeInsertUpdate.forEach(
          (node: NodeInsertUpdate) => updatePositions.push(
            this.insertNode(transaction, node.parentGuid, node.positionAsChild, node.block)));

        documentUpdate.nodeReplaceUpdate.forEach(
          (nodeReplaceUpdate: NodeReplaceUpdate) => updatePositions.push(
            this.replaceNode(transaction, nodeReplaceUpdate.block)));

        documentUpdate.nodeAttributeUpdate.forEach(
          (nodeAttributeUpdate: NodeAttributeUpdate) => this.replaceNode(transaction, nodeAttributeUpdate.block));
      });
    }

    transaction
      .setMeta(ProsemirrorTransactionMeta.UPDATE_FROM_BACKEND, updatePositions.filter((pos: number) => pos > 1))
      .setMeta(ProsemirrorTransactionMeta.KEEP_LOCAL_HISTORY, this.keepLocalHistory);

    this.lastSelectedPos = {
      from: transaction.mapping.map(this.lastSelectedPos.from),
      to: transaction.mapping.map(this.lastSelectedPos.to)
    };

    EditorModule.clearPendingBlockUpdates();

    // Reset after single consumption
    EditorModule.resetUpdatedNodes();
    EditorModule.resetKeepLocalHistory();

    // Selection handling
    // FIXME mbi: move this logic to module?
    const createdGuid = EditorModule.createdGuidForEditor;
    const deletedGuid = EditorModule.deletedGuidForEditor;
    const inlinedGuid = EditorModule.inlinedGuidForEditor;

    if (createdGuid) {
      this.log('created node');

      // Must be done in the next tick, as the DOM must be updated first.
      nextTick(() => this.selectNode(createdGuid, true));
      EditorModule.selectCreatedGuidForEditor(null); // Reset after single consumption
    } else if (deletedGuid) {
      this.log('deleted node');

      // Must be done in the next tick, as the DOM must be updated first.
      nextTick(() => this.selectNode(deletedGuid, true, true));
      EditorModule.selectDeletedGuidForEditor(null); // Reset after single consumption
    } else if (inlinedGuid) {
      this.log('inline node');

      // Must be done in the next tick, as the DOM must be updated first.
      nextTick(() => this.selectNode(inlinedGuid, true, false, this.lastSelectedPos));
      EditorModule.selectInlinedGuidForEditor(null); // Reset after single consumption
    } else {
      transaction.setSelection(TextSelection.create(transaction.doc, this.lastSelectedPos.from, this.lastSelectedPos.to));
    }
    transaction.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'ApplicationEditor - onDocumentUpdate');
    this.activeEditor.view.dispatch(transaction);
  }

  private removeNode(transaction: Transaction, guid: string, parentGuid: string, schema: Schema): number {
    const nodeRange = findNodeRangeOfGuid(transaction.doc, guid);
    if (!nodeRange) {
      return -1;
    }
    const parentNodeRange = findNodeRangeOfGuid(transaction.doc, parentGuid);
    if (!parentNodeRange) {
      return -1;
    }
    transaction.delete(nodeRange.start, nodeRange.end);

    // Return the position where the node was deleted (-1: Nothing was deleted)
    return nodeRange.start;
  }

  private insertNode(transaction: Transaction, parentGuid: string, positionAsChild: number, block: AbstractBlockViewModel): number {
    if (this.activeEditor === null) {
      return -1;
    }
    const newNode: PmNode | null | undefined = this.convertBlockViewModelToPmNode(block, this.activeEditor.schema);

    if (!newNode) {
      return -1;
    }
    const nodeRange = findNodeRangeOfGuid(transaction.doc, parentGuid);
    if (!nodeRange) {
      return -1;
    }
    // Finde position where newNode should be inserted
    const parentNode = nodeRange.node;
    const startParentNode = nodeRange.start;
    const endParentNode = nodeRange.end;
    let insertPosition = endParentNode - 1;
    if (parentNode.childCount > positionAsChild) {
      parentNode.forEach((node: PmNode, offset: number, index: number) => {
        if (index + 1 === positionAsChild) {
          insertPosition = startParentNode + offset + node.nodeSize + 1;
        }
      });
    }
    transaction.insert(insertPosition, newNode);

    // Return the position where the new node was inserted (-1: Nothing was inserted)
    return insertPosition;
  }

  private replaceNode(transaction: Transaction, updatedBlock: AbstractBlockViewModel): number {
    if (this.activeEditor === null) {
      return -1;
    }
    const newNode = this.convertBlockViewModelToPmNode(updatedBlock, this.activeEditor.schema);
    if (!newNode) {
      return -1;
    }
    // Find range of previous node
    const rootNode = transaction.doc;
    const nodeRange = findNodeRangeOfGuid(rootNode, updatedBlock.guid);
    if (!nodeRange) {
      return -1;
    }
    const oldNodeStart = nodeRange.start;
    const oldNodeEnd = nodeRange.end;

    transaction.replaceWith(oldNodeStart, oldNodeEnd, newNode);

    // Return the position where the node was replaced (-1: Nothing was replaced)
    return oldNodeStart + 1;
  }

  private prosemirrorToHTML(node: PmNode): string {
    if (!this.activeEditor) {
      return '';
    }
    if (!this.serializer) {
      this.serializer = DOMSerializer.fromSchema(this.activeEditor.schema);
    }
    const div = document.createElement('div');
    const fragment = this.serializer?.serializeFragment(node.content);
    div.appendChild(fragment);

    // Replace <br> with <br /> for document export
    return div['innerHTML'].replaceAll('<br>', '<br />');
  }

  private convertBlockViewModelToPmNode(updatedBlock: AbstractBlockViewModel | null, schema: Schema): PmNode | null | undefined {
    if (!updatedBlock) {
      return undefined;
    }
    const prosemirrorXml = toProseMirrorXml(updatedBlock);
    this.log("convertBlockViewModelToPmNode -> input ViewModel : ", updatedBlock);
    this.log("convertBlockViewModelToPmNode -> proseMirrorXml  : ", prosemirrorXml);
    const parser = new DOMParser();
    const element = parser.parseFromString(prosemirrorXml, 'text/html').body;
    if (element) {
      const parser = PmDOMParser.fromSchema(schema);
      const slice = parser.parseSlice(element, {preserveWhitespace: 'full'});
      this.log("convertBlockViewModelToPmNode -> proseMirror Node : ", slice.content.firstChild);
      return slice.content.firstChild;
    }
    return undefined;
  }

  /**
   * This method is called whenever the user scrolls on ApplicationEditor.
   */
  private onScroll(event: EditorScrollEvent) {
    if (event) {
      // Hide when scrolling because spellcheck overlay could be positioned wrong.
      this.spellcheckOverlay?.hide();
      this.hideMenuBubble = true;

      // Save last scroll positions in case we need to restore them.
      if (event.applicationEditor === this.editor1Ref) {
        this.editor1LastScrollPosition = {scrollTop: event.scrollTop, scrollLeft: event.scrollLeft};
      } else if (event.applicationEditor === this.editor2Ref) {
        this.editor2LastScrollPosition = {scrollTop: event.scrollTop, scrollLeft: event.scrollLeft};
      }

      const scrollEvent: EditorScrollEvent = {
        applicationEditor: this.editor1Ref,
        scrollTop: event.scrollTop,
        scrollLeft: event.scrollLeft
      };

      this.emitMainEditorScrolled(scrollEvent);
    }
  }

  get zoomLevel() {
    return EditorModule.zoomLevel;
  }

  @Watch("zoomLevel")
  zoomLevelUpdated(zoomLevel: number) {
    this.hideMenuBubble = true;
  }

  get activeEditorScroll(): any {
    if (this.computedActiveEditorName === this.editor?.editorName) {
      return this.editor1LastScrollPosition;
    } else if (this.computedActiveEditorName === this.editor2?.editorName) {
      return this.editor2LastScrollPosition;
    }
    return null;
  }

  @Emit('mainEditorScrolled')
  emitMainEditorScrolled(scrollEvent: EditorScrollEvent) {
    return scrollEvent;
  }

  private onTransaction({editor, transaction}: { editor: CoreEditor, transaction: Transaction }) {
    // Hide overlay after a block selection because we don't want to see both spellcheck overlay and formatting menu.
    if (!editor.state.selection.empty) {
      this.spellcheckOverlay?.hide();
    }
    let targetEditor: NamedEditor | null;
    if (this.activeEditor === this.editor) {
      targetEditor = this.editor2;
    } else {
      targetEditor = this.editor;
    }
    const succesfullyReplicated = syncEditors(this.activeEditor!.view, targetEditor!.view, transaction);
    if (!succesfullyReplicated) {
      throw Error('unable to replicate transactions between editors');
    }
  }

  private onKeyDown(view: EditorView, event: KeyboardEvent): boolean {
    // Hide overlay for every key stroke (cursor or input) because the overlay could distract users.
    this.spellcheckOverlay?.hide();

    // Help Safari navigate the caret
    if (this.detectBrowser.isSafari && (event.code === "ArrowRight" || event.code === "ArrowLeft")) {
      return this.fixJumpingCaretSafari(view, event);
    }

    // Check if arrow keys were pressed but the cursor didn't move
    if (event.code === 'ArrowUp' || event.code === 'ArrowRight' || event.code === 'ArrowDown' || event.code === 'ArrowLeft') {
      this.handleArrowKeys(view, event);
    }

    // Reference Signs in Firefox
    if (this.detectBrowser.isFirefox && event.code === 'Backspace') {
      this.handleRefSignFirefox(view, event);
    }

    // Inline blocks, prevent deletion of wrapper span
    if (event.code === 'Backspace' || event.code === 'Delete') {
      return this.handleDeletionsInlineBlocks(view, event.code === 'Backspace');
    }
    // Do not add any handling beyond the method above. Due to its return any following statements would be ignored!
    return false;
  }

  private handleRefSignFirefox(view: EditorView, event: KeyboardEvent) {
    const posPrev = view.state.selection.head;
    setTimeout(() => {
      const pos = view.state.selection.head;
      if (posPrev > pos) {
        // This will prevent reaching the position directly after the RefSign if:
        // Entering from the right side going left
        // Entering via up and down keys
        const node: any = (view as any).docView.domFromPos(pos)?.node;
        if (node) {
          const previousElementSibling = this.findPreviousElementSibling(node);
          if (previousElementSibling?.dataset?.refsignFormat && view.posAtDOM(node, 0) === pos) {
            const hasSelection = view.state.selection.from != view.state.selection.to;
            this.setSelectionBeforeRefSign(hasSelection, view, pos);
          }
        }
      }
    }, this.checkCursorMovedOnKeyDownDelay);
  }

  /**
   * This method handles 'Delete' and 'Backspace'-Events, if they occur inside Inline-Blocks.
   * There are situations in which the Wrapper-Span around the inline-block would be destroyed, i.e. the first or last element is deleted
   * This handling affects firefox, as well as chrome and any chromium based browser as they share the same carret bahaviour
   * @param backspace true, if 'backspace'-Event; false, if 'delete'-Event
   */
  private handleDeletionsInlineBlocks(view: EditorView, backspace: boolean): boolean {
    // fix backspace behavior for inline-blocks
    const position = view.state.selection.from;
    // Can only get currentNode if not at the last position of a block
    let offset = 0;
    if (backspace) {
      offset = 1;
    }
    const currentNode = view.state.doc.nodeAt(position - offset);
    if (currentNode?.isInline) {
      const beginOfBlock = getNodeRange(view.state.doc.resolve(position),
                                        findLowestDepth(view.state.doc.resolve(position), (node => node.type.name === 'textBlockNode')),
                                        currentNode);
      const isStructuralInlineBlock = findParentNode(view.state.selection.$from,
                                                     node => node.type.name === 'structuralInlineBlockNode');
      if (!beginOfBlock || !isStructuralInlineBlock) {
        // Early Exit
        return false;
      }
      if (this.detectBrowser.isChrome || this.detectBrowser.isEdge || this.detectBrowser.isSafari) {
        const atFront = position - beginOfBlock?.start < 3;
        const atBack = position >= beginOfBlock.end;
        if (backspace && (atFront || atBack)) {
          // Execute Deletion
          const deletion = view.state.tr.delete(view.state.selection.from - 1, view.state.selection.from);
          view.dispatch(deletion);
          const posOfBlockBefore = findNextTextblock(view.state.doc, view.state.selection.$anchor, SearchDirection.LEFT);
          // Move Carret
          if (posOfBlockBefore !== -1 && atFront) {
            const transaction = view.state.tr.setSelection(TextSelection.create(view.state.doc, posOfBlockBefore, posOfBlockBefore));
            view.dispatch(transaction);
          }
          // Restrict any further handling
          return true;
        } else if (!backspace && (atBack || position === beginOfBlock.start + 1)) {
          // Execute Deletion
          const deletion = view.state.tr.delete(view.state.selection.from, view.state.selection.from + 1);
          view.dispatch(deletion);
          // Restrict any further handling
          return true;
        }
      }

      if (this.detectBrowser.isFirefox && !backspace) {
        if (beginOfBlock.start + 1 === beginOfBlock.end) {
          // Execute Deletion
          const deletion = view.state.tr.delete(view.state.selection.from, view.state.selection.from + 1);
          view.dispatch(deletion)
          // Restrict any further handling
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Fix for navigating inline blocks in Safari. Without this, the caret will navigate inside the surrounding span, resulting
   * in an increase of size and a change of location due to safari caret behaviour, looking quite distracting.
   * Therefore we check, if the caret is at the end/beginning of an inline-block and move it accordingly to the next/previous manually.
   * There are cases in which it isn't possible to determine, in which block we currently are, but still able to check if the coordinate
   * is inside an inline-block. In these situations we try a best effort strategy.
   */
  private fixJumpingCaretSafari(view: EditorView, event: KeyboardEvent): boolean {
    const isStructuralInlineBlock = findParentNode(view.state.selection.$from,
                                                   node => node.type.name === 'structuralInlineBlockNode');
    // Early Exit
    if (!isStructuralInlineBlock || !(event.code === "ArrowRight" || event.code === "ArrowLeft")) {
      return false;
    }

    const position = view.state.selection.from;
    const currentNode = view.state.doc.nodeAt(position - 1);
    const hasSelection = view.state.selection.from != view.state.selection.to;

    if (currentNode) {
      const beginOfBlock = getNodeRange(view.state.doc.resolve(position),
                                        findLowestDepth(view.state.doc.resolve(position), (node => node.type.name === 'textBlockNode')),
                                        currentNode);
      if (beginOfBlock) {
        const atBack = position >= beginOfBlock.end;
        const atFront = position - beginOfBlock.start < 3;
        if (event.code === "ArrowRight" && atBack) {
          const posOfBlockAfter = findNextTextblock(view.state.doc, view.state.selection.$anchor, SearchDirection.RIGHT);
          this.setSelection(hasSelection, view, posOfBlockAfter + 1);
          return true;
        }
        if (event.code === "ArrowLeft" && atFront) {
          const posOfBlockBefore = findNextTextblock(view.state.doc, view.state.selection.$anchor, SearchDirection.LEFT);
          this.setSelection(hasSelection, view, posOfBlockBefore + 1);
          return true;
        }
      }
    } else {
      // couldn't resolve the current text block. Using "best effort" strategy.
      const offset = (event.code === "ArrowLeft") ? -5 : 5;
      this.setSelection(hasSelection, view, position + offset);
      return true;
    }
    return false;
  }

  private handleArrowKeys(view: EditorView, event: KeyboardEvent) {
    // Remember the current caret position and wait a short time (for Chrome 0ms = 1frame is enough, Firefox needs longer)
    const posPrev = view.state.selection.head;
    if (this.navigationTimer) {
      clearTimeout(this.navigationTimer);
    }

    this.navigationTimer = setTimeout(() => {
      const pos = view.state.selection.head;
      const leftRightNavigation = (event.code === 'ArrowLeft' || event.code === 'ArrowRight');
      const upLeft = event.code === 'ArrowUp' || event.code === 'ArrowLeft';
      const hasSelection = view.state.selection.from != view.state.selection.to;
      const node: any = (view as any).docView.domFromPos(pos)?.node;
      let previousElementSibling;
      if (node) {
        previousElementSibling = this.findPreviousElementSibling(node);
      }

      // Not only check for equality, but also if the caret moved in the wrong direction (could happen via loops in inline blocks)
      if ((upLeft && posPrev <= pos) || (!upLeft && posPrev >= pos)) {

        // If we try to go up or left at the beginning of the document, there is nothing to do
        let docStartPos = -1;
        if (upLeft) {
          docStartPos = determineDocStartPos(view.state.doc);
          if (docStartPos === pos) {
            // Nothing to do at docStartPos
            return;
          }
        }

        // Special case in Firefox: Left and right of a reference sign number (in brackets) the prosemirror positions are exact the same,
        // so we try to determine if we reached such a position to deliver some special treatment.
        if (this.detectBrowser.isFirefox) {
          if (previousElementSibling?.dataset.refsignFormat) {
            // Entering from the left side going right
            // Prevent Firefox from reaching the position directly behind the RefSign by forcing it over the following whitespace
            // Check if previous Position was inside the refSign (since position did not change use -1)
            if (posPrev <= pos && this.isPosInsideRefSign(view, posPrev - 1)) {
              this.setSelection(hasSelection, view, pos + 1);
            }
            // No further modification needed
            return;
          }
        }

        // We need to remember if there was a selection to keep it (eg. when using the arrow keys while holding Shift)
        // Search for the next available text block that is editable (not "isReadOnly") to keep markup positions
        const resolvedPosPrev = view.state.doc.resolve(posPrev);
        if (!leftRightNavigation) {
          const posNew = findNextTextblock(view.state.doc, resolvedPosPrev, upLeft ? SearchDirection.LEFT :
            SearchDirection.RIGHT, !upLeft, true);
          if (posNew !== -1) {
            // If a position was found - set the selection
            this.setSelection(hasSelection, view, posNew);
          } else if (upLeft && docStartPos === resolvedPosPrev.start()) {
            // Special case for Firefox to prevent loops when an inline block is at the beginning of the document
            this.setSelection(hasSelection, view, docStartPos);
          }
        }
      }
      if (this.detectBrowser.isFirefox && posPrev != pos) {
        // This will prevent reaching the position directly after the RefSign if:
        // Entering from the right side going left
        // Entering via up and down keys
        if (previousElementSibling?.dataset?.refsignFormat && view.posAtDOM(node, 0) === pos) {
          // directly behind refSign or in above row
          if (posPrev < pos || event.code === 'ArrowLeft') {
            this.setSelectionBeforeRefSign(hasSelection, view, pos);
          } else {
            // entering from below
            this.setSelection(hasSelection, view, pos + 1);
          }
        }
      }
    }, this.checkCursorMovedOnKeyDownDelay);
  }

  private setSelection(hasSelection: boolean, view: EditorView, posNew: number) {
    this.log(`setSelection with hasSelection: ${hasSelection} posNew: ${posNew}`);
    const selection = hasSelection ? new TextSelection(view.state.selection.$anchor, view.state.doc.resolve(posNew))
      : new TextSelection(view.state.doc.resolve(posNew));
    const transaction = view.state.tr.setSelection(selection);
    transaction.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'ApplicationEditor - setSelection');
    view.dispatch(transaction);
  }

  private onEditorClick(view: EditorView, pos: number, /*event: MouseEvent*/): boolean {
    if (this.isGenerateUnRedoLoading) {
      return false;
    }
    // Correct Cursor positioning on click for Reference Signs
    if (this.detectBrowser.isFirefox) {
      setTimeout(() => {
        const node: any = (view as any).docView.domFromPos(pos)?.node;
        if (node) {
          const previousElementSibling = this.findPreviousElementSibling(node);
          const hasSelection = view.state.selection.from != view.state.selection.to;
          if (previousElementSibling?.dataset?.refsignFormat && view.posAtDOM(node, 0) === pos) {
            this.setSelectionBeforeRefSign(hasSelection, view, pos);
          }
        }
      }, this.checkCursorMovedOnKeyDownDelay);
    }

    // Show/hide overlay when clicking on an error or somewhere else.
    this.spellcheckOverlay?.toggleOverlayOnClick(view.state, pos);

    const guidSelectedLogicalBlock = calcGuidOfLogicalBlock(this.activeEditor!.state.doc, this.activeEditor!.state.selection.to);
    EditorModule.selectGuidForDocumentStructureTree(guidSelectedLogicalBlock);
    EditorModule.selectGuidForEditorNoScroll(guidSelectedLogicalBlock);
    const semanticTypeOfLogicalBlock = calcSemanticTypeOfLogicalBlock(this.activeEditor!.state.doc, this.activeEditor!.state.selection.to);
    EditorModule.selectSemanticType(semanticTypeOfLogicalBlock);

    return false;
  }

  private setSelectionBeforeRefSign(hasSelection: boolean, view: EditorView, posNew: number): void {
    // Cursor needs be set way earlier, otherwise it will not take the position in front of the refSign
    // Espacially occured for shift+arrowkey
    this.setSelection(hasSelection, view, posNew - 10);
    this.setSelection(hasSelection, view, posNew);
  }

  private findPreviousElementSibling(node: any): HTMLElement | undefined {
    // In the case we are deeper (eg. in a spellcheck word, bold text, ...) we have to go up first
    if (!node) {
      return;
    }
    let previousElementSibling = node?.previousElementSibling;
    while (!previousElementSibling) {
      node = node?.parentElement;
      if (!node) {
        break;
      }
      previousElementSibling = node.previousElementSibling;
    }
    return previousElementSibling;
  }

  private isPosInsideRefSign(view: EditorView, pos: number): boolean {
    //check if position is inside a RefSign Node
    let currentNode = (view as any).docView.domFromPos(pos - 1)?.node;
    while (currentNode?.parentNode) {
      if (currentNode.nodeName === "REFERENCE-SIGN") {
        return true;
      }
      if (currentNode.nodeName === "DIV") {
        return false;
      }
      currentNode = currentNode.parentNode;
    }
    return false;
  }

  get showReferenceSignsInText() {
    return EditorModule.showReferenceSignsInText;
  }

  @Watch('showReferenceSignsInText')
  private updateReferenceSignShowing(newValue: boolean): void {
    this.log('Toggleing reference signs', newValue);
    const applicationEditor = document.querySelectorAll('.applicationEditor');

    if (newValue) {
      applicationEditor.forEach(el => el.classList.add('reference-sign-theme-show'));
      applicationEditor.forEach(el => el.classList.remove('reference-sign-theme-hide'));
    } else {
      applicationEditor.forEach(el => el.classList.add('reference-sign-theme-hide'));
      applicationEditor.forEach(el => el.classList.remove('reference-sign-theme-show'));
    }
  }
}

export default toNative(ApplicationEditor);
export {ApplicationEditor};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/colors.scss';
@import 'src/assets/styles/constants.scss';

.applicationEditor {

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header {
    flex-basis: auto;
  }

  .document-editor-area {
    border-right: 1px solid $pengine-grey;

    width: 100%;

    display: flex;
    flex-basis: 100%;
    flex-direction: column;

    overflow: hidden;
  }
}


</style>
<style lang="scss">
@import 'src/assets/styles/constants.scss';

// For a read only mode on the whole document
.editor-read-only {

  .logo {
    img {
      opacity: 0.6;
    }
  }

  // Grayed out text color
  color: $editor-text-color-disabled !important;

  strong {
    color: $editor-text-color-disabled !important;
  }

  // Hide box frames
  div, .parent-of-logical-block, .sibling-of-logical-block, .current-logical-block {
    border-color: transparent !important;

    div {
      border-color: transparent !important;
    }
  }

  // Hide placeholders
  .show-placeholder {
    display: none !important;
  }

  // Hide spellchecks
  .spelling-error {
    --color: transparent !important;
  }

  // Hide even and odd (alternating) text block backgrounds
  .odd-descendant-of-logical-block, .even-descendant-of-logical-block {
    background-color: transparent !important;
  }
}
</style>
