<template>
  <div class="spellcheck-dictionary-management" id="spellcheck-dictionary-management">
    <modal-dialog ref="spellcheck-dictionary-dialog" :showHeader="true" :showFooter="false" :width="contextWidth" :margin="contextMargin">
      <template v-slot:header>
        <h1>{{ $t('spellcheckDictionaryManagement.title') }}</h1>
      </template>

      <template v-slot:body>

        <ConfirmationDialog ref="deleteSpellcheckDictionaryEntryDialog" titleKey="spellcheckDictionaryManagement.delete.title"
                            questionKey="deleteReferenceSign.question"/>

        <div class="description">{{ $t('spellcheckDictionaryManagement.description') }}</div>

        <b-table :data="dictionaryWords.concat(newDictionaryWords)" :mobile-cards="false"
                 :debounce-search="500" :sticky-header="true">

          <div class="language-select-row">
            <select id="locale" v-model="locale" :disabled="isLoading" ref="localeRef">
              <option v-for="language in availableLanguages"
                      :key="language"
                      :value="language">
                {{ $t('locales.' + language) }}
              </option>
            </select>
          </div>

          <div class="add-button-row">
            <button class="icon-button" :title="$t('spellcheckDictionaryManagement.createEntry')"
                    @click="clickCreateSpellcheckDictionaryWord()" :disabled="hasEmptyRow() || !allWordsAreValid || isLoading">
              <i :class="'exi exi-plus'"/>
            </button>
          </div>

          <b-table-column field="word" :label="$t('spellcheckDictionaryManagement.word')" header-class="table-header" cell-class="word-cell"
                          sortable v-slot="props">
            <b-input ref="lastInput" v-bind:class="{invalid: wordIsValid[props.row.guid] === false}" class="label-input"
                     @focusout="save(props.row)"
                     v-model="props.row.word"
                     @keyup="isSpellcheckDictionaryWordValid(props.row, dictionaryWords)"
                     :maxlength="wordMaxLength"
                     :has-counter="false"
                     :disabled="(!allWordsAreValid && (wordIsValid[props.row.guid] != false))
                                || (hasUnsavedWord && !props.row.guid.startsWith('new')) || isLoading">
            </b-input>
          </b-table-column>

          <b-table-column header-class="table-header" cell-class="button-cell" width="54" v-slot="props">
            <div class="table-cell-padding">
              <button class="icon-button delete-button" :title="$t('general.delete')" @click="clickDelete(props.row)" :disabled="isLoading">
                <i class="exi exi-delete"/>
              </button>
            </div>
          </b-table-column>

          <template v-slot:empty>
            <div class="table-empty-text">
              <div v-if="isLoading" class="is-loading">
                <i class="exi exi-small-spinner-unmasked rotating"/> {{ $t('spellcheckDictionaryManagement.load') }}
              </div>
              <div v-if="!isLoading">{{ $t('spellcheckDictionaryManagement.empty') }}</div>
            </div>
          </template>

        </b-table>

      </template>
    </modal-dialog>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import ConfirmationDialog, {ConfirmationDialog as ConfirmationDialogClass} from '@/components/common/ConfirmationDialog.vue';
import {SearchResultDialog as SearchResultDialogClass} from '@/components/SearchResultDialog.vue';
import {PatentEngineConstraints} from '@/constraints';
import ModalDialog, {ModalDialog as ModalDialogClass} from '@/components/common/ModalDialog.vue';
import {SpellcheckDictionaryWord} from '@/api/models/spellcheck.model';
import SpellcheckModule from '@/store/modules/SpellcheckModule';
import ApplicationTemplateModule from '@/store/modules/ApplicationTemplateModule';
import {Editor} from '@tiptap/vue-3';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';
import {useDefaultErrorHandling} from '@/errorHandling';

@Component(
  {
    name: 'spellcheck-dictionary-management',
    components: {
      ModalDialog,
      ConfirmationDialog
    }
  })
class SpellcheckDictionaryManagement extends Vue {
  @Prop() editor!: Editor;
  @Ref('spellcheck-dictionary-dialog') private dialog!: ModalDialogClass;
  @Ref('taginput') private taginput!: HTMLElement;
  @Ref('localeRef') private localeRef!: HTMLElement;
  @Ref('lastInput') private lastInput!: HTMLElement;
  @Ref('createEditEntityDialog') private createEditEntityDialog!: SearchResultDialogClass;
  @Ref('deleteSpellcheckDictionaryEntryDialog') private deleteSpellcheckDictionaryEntryDialog!: ConfirmationDialogClass;

  contextWidth: number | undefined = 600;
  contextMargin: string | undefined = 'auto auto auto auto';

  private wordMaxLength = PatentEngineConstraints.FIELD_LENGTH_SPELLCHECK_WORD;
  private wordIsValid: { [guid: string]: boolean } = {};
  private allWordsAreValid = true;
  private hasUnsavedWord = false;
  private guidToWord: { [guid: string]: string } = {};
  private guidOfInvalidWord = '';

  private dictionaryWords: SpellcheckDictionaryWord[] = [];
  private newDictionaryWords: SpellcheckDictionaryWord[] = [];

  get dictionaryWordsForManagement(): SpellcheckDictionaryWord[] {
    return SpellcheckModule.dictionaryWordsForManagement;
  }

  @Watch('dictionaryWordsForManagement', {immediate: true})
  private dictionaryWordsForManagementChanged(dictionaryWordsForManagement: SpellcheckDictionaryWord[]): void {
    this.dictionaryWords = dictionaryWordsForManagement;
    // Just loaded words can be invalid but also got deleted
    if (this.guidOfInvalidWord) {
      if (!this.guidOfInvalidWord.startsWith('new')) {
        const filteredWords = this.dictionaryWords.filter((dictWord) => dictWord.guid === this.guidOfInvalidWord);
        // Check if persisted invalid word is gone
        if (!filteredWords.length) {
          delete this.wordIsValid[this.guidOfInvalidWord];
          this.guidOfInvalidWord = '';
          this.allWordsAreValid = true;
        } else {
          // if the ivalid word is still here we must check if it really still is invalid (maybe the duplicated word was deleted)
          const wordToCheck = filteredWords[0];
          this.isSpellcheckDictionaryWordValid(wordToCheck, this.dictionaryWords);
          // Now we must save the no longer invalid old word
          this.save(wordToCheck);
        }
      } else {
        // Maybe an old word was deleted and a "new" word is no longer invalid because of that
        const filteredWords = this.newDictionaryWords.filter((dictWord) => dictWord.guid === this.guidOfInvalidWord);
        if (filteredWords.length) {
          const wordToCheck = filteredWords[0];
          this.isSpellcheckDictionaryWordValid(wordToCheck, this.dictionaryWords);
          // Now we must save the no longer invalid new word
          this.save(wordToCheck);
        }
      }
    }
    this.trackGuidsToWords(this.dictionaryWords);
  }

  get newSpellcheckDictionaryWords(): SpellcheckDictionaryWord[] {
    return SpellcheckModule.temporaryDictionaryWords;
  }

  @Watch('newSpellcheckDictionaryWords', {immediate: true})
  private newSpellcheckDictionaryWordsChanged(newSpellcheckDictionaryWords: SpellcheckDictionaryWord[]): void {
    this.newDictionaryWords = newSpellcheckDictionaryWords;
    this.hasUnsavedWord = this.newDictionaryWords.length > 0;
    // Just loaded "new" words can be invalid but will be tested after loading before rendering.
    // But if they got deleted we would not recognize if there is no invalid word left.
    if (this.guidOfInvalidWord && this.guidOfInvalidWord.startsWith('new') && !this.newDictionaryWords
      .filter((dictWord) => dictWord.guid === this.guidOfInvalidWord).length) {
      delete this.wordIsValid[this.guidOfInvalidWord];
      this.guidOfInvalidWord = '';
      this.allWordsAreValid = true;
    }
    this.trackGuidsToWords(this.newDictionaryWords);
  }

  private hasEmptyRow() {
    if (!this.hasUnsavedWord) {
      return false;
    }
    for (const word of this.newDictionaryWords) {
      if (!word.word.trim().length) {
        return true;
      }
    }
    return false;
  }

  // Remember the word at the time of loading so we can determin if the words were changed
  private trackGuidsToWords(spellcheckDictionaryWords: SpellcheckDictionaryWord[]) {
    spellcheckDictionaryWords.forEach((spellcheckDictionaryWord: SpellcheckDictionaryWord) => {
      this.guidToWord[spellcheckDictionaryWord.guid] = spellcheckDictionaryWord.word;
    });
  }

  get reloadForManagement(): number {
    return SpellcheckModule.reloadForManagement;
  }

  @Watch('reloadForManagement', {immediate: true})
  private reloadForManagementChanged(reloadForManagement: number): void {
    if (!this.editor || reloadForManagement <= 0) {
      return;
    }

    // Reset spellcheck data
    SpellcheckModule.resetSpellcheckData(true);

    const transaction = this.editor.state.tr.setMeta(ProsemirrorTransactionMeta.UPDATE_FROM_BACKEND, [-1]);
    transaction.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'SpellcheckDictionaryManagement - reloadForManagementChanged');
    this.editor.view.dispatch(transaction);
  }

  get isLoading(): boolean {
    return SpellcheckModule.isLoadingForManagement;
  }

  get locale(): string | null {
    return SpellcheckModule.localeForManagement;
  }

  set locale(locale: string | null) {
    if (locale) {
      this.setLocale(locale);
    }
  }

  get availableLanguages(): string[] {
    const applicationTemplates = ApplicationTemplateModule.applicationTemplates;
    let availableLanguages: string[] = applicationTemplates.filter(template => template.locale).map(template => template.locale);
    // Remove duplicates
    availableLanguages = Array.from(new Set(availableLanguages));
    // Set the first found language as default if we do not already have one
    if (this.locale == null && availableLanguages.length > 0) {
      this.setLocale(availableLanguages[0]);
    }
    return availableLanguages;
  }

  private setLocale(locale: string) {
    if (locale == null) {
      return;
    }
    SpellcheckModule.setLanguageForManagement(locale);
    this.fetchSpellcheckDictionaryWords();
  }

  private fetchSpellcheckDictionaryWords(): void {
    if (!this.locale) {
      return;
    }
    SpellcheckModule.getWordsFromDictionary({locale: this.locale, forManagement: true}).catch(useDefaultErrorHandling);
  }

  open(): void {
    // Load available languages
    ApplicationTemplateModule.fetchAllAplicationTemplate().catch(useDefaultErrorHandling);
    this.dialog.openModal();
    setTimeout(() => {
      this.localeRef.focus();
    }, 100);
  }

  private clickCreateSpellcheckDictionaryWord(): void {
    const guid = 'new' + Math.floor(Math.random() * 100000); // temporal random fake guid
    const language = this.locale;
    if (!language) {
      return;
    }

    // The add button should be disabled if there is an invalid word, but better check again
    if (this.guidOfInvalidWord) {
      return;
    }

    this.hasUnsavedWord = true; // Remember this to disable all words that are already persisted
    SpellcheckModule.addTemporaryDictionaryWord({guid, language, word: ''});

    // Focus and scroll to new entry
    setTimeout(() => {
      this.lastInput.focus();
    }, 100);
  }

  private isSpellcheckDictionaryWordValid(spellcheckDictionaryWord: SpellcheckDictionaryWord, allSpellcheckDictionaryWords:
    Array<SpellcheckDictionaryWord>): boolean {
    let isValid = true;
    const trimmedWord = spellcheckDictionaryWord.word?.trim();
    if (!trimmedWord || trimmedWord === '') {
      isValid = false;
    } else {
      // Check if other entries have the same word (ignoring case)
      for (let wordIndex = 0; wordIndex < allSpellcheckDictionaryWords.length; wordIndex++) {
        // Skip oneself
        if (allSpellcheckDictionaryWords[wordIndex].guid === spellcheckDictionaryWord.guid) {
          continue;
        }
        const otherTrimmedWord = allSpellcheckDictionaryWords[wordIndex].word?.trim();
        // Don't compare with empty fields
        if (!otherTrimmedWord || otherTrimmedWord === '') {
          continue;
        }
        // They are equal
        else if (trimmedWord.toLowerCase() === otherTrimmedWord.toLowerCase()) {
          isValid = false;
          this.guidOfInvalidWord = spellcheckDictionaryWord.guid;
          break;
        }
      }
    }

    // Assuming that only one word can be invalid at a time, we keep track of all words being valid or not
    if (isValid) {
      // If this word was the only invalid one, now all must be valid
      if (!this.wordIsValid[spellcheckDictionaryWord.guid]) {
        this.allWordsAreValid = true;
        this.guidOfInvalidWord = '';
      }
    } else {
      this.allWordsAreValid = false;
    }

    this.wordIsValid[spellcheckDictionaryWord.guid] = isValid;
    // Make copy to trigger render refresh
    this.wordIsValid = {...this.wordIsValid};
    return isValid;
  }

  private save(spellcheckDictionaryWord: SpellcheckDictionaryWord): void {
    const isNew = spellcheckDictionaryWord.guid.startsWith('new');
    if (isNew) {
      // If it's new it's enough to just check if it was already marked as invalid
      if (!this.wordIsValid[spellcheckDictionaryWord.guid]) {
        return;
      }
      // If it's an old word we must also trigger a new check onBlur / on focusout
    } else if (!this.isSpellcheckDictionaryWordValid(spellcheckDictionaryWord, this.dictionaryWords)) {
      return;
    }
    const trimmedWord = spellcheckDictionaryWord.word.trim();
    // If the word was not changed - no need to save or update it
    if (this.guidToWord[spellcheckDictionaryWord.guid] === trimmedWord) {
      return;
    }
    if (isNew) {
      // Create a new entry
      SpellcheckModule.addWordToDictionary({
                                             locale: spellcheckDictionaryWord.language, word: trimmedWord
                                           }).catch(useDefaultErrorHandling);
    } else {
      // Update an entry
      SpellcheckModule.updateWordInDictionary({guid: spellcheckDictionaryWord.guid, word: trimmedWord}).catch(useDefaultErrorHandling);
    }
  }

  private clickDelete(spellcheckDictionaryWord: SpellcheckDictionaryWord): void {
    // If this word wasn't persisted on the server yet, just remove it without asking
    if (spellcheckDictionaryWord.guid.startsWith('new')) {
      SpellcheckModule.removeTemporaryDictionaryWord(spellcheckDictionaryWord);
      this.allWordsAreValid = true; // This word may have been invalid, but no other words could have been invalid
      this.hasUnsavedWord = false; // Currently only one new word was allowed at a time
      return;
    }

    const originalWord = this.guidToWord[spellcheckDictionaryWord.guid];
    this.deleteSpellcheckDictionaryEntryDialog.open({
                                                      titleKey: 'spellcheckDictionaryManagement.delete.title',
                                                      titleValues: [originalWord], // Not in use
                                                      questionKey: 'spellcheckDictionaryManagement.delete.question',
                                                      questionValues: [originalWord],
                                                      options: [
                                                        {
                                                          labelKey: 'general.delete',
                                                          class: 'button-delete',
                                                          callback: () => SpellcheckModule
                                                            .deleteWordFromDictionary(spellcheckDictionaryWord.guid as string)
                                                            .catch(useDefaultErrorHandling),
                                                          autofocus: true
                                                        }, {
                                                          labelKey: 'general.cancel',
                                                          class: 'button-cancel'
                                                        }]
                                                    });
  }
}

export default toNative(SpellcheckDictionaryManagement);
export {SpellcheckDictionaryManagement};
</script>

<style lang="scss">
@import 'src/assets/styles/constants';

.spellcheck-dictionary-management {
  margin: 0px 20px 0px 20px;
  $header-height: 32px;
  $filter-row-height: 45px;
  $width: 540px;
  $maxHeight: 500px;
  min-width: $width;

  .modal-dialog {

    &-dialog {
      overflow-x: auto !important;
      margin-top: 20px !important;

      .description {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        text-align: left;
      }
    }

    &-header {
      // width: $dialog-width;

      h1 {
        margin: 0 auto;
      }

      .icon-button {
        margin-top: -12px;
        margin-right: -12px;
        padding: 6px !important;
      }
    }

    .b-table {
      width: $width;
      margin: 4px auto 0px;
      padding-bottom: 20px;

      .language-select-row {
        float: left;
        margin-top: -10px;
        margin-left: 1px;

        select {
          min-width: 86px;
          height: 30px;
          font-size: $font-size-normal;
          padding-left: 4px;

          &:enabled:hover, &:enabled:focus {
            padding-top: 2px !important;
            padding-left: 4px !important;
          }
        }
      }

      .add-button-row {
        float: right;
        margin-right: 14px;

        button:focus {
          background-color: $pengine-grey;
        }
      }

      button:disabled { // For add and delete buttons
        border: none;

        .exi {
          background-color: $text-color-disabled !important;;
        }
      }

      .delete-button:focus {
        .exi {
          background-color: red;

        }
      }

      .table-wrapper {
        position: inherit;
        min-width: $width;
        max-height: $maxHeight;

        &.has-sticky-header {
          height: inherit;
        }

        table {
          // Hack for Chrome and IE11 to make the clickable area 100% height.
          height: 1px;

          td {
            padding: 0px;
            text-align: left;

            input {
              margin-left: 4px;
              margin-right: 4px;
              margin-top: 3px;
              padding: 2px;
              height: 26px;
              width: calc(100% - 4px);
            }

            &.word-cell {
              height: 100%;
            }

            &.button-cell {
              text-align: right;

              .table-cell-padding {
                padding-top: 8px;
                padding-right: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
