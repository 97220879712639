import {Extension} from '@tiptap/vue-3';
import {EditorView} from '@tiptap/pm/view';
import {EditorState, Plugin, TextSelection} from '@tiptap/pm/state'
import EditorModule from '@/store/modules/EditorModule';
import {findNextTextblock, SearchDirection} from '@/components/applicationEditor/utils/prosemirror.util';

interface EditorStateBackspaceExtension extends EditorState {
  resetDeleting: number | undefined;
  deleting: boolean;
  filterTransactionCalled: boolean | undefined;
}

interface EditorViewBackspaceExtension extends EditorView {
  state: EditorStateBackspaceExtension;
}

/**
 * Extension to interrupt unwanted behaviour when the backspace key is pressed.
 * The specific problem solved by this extension was:
 * When the cursor was on the first position of a text block and backspace was pressed,
 * the parent node in the tree was selected that never should be selectable.
 * Pressing backspace again while the parent is selected then deleted multiple child blocks and
 * destroyed the document. (This only happened for some text blocks.)
 * Now it will move the cursor to the end of the block in front.
 */
export const BackspaceExtension = Extension.create(
  {
    name: 'backspaceExtension',

    addProseMirrorPlugins() {
      return [
        new Plugin(
          {
            props: {
              handleKeyDown: (_view: EditorView, event) => {
                const view: EditorViewBackspaceExtension = _view as EditorViewBackspaceExtension;
                // 8: Backspace, 46: Delete
                if (event.which === 8) {
                  if (view.state.resetDeleting) {
                    clearTimeout(view.state.resetDeleting);
                  }
                  view.state.deleting = true;
                  view.state.filterTransactionCalled = false;
                  view.state.resetDeleting = setTimeout(() => {
                    // Reset this flag, because in some cases it may be set but not used and this may block other keys.
                    view.state.deleting = false;
                    // If this variable is undefined it means pressing backspace didn't trigger the filterTransaction below
                    // therefore we must be at the beginning of a text block, because nothing could be removed.
                    if (view.state.filterTransactionCalled === false) {
                      const pos = findNextTextblock(view.state.doc, view.state.selection.$anchor, SearchDirection.LEFT);
                      // Check if a block was found
                      if (pos !== -1) {
                        const transaction = view.state.tr.setSelection(TextSelection.create(view.state.doc, pos, pos));
                        view.dispatch(transaction);
                      }
                    }
                  }, 50);

                  // Remove all search results on any text change (no idea why the delay is needed, but doesn't work without)
                  setTimeout(() => EditorModule.triggerClearSearch(), 1);
                }
                // The following could be used to jump right/down at the end of a text block by using the delete button
                // For now we decided to not use it and see what the customer wished for.
                /*else if (event.which === 46) {
                  view.state.filterTransactionCalled = false;
                  view.state.resetDeleting = setTimeout(() => {
                    // If this variable is undefined it means pressing delete didn't trigger the filterTransaction below
                    // therefore we must be at the end of a text block, because nothing could be removed.
                    if (view.state.filterTransactionCalled === false) {
                      const pos = findNextTextblock(view.state.doc, view.state.selection.$anchor, SearchDirection.RIGHT);
                      const transaction = view.state.tr.setSelection(TextSelection.create(view.state.doc, pos, pos));
                      view.dispatch(transaction);
                    }
                  }, 50);
                }*/

                return false;
              },
            },

            filterTransaction: (transaction, _state: EditorState) => {
              const state: EditorStateBackspaceExtension = _state as EditorStateBackspaceExtension;
              if (!state.deleting) {
                return true;
              }

              // In this case interrupt the strange behaviour
              if (transaction.mapping.maps.length === 0) {
                state.deleting = false;
                if (state.resetDeleting) {
                  clearTimeout(state.resetDeleting);
                  state.resetDeleting = undefined;
                }
                return false;
              }
              return true;
            },
          })
      ];
    }
  });
