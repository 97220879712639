import {createApp} from 'vue';
import App from './App.vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router';
import store from './store';
import i18n from './i18n';
import Buefy from '@ntohq/buefy-next';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import detectBrowser from "vue-detect-browser";
import '@/assets/styles/main.scss';
import '@ntohq/buefy-next/dist/buefy.css';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import 'vue-easy-dnd/dist/dnd.css'
import activeElementDirective from '@/components/ActiveElementDirective';
import mouseMarginDirective from '@/components/MouseMarginDirective';

const app = createApp(App); // Create a Vue 3 app instance

app.use(VueAxios, axios)
app.use(i18n); // Use i18n
app.use(Buefy);
app.use(PerfectScrollbar); // Use PerfectScrollbar
app.use(detectBrowser); // Use Browser Detect

app.use(router); // Use Vue Router
app.use(store); // Use Vuex
app.directive('active-element',activeElementDirective) // Focus handling for the active element
app.directive('mouse-margin-leave',mouseMarginDirective)

app.mount('#app'); // Mount your app to the DOM
