import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import store from '@/store';
import {CompletionResultState} from '@/store/models/completionResult.model';
import {CompletionResult} from '@/api/models/CompletionResult.model';
import {LlmRequest} from '@/api/models/LlmRequest.model';
import {SendLlmRequest, SendLlmRequestStream, UpdateRating} from '@/api/services/aiAssisstant.api';
import AiAssistantModule from '@/store/modules/AiAssistantModule';

@Module({dynamic: true, namespaced: true, store, name: 'completionResult'})
class CompletionResultModule extends VuexModule implements CompletionResultState {

  // initial state
  private _isLoading = false;
  private _completionResults: { [key: string]: CompletionResult } = {};

  get isLoading(): boolean {
    return this._isLoading;
  }

  @Mutation
  private setLoading(): void {
    this._isLoading = true;
  }

  @Mutation
  public setLoadingFalse(): void {
    this._isLoading = false;
  }

  get currentCompletionResult(): CompletionResult | null {
    if (AiAssistantModule.lastSelectedBlock === null) {
      return null;
    }
    const result = this.completionResults[AiAssistantModule.lastSelectedBlock];
    if (result == undefined) {
      return null;
    }
    return result;
  }

  get completionResults(): { [key: string]: CompletionResult } {
    return this._completionResults;
  }

  @Mutation
  private getLlmsEnd(completionResult: CompletionResult) {
    this._completionResults[completionResult.selectedBlockGuid] = completionResult;
  }

  @Mutation
  private setIsLoadingFalse() {
    this._isLoading = false;
  }

  @Action({rawError: true})
  async sendLlmRequest(llmRequest: LlmRequest): Promise<CompletionResult> {
    this.setLoading();
    return SendLlmRequest(llmRequest).then((completionResult: CompletionResult) => {
      this.getLlmsEnd(completionResult);
      return completionResult;
    })
      .finally(() => {
        this.setIsLoadingFalse()
      })
  }

  @Action
  async updateRating(payload: { completionResultGuid: string; rating: number }): Promise<CompletionResult> {
    this.setLoading();
    return UpdateRating(payload.completionResultGuid, payload.rating).then((completionResult: CompletionResult) => {
      this.getLlmsEnd(completionResult);
      return completionResult;
    }).finally(() => {
      this.setIsLoadingFalse()
    })
  }


  @Mutation
  public setChunkedResult(completionResult: CompletionResult) {
    if (AiAssistantModule.lastSelectedBlock === null) {
      return null;
    }
    if (completionResult == undefined) {
      return null;
    }
    this._completionResults[completionResult.selectedBlockGuid] = completionResult;
  }

  @Action({rawError: true})
  async sendLlmRequestStream(llmRequest: LlmRequest): Promise<void> {
    this.setLoading();
    return SendLlmRequestStream(llmRequest).then((completionResult: any) => {
      this.setChunkedResult(completionResult);
      return Promise.resolve();
    })
    .finally(() => {
      this.setLoadingFalse();
    })
  }
}


export default getModule(CompletionResultModule);