import {Mark, MarkType, Node as PmNode} from '@tiptap/pm/model';
import {Transaction} from '@tiptap/pm/state';
import {RemoveMarkStep} from '@tiptap/pm/transform';

interface MatchedMark {
  mark: Mark;
  from: number;
  to: number;
  step: number;
}

/**
 * Gets the list of marks of mark types within the given node.
 * @param node The node to be checked.
 * @param markTypes The list of MarkTypes to be get. If not provided, all the Marks within the node are returned.
 * @return The list of marks of mark types within the given node.
 */
const getMarksOfTypesInNode = (node: PmNode, markTypes?: MarkType[]): Mark[] => {
  if (!markTypes || !markTypes.length) {
    return [];
  }

  const marks: Mark[] = [];
  let set = node.marks;
  markTypes.forEach((mark: MarkType) => {
    let found = mark.isInSet(set);
    while (found) {
      marks.push(found);
      set = found.removeFromSet(set);
      found = mark.isInSet(set);
    }
  });

  return marks;
}

/**
 * Remove marks from inline nodes between `from` and `to`. When `marks` is given removes all marks of those types.
 * When it is null, remove all marks of any type within the nodes in the given range.
 * @extends https://github.com/ProseMirror/prosemirror-transform/blob/master/src/mark.js#removeMark
 * @param tr The transaction to be updated.
 * @param from The start position to look for.
 * @param to The end position to look for.
 * @param markTypes The list of MarkTypes to be removed.
 * @example
 *  - removeMarksInNodesBetween(tr, from, to, [schema.marks.bold, schema.marks.italic, schema.marks.underline]);
 *  - removeMarksInNodesBetween(tr, from, to);
 */
const removeMarks = (tr: Transaction, from: number, to: number, markTypes?: MarkType[]): void => {
  const matchedMarks: MatchedMark[] = [];
  let step = 0
  tr.doc.nodesBetween(from, to, (node: PmNode, pos: number) => {
    if (!node.isInline) {
      return;
    }

    step++;
    const toRemove: Mark[] = getMarksOfTypesInNode(node, markTypes);

    // If there's anything to be removed
    if (toRemove.length) {
      const start = Math.max(pos, from);
      const end = Math.min(pos + node.nodeSize, to);

      for (let toRemoveIndex = 0; toRemoveIndex < toRemove.length; toRemoveIndex++) {
        const markToBeRemoved = toRemove[toRemoveIndex];
        let foundMatchedMark: MatchedMark | undefined = undefined;
        for (let matchIndex = 0; matchIndex < matchedMarks.length; matchIndex++) {
          const matchedMark = matchedMarks[matchIndex];

          // Searches for an already matched mark in the previous step iteration
          if (matchedMark.step == step - 1 && markToBeRemoved.eq(matchedMarks[matchIndex].mark)) {
            foundMatchedMark = matchedMark;
          }
        }

        if (foundMatchedMark) {
          foundMatchedMark.to = end;
          foundMatchedMark.step = step;
        } else {
          matchedMarks.push({mark: markToBeRemoved, from: start, to: end, step})
        }
      }
    }
  });

  // Removes all the corresponding mark steps within the given transaction
  matchedMarks.forEach((matchMark: MatchedMark) => tr.removeMark(matchMark.from, matchMark.to, matchMark.mark));
}

export {
  removeMarks
}