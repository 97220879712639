import {EditorState, Plugin, PluginKey, Transaction as PmTransaction} from '@tiptap/pm/state';
import {filterForUnknownSteps} from '@/util/prosemirror-tx-replication.util';

const FilterTransactionPluginKey = new PluginKey('FilterTransactionPlugin');

/**
 * This plugin filter all transaction that contain transaction steps that are unknown to the replication logic in the two document
 * editor view mode.
 */
export class FilterTransactionPlugin extends Plugin {

  debug = false;

  constructor() {
    super({
            key: FilterTransactionPluginKey,
            props: {},

            filterTransaction(transaction: PmTransaction, state: EditorState): boolean {
              const plugin = FilterTransactionPluginKey.get(state) as FilterTransactionPlugin;
              return plugin.filterTransaction(transaction, state);
            }
          });
  }

  filterTransaction(transaction: PmTransaction, state: EditorState): boolean {

    const unknownSteps = filterForUnknownSteps(transaction);
    const unknownStepsFound = unknownSteps.length > 0;
    if(unknownStepsFound){
      this.logDebug('Prevented unknown steps:', unknownSteps);
    }
    return unknownSteps.length === 0;
  }

  logDebug(text: string, object?: any) {
    if (this.debug) {
      console.log(text, object);
    }
  }
}

