import {EditorView as PmEditorView} from '@tiptap/pm/view';
import EditorModule from '@/store/modules/EditorModule';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';

export default (view: PmEditorView, event: KeyboardEvent): boolean => {

  /*
   * If a single node is selected exactly from start to end,
   * prosemirror interpretes a 'Space' not as an text input but as a deletion of nodes.
   * This node deletion somehow gets applied to the prosemirror model even before the regular transaction is applied.
   * Therefore, we have no other means to avoid node deletion than intercepting the key press of 'Space',
   * ignoring it and dispatching a regular transaction for inserting a space character instead.
   */
  if (event.code === 'Space') {
    const selection = view.state.selection;
    const selectionLength = Math.abs(selection.to - selection.from);

    if (selectionLength > 0) {
      const fromNode = view.state.selection.$from.node();
      const toNode = view.state.selection.$to.node();

      const selectionMatchesNodeRange = (fromNode.content.size == selectionLength) && (fromNode.eq(toNode));
      if (selectionMatchesNodeRange) {
        const transaction = view.state.tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'handleKeyPress');
        view.dispatch(transaction.insertText(' '));
        return true;
      }
    }
  }

  // Remove all search results on any text change
  EditorModule.triggerClearSearch();

  return false;
};
