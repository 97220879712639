<template>
  <PatentEngineMenuBar :editor="editorInstance">
    <div ref="editorMenuBar" class="menubar">
      <div class="menubar-row">
        <div class="space-between-container" :style="visible">
          <div class="create-delete-button-container">
            <CreateBlock :key="computedActiveEditorSelection+1" :editor="editorInstance" v-show="selectedBlockGuid"/>
            <DeleteBlock :key="computedActiveEditorSelection+2" :editor="editorInstance" v-show="selectedBlockGuid"/>
            <InlineBlock :key="computedActiveEditorSelection+3" :editor="editorInstance" v-show="selectedBlockGuid"/>
          </div>
        </div>
        <div class="generate-undo-redo">
          <div class="generate-button-container">
            <GenerateBlock :editor="editorInstance" v-show="selectedBlockGuid"/>
          </div>
          <GenerateAll :editor="editor"/>
          <History :editor="editor" :applicationeditor="applicationeditor"/>
        </div>
      </div>

      <div v-show="toggleSearchBar"
           class="menubar-row">
        <SearchBar ref="searchBar" :editor="editor" :closeCallback="closeSearchbarPressed"/>
      </div>
    </div>
  </PatentEngineMenuBar>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import CreateBlock from '@/components/applicationEditor/menubar/CreateBlock.vue';
import DeleteBlock from '@/components/applicationEditor/menubar/DeleteBlock.vue';
import InlineBlock from '@/components/applicationEditor/menubar/InlineBlock.vue';
import History from '@/components/applicationEditor/menubar/History.vue';
import {ApplicationEditor as ApplicationEditorClass, NamedEditor} from '@/components/ApplicationEditor.vue';
import GenerateBlock from '@/components/applicationEditor/menubar/Generate.vue';
import GenerateAll from '@/components/applicationEditor/menubar/GenerateAll.vue';
import Paste from '@/components/applicationEditor/menubar/Paste.vue';
import SearchBar, {SearchBar as SearchBarClass} from '@/components/applicationEditor/menubar/SearchBar.vue';
import EditorModule from '@/store/modules/EditorModule';
import PatentEngineMenuBar from '@/components/header/PatentEngineMenuBar.vue';

@Component(
  {
    components: {
      PatentEngineMenuBar,
      History,
      DeleteBlock,
      CreateBlock,
      InlineBlock,
      GenerateBlock,
      GenerateAll,
      Paste,
      SearchBar
    }
  })
class Menubar extends Vue {

  @Prop({required: true}) editor!: NamedEditor;
  @Prop({required: true}) applicationeditor!: ApplicationEditorClass;
  @Ref('editorMenuBar') private editorMenuBar!: HTMLElement;
  @Ref('searchBar') private searchBar!: SearchBarClass;

  get toggleSearchBar(): boolean {
    return EditorModule.isSearchBarVisible;
  }

  get computedActiveEditorSelection() {
    if (!this.editor) {
      return "";
    }
    return `${this.editor?.state.selection.from} ${this.editor?.state.selection.to}`;
  }

  get selectedBlockGuid(): string | null {
    return EditorModule.selectionGuidForEditor;
  }

  private closeSearchbarPressed(): void {
    EditorModule.setSearchBarVisible(false);
  }

  get searchTerm(): string {
    return EditorModule.searchTermOnKeyDown;
  }

  @Watch('searchTerm', {immediate: true})
  private searchTermChanged(newTerm: string) {
    if (this.searchBar && this.toggleSearchBar && newTerm) {
      setTimeout(this.searchBar.setFocus, 100);
    }
  }

  @Watch('toggleSearchBar', {immediate: true})
  private toggleSearchBarChanged(toggleSearchBar: boolean) {
    if (toggleSearchBar) {
      // On open
      if (this.searchBar) {
        setTimeout(this.searchBar.setFocus, 100);
      }
    } else {
      // On close
      if (this.searchBar) {
        this.searchBar.clearSearch();
      }
    }
  }

  @Watch('displaySearchBarOnKeyDown', {immediate: true})
  private displaySearchBarOnKeyDownChanged(toggleSearchBar: boolean) {
    if (toggleSearchBar) {
      if (this.searchBar) {
        setTimeout(this.searchBar.setFocus, 100);
      }
      EditorModule.setSearchBarVisible(false);

    }
  }

  get editorInstance(): NamedEditor {
    return this.editor;
  }

  get editorKey(): string {
    if (!this.editorInstance) {
      return ""
    }
    return this.editorInstance.editorName;
  }

  get visible() {
    return {visibility: 'visible'};
  }
}

export default toNative(Menubar);
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<!-- not 'scoped' so styling applies to generated buttons -->
<style lang="scss">
@import "src/assets/styles/colors";
@import "src/assets/styles/constants";

// Wrapper
.menubar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 8px;

  .menubar-row {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  // This will reserve the space the search bar will take
  .search-bar-placeholder {
    width: 100%;
    height: calc(#{$search-bar-height} - 6px);
  }

  .space-between-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: fit-content;
  }

  .generate-undo-redo {
    display: flex;
    justify-content: flex-end;
    width: fit-content;
    flex-flow: row wrap;
    height: fit-content;
  }

  .create-delete-button-container {
    display: flex;
    flex-flow: row wrap;
    text-align: left;
    min-width: 1px;
    min-height: 35px;

  }

  .generate-button-container {
    display: flex;
    flex-flow: row-reverse wrap;
    text-align: right;
  }

  // Button flex layout
  button {
    span {
      display: flex;
      vertical-align: middle;
      line-height: 18px;

      i {
        margin-right: 8px;
      }
    }

    cursor: pointer;
    padding: 8px 8px;
    font-size: $font-size-normal;
  }

  // Standard
  button:not(.icon-button) {
    background-color: transparent;
    color: $text-color;
  }

  button.delete-button:not(.icon-button):hover:enabled {
    background-color: transparent;
    color: $pengine-delete-red;
  }

  // Standard hover
  button:hover:not(.icon-button,.delete-button):enabled {
    background-color: transparent;
    color: $pengine-blue-dark;
  }

  button:disabled {
    background-color: transparent;
    border: 1px solid $pengine-grey-light;
    cursor: not-allowed;
    color: $pengine-grey;
  }

  .search-bar-panel {
    width: 100%;
  }

  .search-bar-dropdown {
    z-index: 8; // Must be behind modal dialogs
    position: absolute;
    vertical-align: top;
    width: 100%;
    // top: 46px;
    left: 0px;

    .dropdown-menu {
      width: calc(100% - 1px);

      .dropdown-content {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-bottom: $pengine-grey solid 1px;
      }
    }
  }
}
</style>
