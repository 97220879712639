import {EditorState as PmEditorState, Plugin, PluginKey, Transaction as PmTransaction} from '@tiptap/pm/state'
import {Node as PmNode} from "@tiptap/pm/model";
import {findAllAncestorNodes, isInlineBlock, isLogicalBlock} from '@/components/applicationEditor/utils/node.util';
import EditorModule from '@/store/modules/EditorModule';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';
import {cloneDeep} from 'lodash'

const NodeDepthPluginKey = new PluginKey('NodeDepthPlugin');

/**
 * Calculates and sets the depth of each node, according to its depth within the block structure.
 * Additionally, the maximum depth for the whole document is set here.
 *
 * The depth calculated here does not necessarily equal the depth used when handling prosemirror nodes.
 */
export class NodeDepthPlugin extends Plugin {

  constructor() {
    super({
            key: NodeDepthPluginKey,
            props: {},

            appendTransaction(transactions: readonly PmTransaction[], oldState: PmEditorState, newState: PmEditorState) {
              const plugin = NodeDepthPluginKey.get(oldState) as NodeDepthPlugin;
              return plugin.setNodeDepth(transactions, oldState, newState);
            }
          });
  }

  public setNodeDepth(transactions: readonly PmTransaction[], oldState: PmEditorState, newState: PmEditorState) {
    const additionalTransaction: PmTransaction = newState.tr;

    // Only (re)calculate node depth on changes from backend that may effect the structure of the document
    const creationOrReloadtransactionFound = transactions.some(this.isCreationOrReloadTransaction);
    if (creationOrReloadtransactionFound) {
      return this.calculateNodeDepth(additionalTransaction);
    }
  }

  /**
   *  Calculates the nesting depth of nodes in block-mode (non-inline) and sets it for each node's depth attribute individually.
   *  Also calculates the max nesting depth of block-mode nodes and sets it in the store.
   */
  private calculateNodeDepth(transaction: PmTransaction): PmTransaction {
    const doc = transaction.doc;
    const changedNodes: Array<PmNode> = [];
    let maxDepth = 0;

    doc.descendants((node: PmNode, pos: number) => {
      const resPos = doc.resolve(pos);
      if (!isInlineBlock(node)) {

        // Traverse every node only once
        if (!changedNodes.includes(node)) {
          changedNodes.push(node);

          const ancestorNodes = findAllAncestorNodes(resPos, (predNode) =>
            isLogicalBlock(predNode)                            // Exclude non logical nodes
            && (predNode !== node)                              // Exclude the node itself
            && (predNode.type.name !== 'applicationDocument')   // Exclude the applicationDocument node
          );

          const depth = ancestorNodes.length;

          // Correct max depth if a higher depth was found that before
          if (maxDepth < depth) {
            maxDepth = depth;
          }

          // Copy attributes and change the depth value
          const nodeToChange = resPos.node();
          // Replace attributes of the current node
          if (nodeToChange.attrs.logicalBlock) {
            transaction.setNodeMarkup(resPos.before(resPos.depth), undefined, {
              ...cloneDeep(nodeToChange.attrs),
              logicalDepth: depth
            });
          }
        }
      }
    });

    // Update max depth in store
    EditorModule.setMaxDepth(maxDepth);
    transaction.setMeta(ProsemirrorTransactionMeta.UPDATE_LOGICAL_DEPTH_ATTRIBUTE, true);
    transaction.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, NodeDepthPluginKey);
    return transaction;
  }

  private isCreationOrReloadTransaction(transaction: PmTransaction) {
    return transaction.getMeta(ProsemirrorTransactionMeta.INITIAL_STATE)
      || transaction.getMeta(ProsemirrorTransactionMeta.UPDATE_FROM_BACKEND);
  }
}

